import React, {ReactElement} from "react";
import {Outlet} from "react-router";

import {PageSubNavigation} from "../../../hoc/layout/layout/page-sub-navigation";
import {NavigationPageLayout} from "../../../hoc/layout/navigation-page-layout";
import {SettingsGearIcon, NotesEditBrandprofileIcon} from "../../../icons";
import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {useMount} from "@/hooks/useMount";

export const AccountPage = (): ReactElement => {
	useMount(() => {document.title = "Vurvey - Personal Profile"})

	return (
		<NavigationPageLayout>
			<PageSubNavigation header="Personal Profile">
				<ButtonGroup>
					<ButtonGroupElement
						icon={<SettingsGearIcon />}
						text="General Settings"
						to=""
						end
					/>
					<ButtonGroupElement
						icon={<NotesEditBrandprofileIcon />}
						text="Terms of Service"
						to="terms-of-service"
					/>
				</ButtonGroup>
			</PageSubNavigation>

			<Outlet />
		</NavigationPageLayout>
	);
};
