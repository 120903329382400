import React, {ReactElement} from "react";

import {DropdownItem} from "..";
import {DropdownItemContent} from "../_helpers/content";
import {DropdownItemSpacing, useItemClassName} from "../_helpers";

export interface DropdownActionItemProps {
	item: DropdownItem & {onClick: (event?: MouseEvent) => void};
}

// component that won't close the dropdown when clicked on the item
export const DropdownOpenActionItem = ({item}: DropdownActionItemProps): ReactElement => {
	const itemClassName = useItemClassName(item);

	return (
		<DropdownItemSpacing active={item.active}>
			<div className={itemClassName} onClick={e => {
				e.stopPropagation();
				item.onClick(e as any);
			}}>
				<DropdownItemContent item={item}/>
			</div>
		</DropdownItemSpacing>
	);
};
