import React, {ReactElement, ReactNode, createContext} from "react";
import {useDragConstraints, UseDragConstraintsResult} from "../../hooks/useDragConstraints";

export type CarouselConstraintsContextValue = UseDragConstraintsResult<HTMLDivElement, HTMLDivElement>;

export const CarouselConstraintsContext =
  createContext<CarouselConstraintsContextValue | undefined>(undefined);

export const CarouselConstraintsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const value = useDragConstraints<HTMLDivElement, HTMLDivElement>();

  return (
    <CarouselConstraintsContext.Provider value={value}>
      {children}
    </CarouselConstraintsContext.Provider>
  );
};

export const useCarouselConstraintsContext = (): CarouselConstraintsContextValue => {
  const context = React.useContext(CarouselConstraintsContext);

  if (context === undefined) {
    throw new Error(
      "useCarouselConstraintsContext must be used within a CarouselConstraintsContextProvider",
    );
  }

  return context;
};
