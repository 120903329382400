import {useLoadingQuery} from "../../../../hooks";
import {useMutation} from "@apollo/client";
import React, {ReactElement, useMemo, useState} from "react";

import {Avatar, Button, Separator, Subheader} from "../../../../shared/v2";
import {ChangeWorkspaceNameModal} from "./change-workspace-name-modal";
import {CREATE_WORKSPACE_LOGO} from "../../../../graphql/mutations/mutations";
import {EditPictureModal} from "../../../../shared/v2/modals/edit-picture-modal";
import {GET_REWARD_SETTINGS_NOCONFIG} from "../../../../graphql/queries/reward-queries";
import {OptionRow} from "../../../../shared/v2/option-row";
import {PlanCard} from "../../../../shared/v2/plan-card";
import {RewardSettingReturnNoConfig} from "../../../../models/rewards";
import {TremendousCard} from "./tremendous-card";
import {UPDATE_WORKSPACE} from "../../../../graphql/mutations/workspace-settings-mutations";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {useWorkspaceContext} from "../../../../context/workspace-context";
import {WorkspaceLogo} from "../../../../models/workspace";

import styles from "./general-settings.module.scss";
import {ForceUserLogoutModal} from "./force-logout-modal";
import {useMount} from "@/hooks/useMount";

export interface UpdateWorkspaceChanges {
  name: string;
  logoId: string;
  forceLogout?: boolean;
  forceLogoutPeriodMin?: number;
}

export const GeneralSettings = (): ReactElement => {
	const {
		workspace: {id},
	} = useWorkspaceContext();
	const {isDarkMode} = useThemeMode();
	const {workspace} = useWorkspaceContext();
	const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);

	const [isChangeWorkspaceNameModalOpen, setIsChangeWorkspaceNameModalOpen] = useState(false);
	const [isChangeWorkspaceAvatarModalOpen, setIsChangeWorkspaceAvatarModalOpen] = useState(false);
	const [isChangeSessionTimeoutOpen, setIsChangeSessionTimeoutOpen] = useState(false);

	const handleAvatarUpdate = (logo: WorkspaceLogo) => {
		updateWorkspace({
			variables: {
				id: workspace.id,
				changes: {logoId: logo.id},
			},
		});

		window.location.reload();
	};

	const {data: tremendousSettings} = useLoadingQuery<RewardSettingReturnNoConfig>(GET_REWARD_SETTINGS_NOCONFIG, {
		variables: {workspaceId: id},
	});

	const isTremendousActive = tremendousSettings && tremendousSettings?.workspaceRewardSettings.length > 0;

	const separator = useMemo(
		() => <Separator color={isDarkMode ? "charcoal-elevation-800" : "gray-modern-300"} />,
		[isDarkMode],
	);

	useMount(() => { document.title = "Vurvey - General Settings" });


	return (
		<>
			<div className={styles.generalSettingsWrapper}>
				<OptionRow
					description="Configure the session timeout settings for your workspace. Set the time limit for inactivity before users are automatically logged out of the platform."
					title="Session Timout"
				>
					<Subheader color="text-secondary" type="semibold" size="l">
						{workspace.forceLogout ? `${workspace.forceLogoutPeriodMin || 15} minutes` : "No Limit"}
					</Subheader>

					<Button variant="outlined" onClick={() => setIsChangeSessionTimeoutOpen(true)}>
            Edit
					</Button>
				</OptionRow>

				{separator}

				<OptionRow
					description="Customize your workspace by giving it a unique name. This name will be visible to all members of your workspace."
					title="Workspace name"
				>
					<Subheader color="text-secondary" type="semibold" size="l">
						{workspace.name}
					</Subheader>

					<Button variant="outlined" onClick={() => setIsChangeWorkspaceNameModalOpen(true)}>
            Edit
					</Button>
				</OptionRow>

				{separator}

				<OptionRow
					description="Upload an avatar to represent your workspace visually. Choose an image that reflects the essence of your team or project for easy recognition across the platform."
					title="Workspace avatar"
				>
					<Avatar firstName={workspace?.name} url={workspace?.logo?.thumbnail} />

					<Button onClick={() => setIsChangeWorkspaceAvatarModalOpen(true)} variant="outlined">
            Edit
					</Button>
				</OptionRow>

				{separator}

				<OptionRow
					description="View the details of your current subscription plan, including the features available to you and your team. Upgrade or modify your plan to fit your evolving workspace needs."
					title="Current plan"
				>
					<PlanCard plan={workspace.plan} />
				</OptionRow>

				{isTremendousActive && (
					<>
						{separator}

						<div className={styles.integrationsWrapper}>
							<Subheader>Integrations</Subheader>

							<TremendousCard />
						</div>
					</>
				)}
			</div>
			<ForceUserLogoutModal
				isOpen={isChangeSessionTimeoutOpen}
				onClose={() => setIsChangeSessionTimeoutOpen(false)}
			/>
			<ChangeWorkspaceNameModal
				isOpen={isChangeWorkspaceNameModalOpen}
				onClose={() => setIsChangeWorkspaceNameModalOpen(false)}
			/>
			<EditPictureModal<WorkspaceLogo>
				isOpen={isChangeWorkspaceAvatarModalOpen}
				title="Workspace avatar"
				description="Upload an avatar to represent your workspace visually."
				imageUrl={workspace?.logo?.thumbnail || ""}
				createPictureMutation={CREATE_WORKSPACE_LOGO}
				onUpdate={handleAvatarUpdate}
				onClose={() => setIsChangeWorkspaceAvatarModalOpen(false)}
			/>
		</>
	);
};
