import {ReactElement, useRef, useState} from "react";
import {useNavigate, useBlocker, Location} from "react-router";
import {useBeforeUnload} from "react-router-dom";

interface NavigationTrx {
	currentLocation: Location;
	nextLocation: Location;
	historyAction: "POP" | "PUSH" | "REPLACE";
}

export interface ConfirmationBlockerProps {
  shouldBlock: boolean | ((trx: NavigationTrx) => boolean);
	allowSameLocation?: boolean;
  renderPrompt: (props: {proceed: () => void; cancel: () => void, isOpen: boolean;}) => ReactElement;
  fallback?: () => void;
}

export function useConfirmationBlocker({
	shouldBlock,
	allowSameLocation = true,
	renderPrompt,
	fallback
}: ConfirmationBlockerProps) {
	const navigate = useNavigate();
	const isActiveRef = useRef(true);
	const lastTrxRef = useRef<NavigationTrx | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useBlocker((trx) => {
		const isChangingRoute = trx.currentLocation.pathname !== trx.nextLocation.pathname;

		const block = typeof shouldBlock === "function" ? shouldBlock(trx) : shouldBlock;

		if (
			block &&
			isActiveRef.current &&
			// if allowSameLocation is true, we block only if the route is changing
			(allowSameLocation ? isChangingRoute : true)
		) {
			lastTrxRef.current = {
				currentLocation: trx.currentLocation,
				nextLocation: trx.nextLocation,
				historyAction: trx.historyAction,
			};
			setIsModalOpen(true);
			return true;
		}

		return false;
	});

  useBeforeUnload(() => {
    if (shouldBlock && isActiveRef.current) {
      fallback?.();
      return "data will get lost";
    }
  })

	const proceed = () => {
		isActiveRef.current = false;
		setIsModalOpen(false);
		const trx = lastTrxRef.current;

		if (trx) {
			const {pathname, search, hash, state} = trx.nextLocation;
			navigate(pathname + search + hash, {state});
		}
	};

	const cancel = () => {
		setIsModalOpen(false);
	};

	return renderPrompt({proceed, cancel, isOpen: isModalOpen});
}
