import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {useThemeMode} from "../../../../context/theme-mode-context";
import {Caption} from "../../../../shared/v2";

import styles from "./additional-agents-avatar.module.scss";

const cx = classNames.bind(styles);

export interface AdditionalAgentsAvatarProps {
  text: any;
  size: "sm" | "md";
}

export const AdditionalAgentsAvatar = ({text, size}: AdditionalAgentsAvatarProps): ReactElement => {
  const {isDarkMode} = useThemeMode();

  return (
    <div className={cx("additionalAgentsAvatar", size, {isDarkMode})}>
      <Caption className={styles.countText}  type="medium">{text}</Caption>
    </div>
  )
}
