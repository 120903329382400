/* eslint-disable */

import classNames, {Argument} from "classnames";
import React, {ReactElement, useEffect, useState} from "react";
import {AnswerVideo, TranscodingStatus} from "../../../models/answer";
import {getCurrent} from "../../../ref";
import {VideoCardPlayer} from "../../../survey/components/video-card-player";
import {convertToClockTime} from "../../utility/utility";
import {Icon} from "../icon";
import styles from "./video-swapper.module.scss";
import {useInView} from "react-intersection-observer";

// Names are hard
interface VideoSwapperProps {
	answerId: string;
	video: AnswerVideo;
	checkbox?: JSX.Element;
	className?: Argument;
}


const VideoSwapper = React.forwardRef<HTMLDivElement, VideoSwapperProps>((props, ref): ReactElement => {
	const {answerId, video, checkbox, className} = props;
	const [showVideo, setShowVideo] = useState(false);

	const handleDownloadVideo = (): void => setShowVideo(true);

	const {ref: imgRef, inView} = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

	const checkClick = ({target}): void => {
		const current = getCurrent(ref);
		if (current?.contains(target)) return;
		setShowVideo(false);
	};

	useEffect(() => {
		if (showVideo) document.addEventListener("mousedown", checkClick);
		return () => document.removeEventListener("mousedown", checkClick);
	}, [showVideo]);

	return (
		<div className={classNames(className, styles.videoContainer)} ref={ref}>
			{
				showVideo ? <VideoCardPlayer answerId={answerId}/>
					: video?.transcodingStatus === TranscodingStatus.COMPLETED ? (
						<>
							{checkbox && <div className={styles.checkbox}>{checkbox}</div>}
							<div ref={imgRef}>{inView && <img className={styles.image} src={video.thumbnail}/>}</div>
							<Icon
								clicked={handleDownloadVideo}
								name="play-solid"
								className={styles.play}
								size="extrasmall"
								isClickable
							/>
							<p className={styles.time}>
								{convertToClockTime(video.duration, "milliseconds")}
							</p>
							<div className={styles.bar}/>
						</>
					) : video?.transcodingStatus === TranscodingStatus.PENDING ? (
						<span className={styles.processing}>Processing video...</span>
					) : video?.transcodingStatus === TranscodingStatus.FAILED ? (
						<span className={styles.failed}>Processing failed.</span>
					)	: undefined
			}
		</div>
	);
});

VideoSwapper.displayName = "VideoSwapper";

export {VideoSwapper};
