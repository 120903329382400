import {useLazyQuery, useMutation} from "@apollo/client";
import React, {ReactElement, useContext, useMemo, useState} from "react";

import {ADD_ATTRIBUTE_TO_USERS, ADD_WORKSPACE_SEGMENT_MEMBERS} from "../../../graphql/mutations/mutations";
import {AddListDropdown} from "../add-list-dropdown";
import {Answer} from "../../../models/answer";
import {Checkbox} from "../../../shared/v2";
import {CreateModalContext} from "../../../context/create-modal-context";
import {DropdownOptions, HeartIcon, Icon, Options} from "../../../shared";
import {GET_UPDATED_MP4} from "../../../graphql/queries/survey-queries";
import {InteractionCounter} from "../interaction-counter";
import {InviteCampaignDropdown} from "../invite-campaign-dropdown";
import {isSupportOrEnterpriseRole} from "../../../shared/utility/utility";
import {LIKE_ANSWER, UNLIKE_ANSWER} from "../../../graphql/mutations/survey-mutations";
import {ReelAdder} from "../reel-adder";
import {useSelectContext} from "../../../context/select-context";
import {selectedAnswer, toggleDeleteResponseModal} from "../../../cache";
import {ToastContext} from "../../../context/toast-context";
import {TranscriptModalContext} from "../../../context/transcript-modal-context";
import {usePingStatus} from "../../../hooks/usePingStatus";
import {UserContext} from "../../../context/user-context";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {VideoSwapper} from "../../../shared/components/video-swapper";
import {WorkspacePlan} from "../../../models/workspace";

import styles from "./video-card.module.scss";
import {AddPropertyModal} from "../../../contacts/modals/add-property";
import {AddAttributeToUserVars, RuleAction} from "../../../models/attribute";

export interface VideoCardProps {
	/**
	 * The answer to display info on
	 */
	answer: Answer;
	/**
	 * Current plan to determine what user can view with cards
	 */
	plan?: WorkspacePlan | null;
	/**
	 * Add clip handler
	 */
	addClip: (reelId: string, answerId: string) => void;
	isSelected: boolean;
	noDelete?: boolean;
	/**
	 * Shows all cards as selected
	 */
	selectAll: boolean;
	/**
	 * Processes when a single video is disselected when selectAll is true
	 */
	disselectOneFromSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * A video card. Displays likes, highlight count, overflow menu, and the video for
 * related answer when clicked on. Currently, when a user clicks on the image it will
 * query for the video based on the answerId. If they click anywhere outside of the card
 * after that, the video will revert back to just the image.
 *
 * @param image The image we want to show whenever the video isn't playing
 * @param answerId Id of the answer. Used to retrieve the video when clicked on
 */
const VideoCard = (props: VideoCardProps): ReactElement => {
	const {
		answer: {id, user, highlightCount, likeCount, video, likedAt, userId},
		plan,
		addClip,
		isSelected,
		noDelete = false,
		selectAll,
		disselectOneFromSelectAll,
	} = props;
	const {setSelected} = useSelectContext();
	const {
		user: {role},
	} = useContext(UserContext);
	const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();
	const {setSingleUserId, setCopyAnswerId} = useContext(CreateModalContext);
	const {setAnswerId} = useContext(TranscriptModalContext);
	const {updateToast} = useContext(ToastContext);

	const refForDropdowns = React.createRef<HTMLDivElement>();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [anchorList, setAnchorEl] = useState<any>(null);
	const [showAddPropModal, setShowAddPropModal] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [anchorInvite, setAnchorElInvite] = useState<any>(null);

	const canCopy = isSupportOrEnterpriseRole(role);
	/**
	 * Sets the anchor element to the current target
	 */
	const handleList = (singleId: string): void => {
		setSingleUserId(singleId);
		setAnchorEl(refForDropdowns.current);
	};
	/**
	 * Resets the anchor element to null.
	 */
	const closeList = (): void => setAnchorEl(null);
	/**
	 * Sets the anchor element to the current target
	 */
	const handleInvite = (): void => {
		setAnchorElInvite(refForDropdowns.current);
	};
	/**
	 * Resets the anchor element to null.
	 */
	const closeInvite = (): void => {
		setAnchorElInvite(null);
	};

	const divRef = React.createRef<HTMLDivElement>();
	usePingStatus(video?.transcodingStatus, id);

	const [downloadVideoLink] = useLazyQuery(GET_UPDATED_MP4, {
		fetchPolicy: "network-only",
		onCompleted: (result) => {
			const link = document.createElement("a");
			link.href = result.answer.video.mp4;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
	});

	const [likeAnswer] = useMutation(LIKE_ANSWER);
	const [unlikeAnswer] = useMutation(UNLIKE_ANSWER);

	const handleLike = (): void => {
		likeAnswer({variables: {answerId: id}});
	};

	const handleUnlike = (): void => {
		unlikeAnswer({variables: {answerId: id}});
	};

	/**
	 * Passes IDs given up to parent.
	 * @param reelId ReelID received from reel add dropdown.
	 */
	const handleAddClip = (reelId: string): void => {
		addClip(reelId, id);
	};

	/**
	 * Handles downloading the video
	 */
	const handleDownload = (): void => {
		downloadVideoLink({
			variables: {id},
		});
	};

	/**
	 * Toggles the delete response modal and changes selected answer.
	 */
	const handleDelete = (): boolean => {
		selectedAnswer(id);
		return toggleDeleteResponseModal(true);
	};


	const [sendToSegment] = useMutation(ADD_WORKSPACE_SEGMENT_MEMBERS);
	const [addProperty, {loading: addPropLoading}] = useMutation<{id: string}, AddAttributeToUserVars>(
		ADD_ATTRIBUTE_TO_USERS,
	);

	const handleAddToList = (segmentId: string): void => {
		sendToSegment({
			variables: {
				segmentId,
				userIds: [userId],
				workspaceId,
			},
			onCompleted: (data) => {
				const addedUsersLength = data.addSegmentMembers.addedUsers.length;
				const existingUsersLength = data.addSegmentMembers.existingUsers.length;
				updateToast({
					description: (
						<div>
							{addedUsersLength > 0 && <div>{addedUsersLength} added to list</div>}
							{existingUsersLength > 0 && <div>{existingUsersLength} already in list</div>}
						</div>
					),
					type: "informational",
				});
			},
			onError: (error) => {
				updateToast({description: error.message, type: "failure"});
			},
		});
	};

	const handleAddProperty = (id: string, ruleAction: RuleAction, value?: string): void => {
		addProperty({
			variables: {attributeId: id, userIds: userId, value, ruleAction},
			onCompleted: () => {
				updateToast({
					description: "Property added to user",
					type: "informational",
				});
				setShowAddPropModal(false);
			},
			onError: () => {
				updateToast({
					description: "Failed to add property",
					type: "failure",
				});
				setShowAddPropModal(false);
			}
		});
	};

	const toggleShowPropertyModal = (): void => setShowAddPropModal((prev) => !prev);

	/**
	 * Returns options based on the plan the workspace is on.
	 * Cannot View if the workspace is on the "BASIC" plan
	 */
	const getOptions = (): DropdownOptions[] => {
		const options: DropdownOptions[] = [
			{
				name: "Download",
				actionOptions: {onClick: handleDownload},
				icon: "download",
			},
			{
				name: "Add Creator to List",
				actionOptions: {onClick: () => handleList(userId)},
				icon: "add-members",
				iconFill: "black",
			},
			{
				name: "Invite Creator To Campaign",
				actionOptions: {onClick: handleInvite},
				icon: "envelope",
				iconFill: "black",
			},
			{
				name: "Add Property To User",
				actionOptions: {onClick: toggleShowPropertyModal},
				icon: "note",
				iconFill: "black",
			},
		];
		if (canCopy) {
			options.push({
				name: "Create Story",
				actionOptions: {onClick: () => setCopyAnswerId(id)},
				icon: "book",
				iconFill: "black",
			});
		}
		if (!noDelete) {
			options.push({
				name: "Delete",
				actionOptions: {onClick: handleDelete},
				icon: "trash",
			});
		}
		if (plan !== WorkspacePlan.BASIC) {
			options.unshift({
				name: "View",
				icon: "eye",
				iconFill: "black",
				actionOptions: {onClick: () => setAnswerId(id)},
			});
		}
		return options;
	};
	/**
	 * Highlight icon
	 */
	const highlightIcon = useMemo(() => {
		return <Icon name="highlight" />;
	}, []);
	/**
	 * Heart icon
	 */
	const heartIcon = useMemo(() => {
		return <HeartIcon isLiked={Boolean(likedAt)} like={handleLike} unlike={handleUnlike} />;
	}, [likedAt]);

	return (
		<>
			<div className={[styles.card, isSelected ? styles.selected : undefined].join(" ")}>
				<VideoSwapper
					answerId={id}
					ref={divRef}
					video={video}
					className={styles.videoBorder}
					checkbox={
						<Checkbox
							id={id}
							checked={isSelected}
							size="s"
							value={`${id}_${userId}`}
							onChange={(e) => (selectAll ? disselectOneFromSelectAll(e) : setSelected(e))}
						/>
					}
				/>
				<div className={styles["card-info"]}>
					<div className={styles.name}>
						{plan === WorkspacePlan.BASIC ? (
							<>
								<span className={styles.tag}>{user?.creatorTag || "Unknown Tag"}</span>
								<span>{user.firstName && `${user.firstName} ${user.lastInitial}.`}</span>
							</>
						) : (
							<>
								<span className={styles.link} onClick={() => setAnswerId(id)}>
									{user?.creatorTag || "Unknown Tag"}
								</span>
								<span className={styles.sub}>{user.firstName && `${user.firstName} ${user.lastInitial}.`}</span>
							</>
						)}
					</div>
					<div className={styles.actions}>
						<ReelAdder addClip={handleAddClip} answerId={id} />
						<InteractionCounter count={likeCount} icon={heartIcon} />
						<InteractionCounter count={highlightCount} icon={highlightIcon} />
						<div className={styles.options} ref={refForDropdowns}>
							<Options type="menu-vertical" position="left" options={getOptions()} />
						</div>
					</div>
				</div>
			</div>
			<AddListDropdown
				anchorEl={anchorList}
				workspaceId={workspaceId}
				addToList={handleAddToList}
				closeCallback={closeList}
			/>
			<InviteCampaignDropdown
				anchorEl={anchorInvite}
				workspaceId={workspaceId}
				closeCallback={closeInvite}
				useUserIds={true}
				className={styles.inviteToCampaign}
				userIds={[userId]}
			/>
			<AddPropertyModal
				handleSave={handleAddProperty}
				isOpen={showAddPropModal}
				loading={addPropLoading}
				onClose={toggleShowPropertyModal}
			/>
		</>
	);
};

export {VideoCard};
