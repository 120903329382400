/* eslint-disable */
import {Dropdown} from "../../../shared";
import React, {ReactElement, useContext, useState} from "react";
import {GET_ALL_REEL_NAMES} from "../../../graphql/queries/reel-queries";
import {ReelsAdderPageData} from "../../../models/reels";
import {CreateModalContext} from "../../../context/create-modal-context";
import styles from "./add-reel-dropdown.module.scss";
import {useQuery} from "@apollo/client";
import {SearchInput} from "../../../shared/v2/inputs";
import {Setter} from "../../../types";

export interface AddReelDropdownProps {
	anchorEl: HTMLElement | null;
	workspaceId: string;
	addClip: (reelId: string, reelName?: string) => void;
	closeCallback: () => void;
	showModal?: Setter<boolean>
}
/**
 * @param anchorEl HTMLElement the dropdown attaches to
 * @param closeCallback How to handle closing dropdown
 */

const AddReelDropdown
	= React.forwardRef<HTMLDivElement, AddReelDropdownProps>((props, ref): ReactElement => {
		const {anchorEl, workspaceId, addClip, closeCallback, showModal} = props;
		const [filterValue, setFilterValue] = useState<string>("");

		const {setIsShowingReelModal} = useContext(CreateModalContext);

		const {data, loading} = useQuery<ReelsAdderPageData>(GET_ALL_REEL_NAMES, {
			skip: !anchorEl,
			variables: {limit: 20, filter: {workspaceId, name: filterValue}},
			fetchPolicy: "no-cache",
		});

		const handleCreate = (): void => {
			if (showModal) {
				showModal(true);
				return closeCallback();
			}
			setIsShowingReelModal(true);
			closeCallback();
		};

		const handleOptionSelected = (id: string, name?: string): void => {
			addClip(id, name);
			closeCallback();
		};

		return (
			<Dropdown anchorEl={anchorEl} closeMenu={closeCallback} keepOpen width={240} height={250}>
				<div ref={ref}>
					<p className={styles.header}>Add to Reel</p>
					<div className={styles["input-container"]}>
						<SearchInput
							id="filter-reels"
							value={filterValue}
							onChange={setFilterValue}
						/>
					</div>
					<div className={styles.list}>
						<Dropdown.Item
							className={styles.link}
							options={{onClick: handleCreate}}
						>
							+ New reel
						</Dropdown.Item>
						{loading ?
							<div className={styles.reel}>Loading...</div>
							: data && data.reels.items.length > 0 ? data.reels.items.map(reel =>
								<Dropdown.Item
									key={reel.id}
									options={{onClick: () => handleOptionSelected(reel.id, reel.name)}}
									className={styles.item}
								>
									{reel.name}
								</Dropdown.Item>)
								: <div className={styles.reel}>No reels found</div>
						}
					</div>
				</div>
			</Dropdown>
		);
	});

AddReelDropdown.displayName = "AddReelDropdown";

export {AddReelDropdown};
