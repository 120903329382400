// @ts-nocheck

import {ButtonProps} from "../components/buttons/button-with-dropdown";
import {WorkspacePlan} from "../../models/workspace";
export interface AccountPlan {
	/**
	 * Name of the plan, used to determine what plan
	 */
	plan: WorkspacePlan;
	/**
	 * Plan details
	 */
	details: PlanDetails;
}
export interface PlanDetails {
	plan: WorkspacePlan;
	/**
 * Title of plan (Generally same as planName)
 */
	title?: string;
	header: string;
	isHidden?: boolean;
	description?: string;
	actionText: string;
	priceDescription?: string;
	priceInfoText?: string;
	/**
	* Flavor text right below title of the plan
	*/
	flavor?: string;
	/**
	* Price of the plan. Could be "let's talk"
	*/
	price?: number | string;
	/**
	* Button component props.
	* Currently we are not doing any "click" events in the object.
	* We can add that in on the cards for now
	*/
	button?: ButtonProps;
	/**
	 * Feature text
	 */
	featureText?: string;
	/**
	* Main features. Just array of strings
	*/
	mainFeatures?: string[];
	/**
	 * Additional features
	 */
	additionalFeatures?: string[];
	/**
	 * Theme colors for the card. Currently "coral", "purple" or "black"
	 */
	themeColor?: "coral" | "purple" | "black";
}

export const plans: PlanDetails[] = [
	{
		plan: WorkspacePlan.PENDING,
		header: "Pending",
		isHidden: true,
		actionText: "",
	},
	{
		plan: WorkspacePlan.BASIC,
		header: "Basic",
		isHidden: true,
		actionText: "",
	},
	{
		// plan: WorkspacePlan.PENDING,
		plan: WorkspacePlan.PRO,
		header: "PRO Plan",
		price: "$1 per active creator",
		actionText: "Start 14-Day Free Trial",
		mainFeatures: [
			"Dedicated workspace",
			"CRM to manage creators",
			"Unlimited campaigns",
			"Data analysis and summaries",
			"Reels and video search",
			"Add team members for $39/mo/user",
		],
	},
	{
		plan: WorkspacePlan.ENTERPRISE,
		header: "Enterprise",
		description: "Enhanced security and support",
		actionText: "Contact Us",
		isHidden: true,
	},
];
