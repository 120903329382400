import React, {ImgHTMLAttributes, ReactElement} from "react";
import { useInView } from 'react-intersection-observer';

export interface ImageWithAltProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, "onError"> {
  src?: string;
  children: ReactElement;
}

export const ImageWithAlt = ({children, ...props}: ImageWithAltProps): ReactElement => {
	const [isError, setIsError] = React.useState(false);

	const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

	return isError ? children : (
		<div ref={ref}>
			{inView && <img {...props} onError={() => {
				setIsError(true);
			}} />
		}
		</div>
	)
}
