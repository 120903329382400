import React, {ReactElement} from "react";

import {ConfirmActionModal} from "@/shared/v2/modals/confirm-action-modal";
import {useConfirmationBlocker} from "@/hooks";
import {useAiActionsContext, useImageHistoryContext} from "../../contexts";

export const UnsavedChangesHandler = (): ReactElement => {
  const {history} = useImageHistoryContext();
  const {isSavingImageRef} = useAiActionsContext();

  const unsavedChangesModal = useConfirmationBlocker({
		shouldBlock: (trx) => (
      // Displays unsaved changes modal if all the following conditions are met:
      // 1. There are images generated
      history.length > 1 &&
      // 2. The navigation action doesn't happen during normal
      // image studio actions (e.g. generating new images, changing current image etc.)
      trx.historyAction !== "REPLACE" &&
      // 3. The image is not being saved to conversation
      !isSavingImageRef.current
    ),
    allowSameLocation: false,
		renderPrompt: ({isOpen, cancel, proceed}) => (
			<ConfirmActionModal
				title="Unsaved Changes"
				description="Looks like you have made changes that haven't been saved yet. Do you want to leave this page and discard your changes, or stay and save them?"
				isOpen={isOpen}
				onClose={cancel}
				onConfirm={proceed}
				confirmText="Discard Changes"
				cancelText="Continue Editing"
			/>
		),
	});

  return unsavedChangesModal;
}
