import {Line} from "react-konva";
import {LineConfig} from "konva/lib/shapes/Line";
import Konva from "konva";
import React, {ReactElement, useEffect, useRef} from "react";

export interface MarchingAntsLineProps extends LineConfig {
  marchingSpeed: number;
}

export const MarchingAntsLine = ({
  marchingSpeed = 100,
  ...props
}: LineConfig): ReactElement => {
  const ref = useRef<Konva.Line>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const line = ref.current;
    const layer = line.getLayer();
    const dashLength = line.dash().reduce((a, b) => a + b, 0);

    const anim = new Konva.Animation((frame) => {
      if (frame && line) {
        const offset = (line.dashOffset() - (frame.timeDiff / 1000) * marchingSpeed) % dashLength;
        line.dashOffset(offset);
      }
    }, layer);

    anim.start();

    return () => {
      anim.stop();
    };
  }, [ref.current, marchingSpeed]);

  return (
    <Line
      ref={ref}
      dash={[10, 10]}
      dashOffset={2}
      stroke="#FFFFFF"
      strokeWidth={1}
      {...props}
    />
  );
}
