import React, {ReactElement, ReactNode, useState} from "react";

import styles from "./no-draggable-item.module.scss";
import classNames from "classnames/bind";

const bStyles = classNames.bind(styles);

export interface NoDraggableItemProps {
  children: ReactNode;
  className?: string;
}

export const NoDraggableItem = ({children, className}: NoDraggableItemProps): ReactElement => {
  const [isSelecting, setIsSelecting] = useState(false);

  const handleMouseDown = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    const isTextArea = target.matches('textarea') || target.closest('textarea');
    
    if (isTextArea) {
      setIsSelecting(true);
    }
  };

  const handleMouseUp = () => {
    setIsSelecting(false);
  };

  const handlePointerDown = (e: React.PointerEvent) => {
    const target = e.target as HTMLElement;
    
    // If we're in a textarea or currently selecting text, stop propagation
    if (isSelecting || target.matches('textarea') || target.closest('textarea')) {
      e.stopPropagation();
      return;
    }

    // For other interactive elements
    const isInteractive = target.matches('button, input, select, [role="button"]') || 
                         target.closest('button, input, select, [role="button"]');
    
    if (isInteractive) {
      e.stopPropagation();
    }
  };

  return (
    <div 
      onPointerDown={handlePointerDown}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className={bStyles("noDraggableItem", className)}
    >
      {children}
    </div>
  );
}
