import {ModifierPhases, Placement} from "@popperjs/core";
import {Popover} from "@headlessui/react";
import {usePopper} from "react-popper";
import classNames from "classnames/bind";
import React, {ReactElement, ReactNode, useMemo, useState} from "react";

import {Portal} from "../portal";
import {VIEWPORT_PORTAL_NAME} from "../../../portals";

import styles from "./popup.module.scss";

const cx = classNames.bind(styles);

export type PopupProps = {
	position?: Placement;
	portal?: boolean;
	space?: number;
	skidding?: number;
	sameWidth?: boolean;
	arrow?: boolean;
	children: ReactNode | ((options: {close: () => void}) => ReactNode);
	className?: string;
	popupClassName?: string;
	triggerClassName?: string;
	alwaysOpen?: boolean;
	trigger: ReactElement | ((open: boolean) => ReactElement);
};

export const Popup = React.forwardRef<HTMLDivElement, PopupProps>(({
	position = "auto",
	arrow = false,
	trigger,
	space = 0,
	skidding = 0,
	sameWidth = false,
	children,
	className,
	triggerClassName,
	popupClassName,
	alwaysOpen = false,
	portal = false,
}: PopupProps, ref): ReactElement => {
	const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

	const sameWidthModifier = useMemo(() => ({
		name: "sameWidth",
		enabled: sameWidth,
		phase: "beforeWrite" as ModifierPhases,
		requires: ["computeStyles"],
		fn: ({state}) => {
			state.styles.popper.width = `${state.rects.reference.width}px`;
		},
		effect: ({state}) => {
			state.elements.popper.style.width = `${
				state.elements.reference.offsetWidth
			}px`;
		}
	}), [sameWidth])

	const {styles: {popper: popperStyles}, attributes} = usePopper(referenceElement, popperElement, {
		placement: position,
		strategy: "fixed",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [skidding, space],
				},
			},
			{
				name: "arrow",
				enabled: arrow,
			},
			{
				name: "flip",
			},
			sameWidthModifier,
		],
	});

	const renderContent = (close: () => void): ReactElement => {
		const content = (
			<div ref={setPopperElement} className={cx("popper", popupClassName)} style={popperStyles} {...attributes.popper}>
				<Popover.Panel static={alwaysOpen}>
					{typeof children === "function" ? children({close}) : children}
				</Popover.Panel>
			</div>
		)

		return portal ? (
			<Portal name={VIEWPORT_PORTAL_NAME}>
				{content}
			</Portal>
		) : content;
	}

	return (
		<Popover
			className={cx("popover", className)}>
			{({open, close}) => (
				<>
					<div ref={ref ? ref : setReferenceElement} className={styles.popperDest}>
						<Popover.Button className={cx("trigger", triggerClassName)}>
							{typeof trigger === "function" ? trigger(open) : trigger}
						</Popover.Button>
					</div>

					{(open || alwaysOpen) && renderContent(close)}
				</>
			)}
		</Popover>
	);
});
