import {Dropdown} from "../../../shared";
import React, {ReactElement, useContext, useState} from "react";
import {CreateModalContext} from "../../../context/create-modal-context";
import styles from "./add-list-dropdown.module.scss";
import {useQuery} from "@apollo/client";
import {GET_SEGMENT_NAMES} from "../../../graphql/queries/queries";
import {SegmentIdNamePage} from "../../../models/workspace-segment";
import {SearchInput} from "../../../shared/v2/inputs";

export interface AddListDropdownProps {
	anchorEl: HTMLElement | null;
	workspaceId: string;
	addToList: (id: string) => void;
	closeCallback: () => void;
	showModal?: () => void;
}
/**
 * @param anchorEl HTMLElement the dropdown attaches to
 * @param closeCallback How to handle closing dropdown
 */

const AddListDropdown = (props: AddListDropdownProps): ReactElement => {
	const {anchorEl, workspaceId, addToList, closeCallback, showModal} = props;
	const [filterValue, setFilterValue] = useState("");

	const {setShowAddList} = useContext(CreateModalContext);

	const {data, loading} = useQuery<SegmentIdNamePage>(GET_SEGMENT_NAMES, {
		skip: !anchorEl,
		variables: {workspaceId},
		fetchPolicy: "no-cache",
	});

	const handleCreate = (): void => {
		if (showModal) {
			showModal();
			return closeCallback();
		}
		setShowAddList(true);
		closeCallback();
	};

	const handleOptionSelected = (id: string): void => {
		addToList(id);
		closeCallback();
	};

	return (
		<Dropdown anchorEl={anchorEl} closeMenu={closeCallback} keepOpen width={240} height={250}>
			<p className={styles.header}>Add to List</p>
			<div className={styles["input-container"]}>
				<SearchInput
					id="filter-lists"
					value={filterValue}
					onChange={setFilterValue}
				/>
			</div>
			<div className={styles.list}>
				<Dropdown.Item
					className={styles.link}
					options={{onClick: handleCreate}}
				>
					+ New list
				</Dropdown.Item>
				{loading ?
					<div className={styles.reel}>Loading...</div>
					: data && data.segments.items.length > 0 ? data.segments.items
						.filter(segment => segment.name.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()))
						.map(segment =>
							<Dropdown.Item
								key={segment.id}
								options={{onClick: () => handleOptionSelected(segment.id)}}
								className={styles.item}
							>
								{segment.name}
							</Dropdown.Item>)
						: <div className={styles.reel}>No reels found</div>
				}
			</div>
		</Dropdown>
	);
};


export {AddListDropdown};
