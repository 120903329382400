import React, {ReactElement, ReactNode, createContext, useMemo} from "react";
import {useImageHistoryContext} from "./image-history";

export interface ImageElementContextValue {
  element: HTMLImageElement | null;
}

export const ImageElementContext =
  createContext<ImageElementContextValue | undefined>(undefined);

export const ImageElementContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {imageSrc} = useImageHistoryContext();

  const element = useMemo(() => {
    if (!imageSrc) {
      return null;
    }

    const img = new Image();
    img.src = imageSrc;
    img.crossOrigin = "anonymous";

    return img;
  }, [imageSrc]);

  return (
    <ImageElementContext.Provider value={{element}}>
      {children}
    </ImageElementContext.Provider>
  );
};

export const useImageElementContext = (): ImageElementContextValue => {
  const context = React.useContext(ImageElementContext);

  if (context === undefined) {
    throw new Error(
      "useImageElementContext must be used within a ImageElementContextProvider",
    );
  }

  return context;
};
