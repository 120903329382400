import {Image as KonvaImage} from "react-konva";
import {ImageConfig} from "konva/lib/shapes/Image";
import React, {ReactElement, useMemo} from "react";

export interface CanvasImageProps extends Omit<ImageConfig, "image"> {
  src: string | null;
}

export const CanvasImage = ({src, ...props}: CanvasImageProps): ReactElement => {
  const imageEl = useMemo(() => {
    if (!src) {
      return;
    }

    const img = new Image();
    img.src = src;
    img.crossOrigin = "anonymous";
    return img;
  }, [src]);

  return (
    <KonvaImage image={imageEl} {...props} />
  );
}
