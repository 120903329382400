import * as SliderPrimitive from "@radix-ui/react-slider"
import React, {ReactElement, useEffect, useRef, useState} from "react";
import classNames from "classnames/bind";

import {Body} from "../typography";
import {Tooltip} from "../tooltip";
import {useFocusVisible} from "../../../hooks";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./slider.module.scss";

const bStyles = classNames.bind(styles);

export interface SliderProps extends SliderPrimitive.SliderProps {
  tooltipTransform?: (value: number) => string;
}

export const Slider = ({
	tooltipTransform,
	value,
	...props
}: SliderProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [tooltipContent, setTooltipContent] = useState<string>("");
	const thumbRef = useRef<HTMLDivElement>(null);
	const isFocusVisible = useFocusVisible(thumbRef);

	useEffect(() => {
		if (!tooltipTransform) {
			return;
		}

		return setTooltipContent(tooltipTransform(value?.[0] ?? 0));
	}, [value]);

	return (
		<SliderPrimitive.Root className={bStyles("sliderRoot", {isDarkMode})} value={value} {...props}>
			<SliderPrimitive.Track className={styles.sliderTrack}>
				<SliderPrimitive.Range className={styles.sliderRange} />
			</SliderPrimitive.Track>
			<SliderPrimitive.Thumb ref={thumbRef} className={styles.sliderThumbWrapper} aria-label="Volume">
				{tooltipContent ? (
					<Tooltip
						key={isFocusVisible ? "focus-visible" : "default"}
						content={
							<Body size="xs">
								{tooltipContent}
							</Body>
						}
						containerClassname={styles.sliderThumb}
						placement="top"
						visible={isFocusVisible ? true : undefined}
					/>
				) : (
					<div className={styles.sliderThumb} />
				)}
			</SliderPrimitive.Thumb>
		</SliderPrimitive.Root>
	)
}
