import {Group} from "react-konva";
import {isoLines} from "marchingsquares";
import {LineConfig} from "konva/lib/shapes/Line";
import Konva from "konva";
import React, {ReactElement, useEffect, useRef, useState} from "react";

import {
  addBorderToBinaryMask,
  calculatePathsFromContours,
  createBinaryMask,
  createImageOfLines,
} from "./helpers";
import {MarchingAntsLine, MarchingAntsLineProps} from "../marching-ants-line";
import {useCanvasContext} from "../../../../contexts/canvas-area-selection";

export interface ContoursProps extends MarchingAntsLineProps {
  isPainting: boolean;
}

export const Contours = ({isPainting, ...props}: ContoursProps): ReactElement => {
  const ref = useRef<Konva.Group>(null);
  const {lines} = useCanvasContext();
  const [borderPaths, setBorderPaths] = useState<number[][]>([]);

  useEffect(() => {
    if (!ref.current || isPainting) {
      return;
    }

    const stage = ref.current.getStage();

    if (!stage) {
      return;
    }

    const size = stage?.size();

    if (!size || size.width === 0 || size.height === 0) {
      return;
    }

    const imageData = createImageOfLines(lines, size.width, size.height);
    const binaryMask = createBinaryMask(imageData);
    const borderedMask = addBorderToBinaryMask(binaryMask);
    const contours = isoLines(borderedMask, 0.5, {noFrame: true});
    const paths = calculatePathsFromContours(contours);

    setBorderPaths(paths);
  }, [isPainting, lines, ref.current]);

  return (
    <Group ref={ref}>
      {borderPaths.map((path, index) => (
        <MarchingAntsLine key={index} points={path} {...props} />
      ))}
    </Group>
  );
}
