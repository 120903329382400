
/* eslint-disable react/prop-types */
import {
	Heading,
	LayoutContainer,
	NewAvatar,
	Options,
	formatDate,
	updateObject,
} from "../../../shared";
import React, {
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {GET_ALL_SURVEY_NAMES} from "../../../graphql/queries/survey-queries";
import {
	ResponsesFilter,
	ResponseWithRewards,
	ResponseWithRewardsPageData,
} from "../../../models/response";
import {AlignText} from "../../../shared/typography/align-text";
import {SurveyNamePageData} from "../../../models/survey";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {NetworkStatus, useMutation, useQuery} from "@apollo/client";
import {useLoadingQuery} from "../../../hooks";
import {Column, HeaderGroup, Row} from "react-table";
import {PaginatedTable} from "../../../shared/components/table/paginated-table";
import styles from "./rewards.module.scss";
import {useNavigate, useSearchParams} from "../../../route";
import {TremendousRewardModal} from "../../modals/tremendous-reward";
import {
	SEND_REWARDS,
	UPDATE_TREMENDOUS_SETTINGS,
} from "../../../graphql/mutations/reward-mutations";
import {
	ParsedReward,
	ResponsesSort,
	RewardSettingReturnNoConfig,
	RewardTypeName,
	SendRewardsInput,
	SendRewardsResult,
	TremendousCurrencyType,
	UpdateTremendousSettingsInput,
	UpdateTremendousSettingsReturn,
} from "../../../models/rewards";
import {REWARD_FRAGMENT} from "../../../graphql/fragments/fragments";
import {ToastContext} from "../../../context/toast-context";
import {
	GET_FUNDING_SOURCES,
	GET_REWARDS_DATA,
	GET_REWARD_SETTINGS,
	GET_REWARD_SETTINGS_NOCONFIG,
} from "../../../graphql/queries/reward-queries";
import {EmptyScreen} from "../../../contacts/components/empty-screen";
import {TremendousIntegrateModal} from "../../../modals/tremendous";
import {useMount} from "../../../hooks/useMount";
import {SearchableFilter} from "../../../shared/components/searchable-filter";
import {uniqWith} from "lodash-es";
import {convertResponsesFilter, unconvertResponsesFilter} from "../../../route/useFilter";
import {currencySymbol} from "../../../shared/utility/utility";
import {Body, Button, Checkbox, DebounceSearch, LoadingContainer} from "../../../shared/v2";

const PAGE_SIZE = 100;

const RewardsPage = (): ReactElement => {
	const {
		workspace: {id, permissions},
	} = useWorkspaceContext();
	const {updateToast, onError} = useContext(ToastContext);
	const navigate = useNavigate();
	const unparsedSearch = useSearchParams();
	const parsedSearch = unconvertResponsesFilter(unparsedSearch);
	const {completed = undefined, surveyIds = []} = parsedSearch;

	const [search, setSearch] = useState<string>(() => parsedSearch.search);
	const [currentPage, setCurrentPage] = useState(0);
	const [showReward, setShowReward] = useState(false);
	const [sortBy, setSortBy] = useState(ResponsesSort.DEFAULT);
	const [showTremendous, setShowTremendous] = useState(false);
	const [singleId, setSingleId] = useState({surveyId: "", responseId: ""});
	const [selectAll, setSelectAll] = useState(false);
	const [selectedResponses, setSelectedResponses] = useState<Row<ResponseWithRewards>[]>(
		[],
	);
	// Used to determine if user can see the configure / disable / enable buttons
	const canManageTremendousSettings = useMemo(() => {
		if (!permissions) return false;
		return permissions.some(value => value.includes("tremendousSettings"));
	}, []);

	const {data: campaignsData} = useQuery<SurveyNamePageData>(GET_ALL_SURVEY_NAMES, {
		variables: {workspaceId: id},
	});

	const {stopPolling, startPolling} = useQuery(GET_FUNDING_SOURCES, {
		skip: !id,
		variables: {id},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
	});

	useMount(() => {document.title = "Vurvey - Rewards"});

	// Every 15 seconds while we are on this page we'll poll for the funding source.
	// May need to change this in the near future.
	useEffect(() => {
		if (id) {
			startPolling?.(15000);
			return () => {
				stopPolling();
			};
		}
	}, [id, startPolling, stopPolling]);

	const {
		data: responseData,
		handleFetchMore,
		fetchMoreFragment,
		networkStatus,
	} = useLoadingQuery<ResponseWithRewardsPageData>(GET_REWARDS_DATA, {
		variables: {
			workspaceId: id,
			limit: PAGE_SIZE,
			filter: {
				name: search || undefined,
				completed,
				surveyIds: surveyIds.length > 0 ? surveyIds : undefined,
				// we cannot pay users that have deleted their account since we no
				// longer have their email to send rewards to. This is one of the few
				// cases we want to exclude deleted users data from responses as typically
				// we want to include responses for deleted users for the purposes of analysis.
				excludeDeletedUsers: true,
			},
			sort: sortBy,
		},
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-first",
		errorPolicy: "all",
		notifyOnNetworkStatusChange: true,
	});
	const {data: settings, fragment: settingsFrag} =
		useLoadingQuery<RewardSettingReturnNoConfig>(GET_REWARD_SETTINGS_NOCONFIG, {
			variables: {workspaceId: id},
		});

	const [sendReward] = useMutation<SendRewardsResult, SendRewardsInput>(SEND_REWARDS);
	const [updateTremendous] =

		useMutation<UpdateTremendousSettingsReturn, UpdateTremendousSettingsInput>(
			UPDATE_TREMENDOUS_SETTINGS,
			{
				// May eventually try to do this through cache. Little convoluted.
				refetchQueries: [
					{
						query: GET_REWARD_SETTINGS,
						variables: {workspaceId: id},
					},
				],
			},
		);

	const campaigns = useMemo(
		() => campaignsData?.workspaceSurveys.items.map(c => ({id: c.id, name: c.name})),
		[campaignsData],
	);

	const currentData = useMemo(
		() =>
			responseData?.responses.items.slice(
				currentPage * PAGE_SIZE,
				(currentPage + 1) * PAGE_SIZE,
			),
		[responseData, currentPage],
	);
	const columns = useMemo(
		(): Column<ResponseWithRewards>[] => [
			{
				Header: "Name",
				accessor: "user",
				Cell: ({value}) => <NewAvatar user={value} showName showTag/>,
			},
			{
				Header: "Campaign",
				accessor: "surveyId",
				// Might be nicer to just have surveyName or survey on the response
				Cell: ({value}) => (
					<div className={styles.campaignCell}>
						{campaigns?.find(o => o.id === value)?.name || ""}
					</div>
				),
			},
			{
				Header: "Completed",
				accessor: "completedAt",
				Cell: ({value}) => (value ? formatDate(value) : "Partial"),
			},
			{
				Header: "Rewards",
				accessor: "rewards",
				Cell: ({value}) => {
					return value.items.length > 0
						? value.items.map(item => {
							const parsed: ParsedReward = JSON.parse(item.configuration);
							return (
								<p className={styles.reward} key={item.id}>
									{currencySymbol(parsed.currency || TremendousCurrencyType.USD)}
									{parsed.amount}
									<span className={styles.status}>
											({item.status})
									</span>
								</p>
							);
						})
						: "No rewards";
				},
			},
			{
				id: "options",
				Header: "",
				Cell: ({row: {original}}) => (
					<AlignText align="right">
						<Options
							type="menu-vertical"
							options={[
								{
									name: "See Campaign",
									actionOptions: {
										onClick: () =>
											navigate(`/survey/${original.surveyId}/results`, {workspace: true}),
									},
									icon: "eye",
								},
								{
									name: "Send Reward",
									actionOptions: {
										onClick: () => {
											setShowReward(true);
											setSingleId({responseId: original.id, surveyId: original.surveyId});
										},
									},
									icon: "star",
								},
							]}
						/>
					</AlignText>
				),
			},
		],
		[campaignsData],
	);

	const toggleRewardModal = (): void => {
		setShowReward(prev => !prev);
		setSingleId({responseId: "", surveyId: ""});
	};

	/**
	 * Since we can now select across multiple surveys, we now need to determine what
	 * users belong to which survey and then call sendReward for each one.
	 * This might be messy.
	 */

	const handlePay = (
		value: string,
		currency: TremendousCurrencyType,
		funds?: string,
		camp?: string,
	): void => {
		const campaignId = camp || undefined;
		const fundingId = funds || undefined;

		/**
		 * Using the "select all" method. Thankfully handles across multiple surveys on its own
		 */
		if (selectAll) {
			sendReward({
				variables: {
					workspaceId: id,
					useFilter: selectAll,
					rewardTypeName: RewardTypeName.TREMENDOUS,
					filter: {
						name: search || undefined,
						completed,
						surveyIds: surveyIds.length > 0 ? surveyIds : undefined,
					},
					settingsForFilteredRewards: {
						amount: value,
						currency,
						fundingId,
						campaignId,
					},
				},
				onCompleted: () => {
					setShowReward(false);
					setSingleId({surveyId: "", responseId: ""});
					setSelectedResponses([]);
					updateToast({
						description: "Sent rewards out to selected users",
						type: "informational",
					});
				},
				onError,
				update(cache, {data}) {
					if (!data) return;
					const {sendRewards} = data;
					sendRewards.forEach(reward => {
						const newRef = cache.writeFragment({
							data: reward,
							fragment: REWARD_FRAGMENT,
						});
						// Gotta go one deeper to get to the page object.
						cache.modify({
							id: `Response:${reward.response.id}`,
							fields: {
								rewards(existing = []) {
									return updateObject(existing, {items: [...existing.items, newRef]});
								},
							},
						});
					});
				},
			});
			return;
		}

		if (singleId.responseId && singleId.surveyId) {
			sendReward({
				variables: {
					workspaceId: id,
					rewardTypeName: RewardTypeName.TREMENDOUS,
					rewards: {
						surveyId: singleId.surveyId,
						amount: value,
						responseIds: singleId.responseId,
						campaignId,
						fundingId,
						currency,
					},
				},
				onCompleted: ({sendRewards}) => {
					setShowReward(false);
					setSingleId({surveyId: "", responseId: ""});
					setSelectedResponses([]);
					updateToast({
						description: `Sent rewards to ${sendRewards.length} users`,
						type: "informational",
					});
				},
				onError,
				update(cache, {data}) {
					if (!data) return;
					const {sendRewards} = data;
					sendRewards.forEach(reward => {
						const newRef = cache.writeFragment({
							data: reward,
							fragment: REWARD_FRAGMENT,
						});
						// Gotta go one deeper to get to the page object.
						cache.modify({
							id: `Response:${reward.response.id}`,
							fields: {
								rewards(existing = []) {
									return updateObject(existing, {items: [...existing.items, newRef]});
								},
							},
						});
					});
				},
			});
		} else {
			const responses = selectedResponses?.map(r => ({
				surveyId: r.original.surveyId,
				responseIds: [r.original.id],
			}));
			// Combine responseIds with like surveyIds
			// this might be useful as a util function, fine just here for now.
			const merged = uniqWith(responses, (pre, cur) => {
				if (pre.surveyId === cur.surveyId) {
					cur.responseIds = cur.responseIds.concat(pre.responseIds);
					return true;
				}
				return false;
			});
			const count = merged.length;
			// Here is the messy part
			merged.forEach((response, i) => {
				sendReward({
					variables: {
						workspaceId: id,
						rewardTypeName: RewardTypeName.TREMENDOUS,
						rewards: {
							surveyId: response.surveyId,
							amount: value,
							responseIds: response.responseIds,
							campaignId,
							fundingId,
							currency,
						},
					},
					onCompleted: () => {
						if (i !== count - 1) return;
						setShowReward(false);
						setSingleId({surveyId: "", responseId: ""});
						setSelectedResponses([]);
						updateToast({
							description: "Sent rewards out to selected users",
							type: "informational",
						});
					},
					onError,
					update(cache, {data}) {
						if (!data) return;
						const {sendRewards} = data;
						sendRewards.forEach(reward => {
							const newRef = cache.writeFragment({
								data: reward,
								fragment: REWARD_FRAGMENT,
							});
							// Gotta go one deeper to get to the page object.
							cache.modify({
								id: `Response:${reward.response.id}`,
								fields: {
									rewards(existing = []) {
										return updateObject(existing, {items: [...existing.items, newRef]});
									},
								},
							});
						});
					},
				});
			});
		}
	};

	const changeRewardsSearch = (newSearch: Partial<ResponsesFilter>): void => {
		navigate({search: convertResponsesFilter(newSearch)}, {search: true});
	};

	const handleDisable = (): void => {
		updateTremendous({variables: {workspaceId: id, disable: true}});
	};

	const handleEnable = (): void => {
		updateTremendous({variables: {workspaceId: id, enable: true}});
	};

	const handleCompletesOnly = (e: React.ChangeEvent<HTMLInputElement>): void => {
		changeRewardsSearch({completed: e.target.checked || undefined});
	};

	const handleCampaignsFilter = (selected: {id: string; name: string}): void => {
		if (surveyIds.includes(selected.id)) {
			changeRewardsSearch({
				surveyIds: surveyIds.filter((val: string) => val !== selected.id),
			});
		} else {
			changeRewardsSearch({surveyIds: [...surveyIds, selected.id]});
		}
		// Reset selected
		setSelectedResponses([]);
		setSingleId({surveyId: "", responseId: ""});
	};

	const handleClearFilter = (): void => {
		changeRewardsSearch({surveyIds: []});
	};

	const handleSearch = (newValue: string): void => {
		setSelectedResponses([]);
		setSearch(newValue);
	};

	const handleSort = (value: HeaderGroup<ResponseWithRewards>): void => {
		if (!value.canSort) return;

		switch (value.Header) {
		case "Name":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ResponsesSort.CREATOR_TAG_DESC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.CREATOR_TAG_ASC);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		case "Campaign":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ResponsesSort.CAMPAIGN_ZA);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.CAMPAIGN_AZ);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		case "Completed":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ResponsesSort.DATE_COMPLETED_OLDEST);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.DATE_COMPLETED_MOST_RECENT);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		case "Rewards":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ResponsesSort.LAST_REWARD_SENT_DESC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.LAST_REWARD_SENT_ASC);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		default:
			setSortBy(ResponsesSort.DEFAULT);
		}

	};

	const toggleTremendousModal = (): void => setShowTremendous(prev => !prev);

	// Render logic looked a bit weird in the return.
	const renderActions = useCallback((): JSX.Element | null => {
		if (!canManageTremendousSettings) return null;
		if (settings && settings.workspaceRewardSettings.length > 0) {
			const [setting] = settings.workspaceRewardSettings;
			return (
				<>
					{setting.enabledStatus ? (
						<Button style="danger" onClick={handleDisable}>Disable</Button>
					) : (
						<Button onClick={handleEnable}>Enable</Button>
					)}
					<Button onClick={toggleTremendousModal}>Configure</Button>
				</>
			);
		}
		return null;
	}, [settings, canManageTremendousSettings]);

	if (settingsFrag) return settingsFrag;

	return (
		<LayoutContainer className={styles.container}>
			<header className={styles.header}>
				<Heading size="lg">Rewards</Heading>
				<div className={styles.headerActions}>{renderActions()}</div>
			</header>
			{campaigns && (
				<div className={styles.actions}>
					<div className={styles.inputs}>
						<DebounceSearch
							id="search-creator-name"
							placeholder="Search by creator name..."
							value={search}
							onChange={handleSearch}
							className={styles.search}
						/>
						<SearchableFilter
							options={campaigns}
							value={surveyIds}
							placeholder="All Campaigns"
							onChange={handleCampaignsFilter}
							onClear={handleClearFilter}
							selectedLabel={
								surveyIds.length === 1 ? "1 campaign" : `${surveyIds.length} campaigns`
							}
						/>
						<div>
							<Checkbox
								id="only-completed"
								size="s"
								className={styles.completedCheckbox}
								onChange={handleCompletesOnly}
								checked={completed || false}
								text={(
									<Body size="xs">
										Completes Only
									</Body>
								)}
							/>
						</div>
					</div>
					{selectedResponses.length > 0 && responseData && (
						<Button
							onClick={toggleRewardModal}
						>
							{`Send Rewards (${
								selectAll
									? responseData.responses.remaining + responseData.responses.items.length
									: selectedResponses.length
							})`}
						</Button>
					)}
				</div>
			)}
			{!settings || settings.workspaceRewardSettings.length === 0 ? (
				<EmptyScreen whatIsEmpty="REWARDS">
					{canManageTremendousSettings ? (
						<Button
							onClick={toggleTremendousModal}
						>
							Configure Tremendous
						</Button>

					) : (
						<div className={styles.cantManage}>
							You do not have permissions to set up rewards. Contact an admin or your
							workspace owner.
						</div>
					)}
				</EmptyScreen>
			) : settings.workspaceRewardSettings[0].enabledStatus ? (
				(networkStatus === NetworkStatus.loading && <LoadingContainer />) ||
				(responseData && currentData && (
					<>
						<PaginatedTable
							columns={columns}
							data={currentData}
							dataLength={responseData.responses.items.length}
							totalCount={
								responseData.responses.remaining + responseData.responses.items.length
							}
							handleFetchMore={handleFetchMore}
							pageSize={PAGE_SIZE}
							pageState={[currentPage, setCurrentPage]}
							selectedValues={selectedResponses}
							onSelectChange={setSelectedResponses}
							selectAllState={[selectAll, setSelectAll]}
							fetchMoreFragment={fetchMoreFragment}
							sortLoading={networkStatus === NetworkStatus.setVariables}
							onSort={handleSort}
						/>
						<TremendousRewardModal
							isOpen={showReward}
							onClose={toggleRewardModal}
							handlePay={handlePay}
							creatorCount={
								singleId.surveyId
									? 1
									: selectAll
										? responseData.responses.remaining + responseData.responses.items.length
										: selectedResponses.length
							}
						/>
					</>
				))
			) : (
				<div className={styles.helpText}>
					You have rewards integrated but they are not enabled. If you cannot enable them
					contact your workspace owner or an Admin.
				</div>
			)}
			{canManageTremendousSettings && (
				<TremendousIntegrateModal
					isOpen={showTremendous}
					onClose={toggleTremendousModal}
				/>
			)}
		</LayoutContainer>
	);
};

export {RewardsPage};
