import {useCallback, useRef, useState} from "react";

export const useTask = <T, RunProps extends Array<unknown>>(
	task: (...args: RunProps) => Promise<T>,
	deps?: any[]
) => {
	const [loading, setLoading] = useState(false);
	const loadingRef = useRef(false);

	const handleSetLoading = (value: boolean) => {
		setLoading(value);
		loadingRef.current = value;
	}

	const run = async (...args: RunProps) => {
		handleSetLoading(true);
		try {
			return await task(...args);
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
		} catch (e) {
			handleSetLoading(false);
		} finally {
			handleSetLoading(false);
		}
	};

	const callback = useCallback(run, deps || []);

	return {loading, loadingRef, run: deps ? callback : run};
}
