import React, {ReactElement, useContext, useEffect, useState} from "react";
import styles from "./contact-modal.module.scss";
import {useWorkspaceContext} from "../../context/workspace-context";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import {GET_CONTACT_DETAILS} from "../../graphql/queries/queries";
import {ContactDataVars, CreatorDetailsData} from "../../models/user";
import {NewAvatar as Avatar, Icon} from "../../shared";
import {useLoadingQuery} from "../../hooks";
import {Card} from "../../shared/layout/card";
import {convertMiliToHourFormat} from "../../shared/utility/utility";
import {useSegmentOptions} from "../../hooks/useSegmentOptions";
import {useMutation} from "@apollo/client";
import {ADD_WORKSPACE_SEGMENT_MEMBERS} from "../../graphql/mutations/mutations";
import {ButtonWithDropdown} from "../../shared/components/buttons/button-with-dropdown";
import {ToastContext} from "../../context/toast-context";
import {Button, Body, Subheader, ButtonIcon, Modal, BaseModalProps} from "../../shared/v2";

export interface ContactModalProps extends BaseModalProps {
	userId: string;
}

const ContactModal = (props: ContactModalProps): ReactElement => {
	const {isOpen, onClose, userId} = props;
	const {
		workspace: {id},
	} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);
	const [showVideo, setShowVideo] = useState(true);
	const {data, fragment} = useLoadingQuery<CreatorDetailsData, ContactDataVars>(GET_CONTACT_DETAILS, {
		variables: {workspaceId: id, userId},
		skip: !isOpen || !id || !userId, // Will not query unless the modal is showing
		errorPolicy: "all",
		what: "Creator",
	});

	const [sendToSegment] = useMutation(ADD_WORKSPACE_SEGMENT_MEMBERS);

	const handleSend = (listId: string): void => {
		sendToSegment({
			variables: {segmentId: listId, userIds: userId, workspaceId: id},
			onError: (err) => {
				updateToast({description: err.message, type: "failure"});
			},
			onCompleted: (sent) => {
				const addedUsersLength = sent.addSegmentMembers.addedUsers.length;
				const existingUsersLength = sent.addSegmentMembers.existingUsers.length;

				if (addedUsersLength > 0) {
					updateToast({description: "Creator added to list", type: "informational"});
				}
				if (existingUsersLength > 0) {
					updateToast({description: "Creator already in the list", type: "informational"});
				}
			},
		});
	};

	useEffect(() => {
		if (!isOpen) setShowVideo(false);
	}, [isOpen]);

	const toggleVideo = (): void => setShowVideo((prev) => !prev);
	const options = useSegmentOptions(handleSend);
	const {contact} = data || {};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="large">
			{fragment}
			{contact && (
				<>
					<header className={styles.header}>
						<Avatar user={contact} size="halfFull" />
						<div className={styles.names}>
							<Subheader size="l">{contact.creatorTag || ''}</Subheader>
							<Body size="s" color="text-secondary">
								{`${contact.firstName || ''} ${contact.lastInitial || ''}`}
							</Body>
						</div>
						<div className={styles.actions}>
							{options && <ButtonWithDropdown options={options}>Add to List</ButtonWithDropdown>}
						</div>
					</header>
					<div className={styles.mainGrid}>
						<div className={styles.video}>
							{contact.sensemakeReel && contact.sensemakeReel.video ? (
								showVideo ? (
									<>
										<video src={contact.sensemakeReel.video.mp4} controls />
										<ButtonIcon
											color="text-secondary"
											className={styles.cancel}
											icon={<Icon name="cross" />}
											onClick={toggleVideo}
										/>
									</>
								) : (
									<>
										<img src={contact.sensemakeReel.video.thumbnail} />
										<Button className={styles.button} onClick={toggleVideo}>
											Watch Reel
										</Button>
									</>
								)
							) : (
								<div className={styles.empty}>
									<p>We&apos;re building a reel to show off some of this creator&apos;s responses. Check back soon!</p>
									<img src="/images/vurvey-big.png" />
								</div>
							)}
						</div>
						<div className={styles.main}>
							<div className={styles.cardGrid}>
								<Card className={styles.card}>
									<Body className={styles.cardHeader} size="xs">
										Total responses
									</Body>
									<Subheader size="l">{contact?.answerCount?.toString() || '0'}</Subheader>
								</Card>
								<Card className={styles.card}>
									<Body className={styles.cardHeader} size="xs">
										Video minutes
									</Body>
									<Subheader size="l">{convertMiliToHourFormat(contact.totalAnswerDuration)}</Subheader>
								</Card>
								<Card className={styles.card}>
									<Body className={styles.cardHeader} size="xs">
										Engagement
									</Body>
									<Subheader size="l">{contact.insights?.engagement || "N/A"}</Subheader>
								</Card>
							</div>
							<Tabs selectedTabClassName={styles.selected} className={styles.tabs}>
								<TabList className={styles.tabList}>
									<Tab>Summary</Tab>
									<Tab>Interests</Tab>
								</TabList>
								<TabPanel className={styles.panel}>
									<Body size="xs" color="text-secondary">
										{contact.insights?.summary || "This creator has no summary at this time."}
									</Body>
								</TabPanel>
								<TabPanel className={styles.panel}>
									<Body size="xs" color="text-secondary">
										{contact.insights?.interests || "We're still learning about this creator's interests."}
									</Body>
								</TabPanel>
							</Tabs>
						</div>
					</div>
				</>
			)}
		</Modal>
	);
};

export {ContactModal};
