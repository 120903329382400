import {useCallback, useRef} from "react";
import {useObserver} from "./useObserver";

export const useSameHeight = (element: HTMLElement | null) => {
  const ref = useRef<any>(null);

  const handleResize = useCallback(() => {
    if (!ref.current || !element) {
      return;
    }

    ref.current.style.height = `${element.clientHeight}px`;
  }, [element]);

  useObserver(ref, ResizeObserver, handleResize);

  return ref;
}
