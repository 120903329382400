import {useState} from "react";
import {FetchResult, useMutation} from "@apollo/client";

import {ADD_FILE_TO_TRAINING_SET,
	ADD_VIDEO_TO_TRAINING_SET,
	CREATE_TRAINING_SET_FILE,
	CREATE_TRAINING_SET_VIDEO} from "../graphql/mutations/ai-mutations";
import {UploadResult} from "../models/upload-result";
import {FileWithUploadResult, TrainingSet, TrainingSetFile} from "../models/ai-model";
import {UPLOAD_FILE} from "../graphql/mutations/mutations";
import {useToastContext} from "../context/toast-context";

export interface TrainingSetUploadHookReturn {
	files: FileWithUploadResult[];
	setFiles: (files: FileWithUploadResult[]) => void;
	uploadFileInProgress: boolean;
	isCreatingFileOrVideo: boolean;
	isAddingFileOrVideoToTrainingSet: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	uploadFile: any;
	createTrainingSetFile: (file: UploadResult, trainingSetId: string) => Promise<FetchResult>;
	addFileToTrainingSet: (file: TrainingSetFile, trainingSetId: string) => Promise<FetchResult>;
}

export const useTrainingSetUpload = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	refetchQuery?: any,
	trainingSet?: TrainingSet,
	startPolling?: () => void,
): TrainingSetUploadHookReturn => {
	const [files, setFiles] = useState<FileWithUploadResult[]>([]);
	const {updateToast} = useToastContext();

	const [uploadFile, {loading: uploadFileInProgress}] = useMutation(UPLOAD_FILE, {
		onError: error => {
			updateToast({
				description: error.message,
				type: "failure",
			});
		},
	});
	const [createTrainingSetFileMutation, {loading: isCreatingTrainingSetFile}] =
		useMutation(CREATE_TRAINING_SET_FILE);
	const [addFileToTrainingSetMutation, {loading: isAddingFileToTrainingSet}] =
		useMutation(ADD_FILE_TO_TRAINING_SET, {refetchQueries: [refetchQuery]});

	const [createTrainingSetVideoMutation, {loading: isCreatingTrainingSetVideo}] =
		useMutation(CREATE_TRAINING_SET_VIDEO);

	const [addVideoToTrainingSetMutation, {loading: isAddingVideoToTrainingSet}] =
		useMutation(ADD_VIDEO_TO_TRAINING_SET, {refetchQueries: [refetchQuery]});

	const createTrainingSetFile = async(file: UploadResult, trainingSetId): Promise<FetchResult> => {
		const mutationFunction = file.mimetype.includes("video") ?
			createTrainingSetVideoMutation : createTrainingSetFileMutation;

		return mutationFunction({
			variables: {
				trainingSetId,
				uploadItemId: file.id,
			},
			onError: error => {
				updateToast({
					description: error.message,
					type: "failure",
				});
			},
		});
	};

	const addFileToTrainingSet = async(
		file: TrainingSetFile,
		trainingSetId,
	): Promise<FetchResult> => {
		const mutationFunction = file.originalMimetype.includes("video") ?
			addVideoToTrainingSetMutation : addFileToTrainingSetMutation;

		return mutationFunction({
			variables: {
				fileId: file.id,
				trainingSetId,
				videoId: file.originalMimetype.includes("video") ? file.id : undefined,
			},
			onCompleted: () => {
				updateToast({
					description: "File uploaded. Processing in progress. Please wait for status to show 'Done' before using in chat.",
					type: "informational",
				});
				startPolling?.();
			},
			onError: error => {
				updateToast({
					description: error.message,
					type: "failure",
				});
			},
		});
	};

	return {
		files,
		setFiles,
		createTrainingSetFile,
		addFileToTrainingSet,
		uploadFile,
		uploadFileInProgress,
		isAddingFileOrVideoToTrainingSet: isAddingFileToTrainingSet || isAddingVideoToTrainingSet,
		isCreatingFileOrVideo: isCreatingTrainingSetFile || isCreatingTrainingSetVideo,
	};
};
