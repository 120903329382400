import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Body} from "@/shared/v2";
import {ProcessingStep} from "@/models/ai-orchestration";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./output-steps.module.scss";

const cx = classNames.bind(styles);

export interface OutputStepsProps {
  steps: ProcessingStep[];
}

export const OutputSteps = ({steps}: OutputStepsProps): ReactElement => {
  const {isDarkMode} = useThemeMode();

  return (
    <div className={cx("processingSteps", {isDarkMode})}>
      <Body type="medium" className={styles.sectionTitle}>Processing Steps:</Body>
      {steps.map((step, index) => (
        <div key={`${step.timestamp}-${index}`} className={styles.step}>
          {step.message}
        </div>
      ))}
    </div>
  );
}
