
import {Line} from "react-konva";
import {LineConfig} from "konva/lib/shapes/Line";
import React, {memo} from "react";

export const MemoizedLine = memo(({line}: {line: LineConfig}) => (
  <Line
    stroke="#F00000"
    lineCap="round"
    lineJoin="round"
    globalCompositeOperation="source-over"
    tension={1}
    {...line}
  />
));
