import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {Body, Button, Subheader} from "../../../../shared/v2";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {VurveyLogoIcon} from "../../../../icons";

import styles from "./flows-diabled-state.module.scss";

const cx = classNames.bind(styles);


export const FlowsDisabledState = (): ReactElement => {
  const {isDarkMode} = useThemeMode();

  return <div className={cx("wrapper", {isDarkMode})}>
    <img src={isDarkMode ? "/images/flow-image-dark.png" : "/images/flow-image-white.png"} alt="flow" className={styles.image} />
    <div className={styles.contentWrapper}>
      <div className={styles.textWrapper}>
        <Subheader size="xl" type="medium">Let agents do the work</Subheader>

        <Body color="text-secondary">
          Create objectives, select source materials, and provide specific tasks to each agent along the way. Automations are perfect for repetitive tasks and complex collaborations.
        </Body>
      </div>
      <a href="mailto:customersuccess@vurvey.co" className={styles.link}>
        <Button className={styles.button} leftIcon={<VurveyLogoIcon />}>Get in touch</Button>
      </a>
    </div>
  </div>
}
