import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {ButtonIcon, Subheader, Button} from "@/shared/v2";
import {ChevronLeftIcon, VurveyLogoFullLightIcon, DownloadIcon} from "@/icons";
import {useImageHistoryContext, useImageElementContext, useAiActionsContext} from "../../contexts";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./navigation.module.scss";

const cx = classNames.bind(styles);

export const Navigation = (): ReactElement => {
  const {isDarkMode} = useThemeMode();
  const {clearImage, history, imageSrc: currentEntry} = useImageHistoryContext();
  const {isUpdatingImage, saveImage: saveImageToConversation} = useAiActionsContext();
  const {element: imageEl} = useImageElementContext();
  const isOriginalImage = history[0] === currentEntry;

  const handleClearImage = () => {
    if (isUpdatingImage) {
      return;
    }

    clearImage();
  }

  const downloadImage = () => {
    if (!imageEl) {
      return;
    }

    const a = document.createElement("a");
    a.href = imageEl.src;
    a.target = "_blank";
    a.download = "image.png";
    a.click();
  }

  return (
    <div className={cx("nav", {isDarkMode})}>
      <div className={styles.leftControls}>
        <ButtonIcon
          icon={<ChevronLeftIcon className={styles.arrowBack} />}
          disabled={isUpdatingImage}
          className={styles.backButton}
          onClick={handleClearImage}
        />

        <VurveyLogoFullLightIcon className={styles.logo} />
      </div>

      <Subheader type="semibold" className={styles.header}>
        Image Studio
      </Subheader>

      <div className={styles.rightControls}>
        <Button
          leftIcon={<DownloadIcon />}
          variant="outlined"
          size="small"
          onClick={downloadImage}
        >
          Download
        </Button>

        <Button
          size="small"
          onClick={saveImageToConversation}
          disabled={isOriginalImage || isUpdatingImage}
        >
          Save to conversation
        </Button>
      </div>
    </div>
  );
}
