import {useCallback, useEffect, useState} from "react";
import {useObserver} from "./useObserver";

interface State {
  x: boolean;
  y: boolean;
}

export const useIsOverflowing = (element: HTMLElement | null, deps: any[] = []): State => {
  const [state, setState] = useState<State>({x: false, y: false});

  const updateState = (element: HTMLElement) => {
    const newState = {
      x: element.scrollWidth > element.clientWidth,
      y: element.scrollHeight > element.clientHeight,
    };

    setState(oldState => {
      if (oldState.x !== newState.x || oldState.y !== newState.y) {
        return newState;
      }
      return oldState;
    });
  }

  useEffect(() => {
    if (!element) {
      return;
    }

    updateState(element);
  }, [element, setState]);

  useEffect(() => {
    if (!element) {
      return;
    }

    updateState(element);
  }, deps);

  const observerCallback = useCallback((entries: ResizeObserverEntry[]) => {
    const {target} = entries[0];
    updateState(target as HTMLElement);
  }, []);

  useObserver(element, ResizeObserver, observerCallback);

  return state;
}
