import React, {createContext, useContext} from "react";
import {useMotionValue, MotionValue, animate} from "framer-motion";
import {useCarouselConstraintsContext} from "./carousel-constraints-provider";

interface CarouselScrollContextProps {
  scrollToElement: (id: string) => void;
  x: MotionValue<number>;
	y: MotionValue<number>;
}

const CarouselScrollContext = createContext<CarouselScrollContextProps | undefined>(undefined);

export const CarouselScrollProvider = ({children}: { children: React.ReactNode }) => {
	const x = useMotionValue(0);
	const y = useMotionValue(0);

	const {constraints} = useCarouselConstraintsContext();

	const findCenter = (element: HTMLElement) => {
		const rect = element.getBoundingClientRect();
		return {
			x: rect.left + rect.width / 2,
			y: rect.top + rect.height / 2
		};
	}


	const scrollToElement = (id: string) => {
		const elementPosition = document.getElementById(id);
		if (elementPosition) {
			const center = findCenter(elementPosition);
			const parentCenter = findCenter(elementPosition.offsetParent as HTMLElement);
			const xAxis = -center.x + parentCenter.x;
			const xAxisBetweenConstraints = Math.max(
				Math.min(
					xAxis,
					constraints.right ?? 0
				),
				constraints.left ?? 0
			);

			animate(x, xAxisBetweenConstraints, {
				duration: .2,
				ease: "easeInOut"
			});

			animate(y, 0, {
				duration: .2,
				ease: "easeInOut"
			});
		}
	}

	return (
		<CarouselScrollContext.Provider value={{scrollToElement, x, y}}>
			{children}
		</CarouselScrollContext.Provider>
	);
};

export const useCarouselScrollContext = () => {
	const context = useContext(CarouselScrollContext);
	if (!context) {
		throw new Error("useCarouselScrollContext must be used within a CarouselScrollProvider");
	}
	return context;
};
