import React, {ReactElement, ReactNode, createContext, useCallback, useState} from "react";
import {reject} from "lodash-es";

import {useCreateElement} from "../../hooks";

export interface ChatImageContextValue {
  image: File | null;
  uploadImage: () => void;
  clearImage: () => void;
  getImageBase64: () => Promise<string> | null;
}

export const ChatImageContext =
  createContext<ChatImageContextValue | undefined>(undefined);

export const ChatImageContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const [image, setImage] = useState<File | null>(null);
	const inputElement = useCreateElement<HTMLInputElement>("input", element => {
		element.setAttribute("type", "file");
		element.setAttribute("accept", "image/*");
		element.onchange = (event) => {
			const target = event.target as HTMLInputElement;

			if (target.files) {
				setImage(target.files[0]);
				target.value = "";
			}
		}
	});

	const uploadImage = (): void => {
		if (inputElement) {
			inputElement.click();
		}
	};

	const clearImage = (): void => setImage(null);

	const getImageBase64 = useCallback((): Promise<string> | null => {
		if (!image) {
			return null;
		}

		return new Promise((resolve) => {
			const img = new Image();
			img.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				const scaleFactor = 1024 / img.width;
				canvas.width = 1024;
				canvas.height = img.height * scaleFactor;

				if (ctx) {
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
					const base64 = canvas.toDataURL('image/jpeg').split(',')[1];
					resolve(base64);
				} else {
					reject(new Error('Failed to get 2D context'));
				}
			};
			img.src = URL.createObjectURL(image);
		});
	}, [image]);

	return (
		<ChatImageContext.Provider value={{
			image,
			uploadImage,
			clearImage,
			getImageBase64,
		}}>
			{children}
		</ChatImageContext.Provider>
	);
};

export const useChatImageContext = (): ChatImageContextValue => {
	const context = React.useContext(ChatImageContext);

	if (context === undefined) {
		throw new Error(
			"useChatImageContext must be used within a ChatImageContextProvider",
		);
	}

	return context;
};
