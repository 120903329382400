/**
 * Most types relating to Tremendous API / rewards will be here.
 */

import {ResponsesFilter} from "./response";

export enum RewardTypeName {
	TREMENDOUS = "TREMENDOUS",
}

export enum ResponsesSort {
	DEFAULT = "DEFAULT",
	DATE_COMPLETED_MOST_RECENT = "DATE_COMPLETED_MOST_RECENT",
	DATE_COMPLETED_OLDEST = "DATE_COMPLETED_OLDEST",
	NAME_AZ = "NAME_AZ",
	NAME_ZA = "NAME_ZA",
	CREATOR_TAG_ASC = "CREATOR_TAG_ASC",
	CREATOR_TAG_DESC = "CREATOR_TAG_DESC",
	CAMPAIGN_AZ = "CAMPAIGN_AZ",
	CAMPAIGN_ZA = "CAMPAIGN_ZA",
	LAST_REWARD_SENT_ASC = "LAST_REWARD_SENT_ASC",
	LAST_REWARD_SENT_DESC = "LAST_REWARD_SENT_DESC",
	COMPLETED_AT_ASC = "COMPLETED_AT_ASC",
	COMPLETED_AT_DESC = "COMPLETED_AT_DESC",
	LOCATION_ASC = "LOCATION_ASC",
	LOCATION_DESC = "LOCATION_DESC",
}

export enum TremendousCurrencyType {
	USD = "USD",
	EUR = "EUR",
	CAD = "CAD",
	THB = "THB",
	CNY = "CNY",
	GBP = "GBP",
}

export interface Reward {
	id: string;
	createdAt: Date;
	status: string;
	rewardType: RewardType;
	configuration: string // JSON.parse this for values
	response: {id: string}; // We only want the id for caching
}
export interface RewardsPage {
	items: Reward[];
	cursor: string;
	remaining: string;
}

export interface RewardTransaction {
	id: string;
	rewardType: RewardType;
	createdById: string;
}
// This is an input for the sendRewards mutation
export interface RewardGroup {
	surveyId?: string;
	userIds?: string[];
	/**
	 * Required if we set the surveyId
	 */
	responseIds?: string[] | string;
	amount: string;
	/**
	 * Defaults to USD
	 */
	currency?: TremendousCurrencyType;
	/**
	 * Tremendous. Will be defaultFundingId value if user sets it
	 */
	fundingId?: string;
	/**
	 * Tremendous. Will be defaultCampaignId value if user sets it
	 */
	campaignId?: string;
}

export interface SendRewardsInput {
	workspaceId: string;
	rewardTypeName: RewardTypeName;
	rewards?: RewardGroup;
	useFilter?: boolean;
	filter?: ResponsesFilter;
	settingsForFilteredRewards?: RewardGroup;
}
export interface SendRewardsResult {
	sendRewards: Omit<Reward, "rewardType">[];
}
export interface RewardType {
	id: string;
	name: RewardTypeName;
	description: string;
}
export interface WorkspaceRewardSetting {
	rewardType: RewardType;
	configuration: string;
	workspaceId: string;
	enabledStatus: boolean;
}


export interface ParsedRewardConfig {
	apiKeySet: boolean;
	defaultCampaignId?: string;
	defaultFundingId?: string;
}

export interface ParsedReward {
	/**
	 * Amount comes back as a string. Can probably parseFloat or just leave as is.
	 */
	amount: string
	currency: TremendousCurrencyType;
	campaignId: string // If we want to show what campaign gave money
}

export interface SetTremendousKeyInput {
	workspaceId: string;
	apiKey: string;
}

export interface SetTremendousKeyReturn {
	setTremendousApiKey: WorkspaceRewardSetting;
}

export interface UpdateTremendousSettingsInput {
	workspaceId: string;
	defaultFundingId?: string;
	defaultCampaignId?: string;
	enable?: boolean;
	disable?: boolean;
}

export interface UpdateTremendousSettingsReturn {
	updateTremendousRewardsettings: WorkspaceRewardSetting;
}

export interface TremendousCampaign {
	id: string;
	name: string;
	description?: string;
	products: string[];
}

export interface TremendousFundingSource {
	id: string;
	method: string;
	meta?: string;
}

export interface WorkspaceCampaignReturn {
	workspace: {
		id: string;
		tremendousCampaigns: Omit<TremendousCampaign, "products">[];
	}
}

export interface WorkspaceFundingAndCampaignReturn {
	workspace: {
		id: string;
		tremendousCampaigns: Omit<TremendousCampaign, "products">[];
		tremendousFundingSources: TremendousFundingSource[];
	}
}

export interface WorkspaceFundingReturn {
	workspace: {
		id: string;
		tremendousFundingSources: TremendousFundingSource[];
	}
}

export interface WorkspaceRewardSettingReturn {
	workspaceRewardSettings: WorkspaceRewardSetting[];
}

export interface RewardSettingReturnNoConfig {
	workspaceRewardSettings: Omit<WorkspaceRewardSetting, "configuration">[];
}

