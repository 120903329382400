import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {InputProps} from "../input";
import {InputElements} from "../input-elements";
import {DebounceInput} from "react-debounce-input";

import styles from "../input/input.module.scss";

const bStyles = classNames.bind(styles);

type DebounceSearchProps = Omit<InputProps<string>, 'ref'>;

export const DebounceSearch = ({
	onChange,
	className,
	size = "small",
	variant = "default",
	label,
	hintText,
	leftIcon,
	rightIcon,
	id,
	value,
	...props
}: DebounceSearchProps): ReactElement => {
	const inputProps = {
		className: bStyles("input", size, variant, leftIcon && "withLeftIcon", rightIcon && "withRightIcon"),
		id,
		value,
		minLength: props.minLength,
		maxLength: props.maxLength,
		placeholder: props.placeholder,
		disabled: props.disabled,
		autoComplete: props.autoComplete,
		name: props.name,
	};

	return <InputElements
		variant={variant}
		className={className}
		label={label}
		hintText={hintText}
		leftIcon={leftIcon}
		rightIcon={rightIcon}
	>
		<DebounceInput
			{...inputProps}
			onChange={(e) => onChange(e.target.value)}
			debounceTimeout={300}
		/>
	</InputElements>
}
