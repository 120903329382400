import {first} from "lodash-es";
import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Body, ButtonIcon} from "../../../../../shared/v2";
import {CloseMediumIcon} from "../../../../../icons";
import {ImageWithAlt} from "../../../../../shared/v2/image-with-alt";
import {Persona} from "../../../../../models";
import {useAgentsPageContext} from "../../../../../context/agents-page-context";

import styles from "./drawer-image.module.scss";

const cx = classNames.bind(styles);

export interface DrawerImageProps {
	agent: Persona;
}

export const DrawerImage = ({agent}: DrawerImageProps): ReactElement => {
	const {setCurrentAgent} = useAgentsPageContext();

	return (
		<div className={styles.drawerImage}>
			<ImageWithAlt
				className={styles.avatarImage}
				src={agent.picture?.url || ""}
				alt={agent.name}
			>
				<div className={cx("avatarImage", "noImage")}>
					<Body
						className={styles.initials}
					>
						{first(agent.name)?.toUpperCase()}
					</Body>
				</div>
			</ImageWithAlt>
			<div className={styles.avatarGradient} />
			<ButtonIcon
				icon={<CloseMediumIcon />}
				className={styles.closeIcon}
				size="small"
				onClick={() => setCurrentAgent(null)}
				color="text-body-dark"
				theme="dark"
			/>
		</div>
	);
}
