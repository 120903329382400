import {useMutation} from "@apollo/client";
import React, {ReactElement, useEffect, useState} from "react";
import classNames from "classnames/bind";

import {BaseModalProps, Button, Modal} from "../../shared/v2";
import {CREATE_TRAINING_SET} from "../../graphql/mutations/ai-mutations";
import {FileWithUploadResult, TrainingSet} from "../../models/ai-model";
import {GET_TRAINING_SETS} from "../../graphql/queries/ai-models-queries";
import {useThemeMode} from "../../context/theme-mode-context";
import {useToastContext} from "../../context/toast-context";
import {useTrainingSetUpload} from "../../hooks/useTrainingSetUpload";
import {useWorkspaceContext} from "../../context/workspace-context";
import {TrainingSetForm} from "../../datasets/components";

import styles from "./create-training-set-modal.module.scss";

const bStyles = classNames.bind(styles);

export interface CreateTrainingSetModalProps extends BaseModalProps {
	files: FileWithUploadResult[];
	clearFiles: () => void;
	setTrainingSet: (trainingSet: TrainingSet) => void;
}

export const CreateTrainingSetModal = ({
	isOpen,
	onClose,
	files,
	clearFiles,
	setTrainingSet,
}: CreateTrainingSetModalProps): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {createTrainingSetFile, addFileToTrainingSet} = useTrainingSetUpload();
	const {updateToast} = useToastContext();
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const {isDarkMode} = useThemeMode();

	const [isInProgress, setIsInProgress] = useState(false);
	const [createTrainingSet] = useMutation(CREATE_TRAINING_SET);

	const handleClose = ():void => {
		setIsInProgress(false);
		onClose();
		clearFiles();
		setName("");
		updateToast({
			description: "All files have been successfully uploaded",
			type: "success",
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleCreateAndAddFiles = async() :Promise<any> => {
		setIsInProgress(true);
		const trainingSetData = await createTrainingSet({
			variables: {
				name,
				description,
				workspaceId,
			},
			refetchQueries: [GET_TRAINING_SETS],
			onError: error => {
				const errorMessage = error.message.includes("trainingsets_workspaceid_name_unique") ?
					"The name needs to be unique across workspace." : error.message;

				updateToast({
					description: errorMessage,
					type: "failure",
				});
				setIsInProgress(false);
			},
		});

		const trainingSetId = trainingSetData.data.createTrainingSet.id;

		const addPromises = files.map(async file => {
			const trainingSetFileData = await createTrainingSetFile(
				file.uploadResult,
				trainingSetId,
			);

			if (trainingSetFileData.data) {
				await addFileToTrainingSet(trainingSetFileData.data.createTrainingSetFile ||
					trainingSetFileData.data.createTrainingSetVideo, trainingSetId);
			}
		});
		await Promise.all(addPromises);
		handleClose();
		setTrainingSet(trainingSetData.data.createTrainingSet);
	};

	useEffect(() => {
		if (!isOpen) {
			setName("");
			setDescription("");
		}
	}, [isOpen]);

	return (
		<Modal
			title="Create new dataset"
			description="A dataset groups data for AI analysis,
        enhancing targeted insights and content generation based on your specific needs."
			isOpen={isOpen}
			onClose={onClose}
			className={bStyles("modal", {isDarkMode})}
		>
			<TrainingSetForm
				name={name}
				setName={setName}
				description={description}
				setDescription={setDescription}
			/>
			<Button
				className={styles.button}
				disabled={!name || isInProgress}

				onClick={handleCreateAndAddFiles}
			>
				Done
			</Button>
		</Modal>
	);
};
