import {format} from "date-fns";
import {TremendousCurrencyType} from "../../models/rewards";
import {BoundingBox, Box, VITime} from "../../models/google-video-ai";
import {difference} from "lodash-es";
import {UserRole, UserRoles} from "../../models/user";
import {Subtitles} from "../../models/answer";
import {Row} from "react-table";
import {Reel} from "../../models/reels";
export const formatDate = (date: Date): string => format(new Date(date), "MMM d, yyyy");

export const formatDateFromString = (date: string): string => format(new Date(date.replaceAll("-", "/")), "MMM d, yyyy");
export const formatLong = (date: Date): string => format(new Date(date), "MMM d, yyyy h:mm a");
export const formatTZ = (date: Date): string => format(new Date(date), "MMM d, yyyy h:mm a (OOOO)");

/**
 *
 * @param value text value to be checked
 * @param rule rule we want to follow
 */
export const checkValidTag = (value: string, rule: string): boolean => {
	try {
		let isValid = true;

	if (rule === "email") {

		const pattern = /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/;
		isValid = pattern.test(value) && isValid;
	}

		return isValid;
	} catch (error) {
		// Handle the error here
		console.error(error);
		return false;
	}
};

export const maskEmail = (value: string): string => {
	return value.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);
}

/**
 * This function is generally used to update state immutably.
 *
 * @param oldObject The object we want to update
 * @param updatedProperties The properties of said object we want to update
 *
 * @returns A copy of the oldObject but includes updatedProperties where applicable
 */
export const updateObject = <T>(oldObject: T, updatedProperties: Partial<T>): T => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

export const convertTimeFromMiliseconds = (time: number, tFormat: "seconds"|"minutes"): number => {
	if (tFormat === "seconds") {
		return time / 1000;
	}
	return time / 60000;
};

/**
 * @param ms time (in miliseconds)
 * @returns XXhr XXmin
 */
export const convertMiliToHourFormat = (ms: number): string => {
	const seconds = (ms / 1000);
	const minutes = (ms / (1000 * 60));
	const hours = (ms / (1000 * 60 * 60));
	const days = (ms / (1000 * 60 * 60 * 24));
	if (seconds < 60) return `${seconds.toFixed(1)} Sec`;
	if (minutes < 60) return `${minutes.toFixed(1)} Min`;
	if (hours < 24) return `${hours.toFixed(1)} Hrs`;
	return `${days} Days`;
};
export const convertClockToMillis = (clockTime: string | null): number => {
	if (!clockTime) return 0;
	const parts = clockTime.split(":");
	return (Number(parts[0]) * 60000) + (Number(parts[1]) * 1000);
};

/**
 * This will use convertTimeFromMiliseconds to turn the string into the format of "0:00"
 * @param time Time in milliseconds
 * @param from What are we converting from. Miliseconds, seconds,
 * undefined (defaults to milliseconds)
 * @returns Time in "0:00" format
 */
export const convertToClockTime =
	(
		time: number,
		from?: "milliseconds" | "seconds",
		type?: "written" | "clock",
	): string => {
		let totalSeconds: number;
		if (from === "milliseconds" || from === undefined) {
			totalSeconds = Math.round(convertTimeFromMiliseconds(time, "seconds"));
		} else {
			totalSeconds = time;
		}
		const minutes = Math.floor(totalSeconds / 60);
		const seconds = (totalSeconds % 60).toString().padStart(2, "0");
		if (type === "written") return `${minutes}m ${seconds}s`;
		return `${minutes}:${seconds}`;
	};

/**
 * Takes two arrays and returns an array that contains the elements that
 * exist in both
 * @param arr1 The main array (larger of the two)
 * @param arr2 The array we want to use as a comparison
 * @returns string[] array of common values in two arrays.
 */
export const filterArrays = (arr1: string[], arr2: string[]): string[] => {
	const filtered = arr1.filter(el => {
		return arr2.includes(el);
	});
	return filtered;
};


/**
 * Takes a cent value and returns it converted to whole dollars.
 * EX: 5033 cents = $50.33 (does not return the "$")
 * @param cents The cent value this should recieve
 * @returns Converted string value to whole dollars.
 */
export const convertToDollars = (cents: number | string): string => {
	if (typeof cents === "string") return (parseFloat(cents) / 100).toFixed(2);
	return (cents / 100).toFixed(2);
};

/**
 * Returns a symbol for the type of currency. Only three right now.
 */
export const currencySymbol = (type: TremendousCurrencyType): string => {
	switch (type) {
	case "EUR":
		return "€";
	case "CAD":
		return "C$";
	case "THB":
		return "฿";
	case "CNY":
		return "CN¥";
	case "GBP":
		return "£";
	default:
		return "$";
	}
};

/**
 * Function to check if user has higher level permissions
 */
export const isSupportOrEnterpriseRole = (role: UserRole): boolean => {
	return role === UserRoles.ENTERPRISE_MANAGER || role === UserRoles.SUPPORT;
}


export function getOrdinal(n: number): string {
	let ord = "th";
	if (n % 10 === 1 && n % 100 !== 11) {
		ord = "st";
	} else if (n % 10 === 2 && n % 100 !== 12) {
		ord = "nd";
	} else if (n % 10 === 3 && n % 100 !== 13) {
		ord = "rd";
	}
	return ord;
}
// Returns current date as YYYY-MM-DD format (for use with DateRange)
export const getCurrentDate = (): string => new Date().toISOString().slice(0, 10);
/**
 * Used for tracking faces (and other things eventually)
 */
export const timeOffsetToSeconds = (offset?: VITime): number => {
	if (!offset) return 0;
	let seconds = offset.seconds || 0;
	if (offset.nanos) seconds += offset.nanos / 10e8 || 0;
	return seconds;
};

// Converting raw BoundingBox we get from video AI into normalized box we can draw on canvas
export const normalizeBox = (raw: BoundingBox, height: number, width: number): Box => {
	return {
		x: (raw.left || 0) * width,
		y: (raw.top || 0) * height,
		width: ((raw.right || 0) - (raw.left || 0)) * width,
		height: ((raw.bottom || 0) - (raw.top || 0)) * height,
	};
};

export const pluralize = (count: number, word: string): string => {
	return count > 1 ? `${word}s` : word;
};

interface Difference<T> {
	added: boolean;
	value: T;
}

export function findSingleDifference<T>(incoming: T[], current: T[]): Difference<T> {
	// Means we are adding. The way we call this the difference will always be one.
	if (incoming.length > current.length) {
		return {added: true, value: difference(incoming, current)[0]};
	}
	return {added: false, value: difference(current, incoming)[0]};
}

export const orderSubtitles = (subtitles: Subtitles[]): Subtitles[] => {
	const copy = subtitles.slice();
	const sorted = copy.reduce((acc: Subtitles[], current: Subtitles) => {
		if (current.langCode === "en-us") {
			return [current, ...acc];
		}
		return [...acc, current];
	}, []);
	return sorted;
}

const sortText = {
	UNPUBLISHED: "Draft",
	FAILED: "Failed",
	PENDING: "Processing",
	CREATED: "Published",
	DIRTY: "Unpublished Changes"
}

export const reelStatusSort = (rowA: Row<Reel>, rowB: Row<Reel>, ignored: string, desc?: boolean): number => {
	const rowAText = sortText[rowA.original.videoStatus];
	const rowBText = sortText[rowB.original.videoStatus];

	if (!desc) {
		return -(rowAText.localeCompare(rowBText));
	} else return (rowBText.localeCompare(rowAText));
}
