import React, {ReactElement, useState} from "react";
import {Icon} from "../icon";
import styles from "./table.module.scss";
import classnames from "classnames/bind";

const bStyles = classnames.bind(styles);

export interface TablePaginationProps {
	currentPage: number;
	pageCount: number;
	handleNext: () => void;
	handlePrev: () => void;
	gotoPage: (page: number) => void;
	disablePagination?: boolean;
}

// Constants maybe could be props?
const MAX_PAGES = 6;
const MIN_PAGES = 0;


const TablePagination = ({currentPage, pageCount, handleNext, handlePrev, gotoPage, disablePagination}: TablePaginationProps): ReactElement | null => {
	const [min, setMin] = useState(MIN_PAGES);
	const [max, setMax] = useState(MAX_PAGES);

	/**
	 * Making sure there are enough pages on left and right
	 */
	const handlePrevious = (): void => {
		if (currentPage - 1 < min + 2 && min > 0) {
			setMax(prev => prev - 1);
			setMin(prev => prev - 1);
		}
		handlePrev();
	};
	/**
	 * Same as above but ascending
	 */
	const handleNextPage = (): void => {
		if (currentPage + 1 > max - 2) {
			setMin(prev => prev + 1);
			setMax(prev => prev + 1);
		}
		handleNext();
	};
	const handleClick = (page: number): void => {
		if (disablePagination) return;
		if (page - 3 > 0) {
			setMin(page - 3);
			setMax(page + 3);
		} else {
			setMin(MIN_PAGES);
			setMax(MAX_PAGES);
		}
		gotoPage(page);
	};

	// Array for total count of pages (0 to pageCount)
	const pageNumbers = [...Array(pageCount).keys()];

	// within a specified range, we populate pages to click
	const pages = pageNumbers.map(page => {
		if (page < max && page >= min && page !== 0) {
			return (
				<div
					className={bStyles("circle", {active: page === currentPage, disabled: disablePagination})}
					key={page}
					onClick={(): void => handleClick(page)}
				>
					<span>{page + 1}</span>
				</div>
			);
		}
		return null;
	});

	if (pages.length < 2) return null;
	return (
		<div className={styles.pagination}>

			<Icon
				name="left-arrow"
				size="extrasmall"
				fill="var(--batterii-grey-light)"
				isClickable
				clicked={handlePrevious}
				disabled={currentPage === 0 || disablePagination}
			/>

			<div className={styles.pages}>
				{/* We always want the first page showing*/}
				<div
					className={bStyles("circle", {active: currentPage === 0, disabled: disablePagination})}
					onClick={(): void => handleClick(0)}
				>
					<span>1</span>
				</div>
				{min > 1 && <div className={styles.dots}>...</div>}
				{pages}
				{pages.length > max && <div className={styles.dots}>...</div>}
			</div>
			<Icon
				name="right-arrow"
				size="extrasmall"
				fill="var(--batterii-grey-light)"
				isClickable
				clicked={handleNextPage}
				disabled={currentPage === pageCount - 1 || disablePagination}
			/>
		</div>
	);
};

export {TablePagination};
