import React, {ReactElement, ReactNode, createContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "../../../route";

export interface ImageHistoryContextValue {
  history: string[];
  setImageSrc: (src: string) => void;
  clearImage: () => void;
  imageSrc: string | null;
}

export const ImageHistoryContext =
  createContext<ImageHistoryContextValue | undefined>(undefined);

export const ImageHistoryContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [current, setCurrent] = useState<string | null>(null);
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    const image = params.get("image");

    if (image === null) {
      setHistory([]);
      setCurrent(null);
      return;
    }

    setCurrent(image);
    const history = params.get("history");
    setHistory(history ? history.split(",") : [image]);
  }, [params]);

  const setImage = (src: string): void => {
    navigate(
      {search: {image: src}},
      {search: true, replace: true}
    );
  };

  const setImageSrc = (src: string): void => {
    const srcIndex = history.indexOf(src);

    // If the image is already in the history, navigate to it
    if (!current || srcIndex !== -1) {
      setImage(src);
      return;
    }

    // Otherwise, add the image to the history and navigate to it
    const currentIndex = history.indexOf(current);
    const newHistory = history.slice(0, currentIndex + 1);
    newHistory.push(src);

    navigate(
      {search: {image: src, history: newHistory.join(",")}},
      {search: true, replace: true}
    );
  };

  const clearImage = (): void => {
    navigate(
      {search: {image: null, history: null}},
      {search: true}
    );
  };

  return (
    <ImageHistoryContext.Provider value={{
      imageSrc: current,
      history,
      setImageSrc,
      clearImage,
    }}>
      {children}
    </ImageHistoryContext.Provider>
  );
};

export const useImageHistoryContext = (): ImageHistoryContextValue => {
  const context = React.useContext(ImageHistoryContext);

  if (context === undefined) {
    throw new Error(
      "useImageHistoryContext must be used within a ImageHistoryContextProvider",
    );
  }

  return context;
};
