import {capitalize} from "lodash-es";
import classNames from "classnames/bind";
import React from "react";

import {Body, Subheader} from "../typography";
import {useThemeMode} from "../../../context/theme-mode-context";
import {WorkspacePlan} from "../../../models/workspace";
import {plans} from "../../../shared/constants/plans";

import styles from "./plan-card.module.scss";

const cx = classNames.bind(styles);

export interface PlanCardProps {
  plan?: WorkspacePlan;
}

export const PlanCard = ({plan = WorkspacePlan.PENDING}: PlanCardProps) => {
	const {isDarkMode} = useThemeMode();

	const planDetails = plans.find((p) => p.plan === plan);
	const planName = plan === WorkspacePlan.PENDING ? `${capitalize(plan)}...` : capitalize(plan);

	return (
		<div className={cx("planCard", {isDarkMode})}>
			<Subheader type="semibold" className={cx("planName", plan.toLocaleLowerCase())}>
				{planName}
			</Subheader>

			{planDetails?.description && (
				<Body className={styles.description} size="s" color="text-secondary">
					{planDetails.description}
				</Body>
			)}
		</div>
	);
}
