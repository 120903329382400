import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

type SetStateAction<T> = T | ((prevState: T) => T);
type Setter<T> = (value: SetStateAction<T>) => void;

export const useSearchParamState = <T>(key: string, initialValue: T): [T, Setter<T>] => {
	const [params, setSearchParam] = useSearchParams();
	const [value, setValue] = useState<T>(initialValue);

	useEffect(() => {
		const param = params.get(key);
		if (param) {
			setValue(JSON.parse(param));
		}
	}, [params, key]);

	const setParam: Setter<T> = (newValue) => {
		const nextValue = newValue instanceof Function ? newValue(value) : newValue;
		const isEmpty = !nextValue || (Array.isArray(nextValue) && nextValue.length === 0);
		setValue(nextValue);
		if (isEmpty) {
			params.delete(key);
		} else {
			params.set(key, JSON.stringify(nextValue));
		}
		setSearchParam(params);
	};

	return [value, setParam];
}
