import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {AiPersonaTask} from "../../../models/ai-orchestration";
import {Avatar, Body, Input, Subheader, TextArea, Switch} from "../../../shared/v2";
import {CloseMediumIcon} from "../../../icons";
import {useRunWorkflowContext} from "../../../context/workflow-contexts/run-workflow-context";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useWorkflowContext} from "../../../context/workflow-contexts/workflow-context";

import styles from "./agent-task-card.module.scss";
import {NoDraggableItem} from "../no-draggable-item";

const bStyles = classNames.bind(styles);

export interface AgentTaskCardProps {
	agentTask: AiPersonaTask;
	onRemove: (agent: AiPersonaTask) => void;
	onChangeTask: (value: string) => void;
	onChangeIndex: (agent: AiPersonaTask, newIndex: number) => void;
	disabled?: boolean;
	elementRef?: any;
	agentsLength?: number;
}

export const AgentTaskCard = ({
	agentTask,
	onRemove,
	onChangeTask,
	onChangeIndex,
	disabled,
	elementRef,
	agentsLength
}: AgentTaskCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {isWorkflowRunning} = useRunWorkflowContext();
	const {setAgentTasks} = useWorkflowContext();

	const handleChangeTask = (value: string) => {
		onChangeTask(value);
	};

	const handleRemove = () => {
		if (isWorkflowRunning) {
			return;
		}
		onRemove(agentTask);
	};

	const handleWebAccessChange = (checked: boolean) => {
		setAgentTasks(prevTasks => {
			return prevTasks.map(task => {
				if (task.id === agentTask.id) {
					return {
						...task,
						webAccess: checked,
						operation: task.operation || "UPDATE"
					};
				}
				return task;
			});
		});
	};

	const renderAgentName = (task: AiPersonaTask) => {
		if (task.persona?.name.includes("auto")) {
			return task.persona.name.replace(/-auto-.*$/, "").split(/(?=[A-Z])/).join(" ")
		};

		return task.persona?.name || "";
	}

	const cardStyles = bStyles("card", {
		processing: agentTask.processingState?.toLowerCase().includes("processing"),
		completed: agentTask.processingState?.toLowerCase().includes("completed"),
		isDarkMode,
	});



	return <NoDraggableItem className={styles.noDraggableWrapper}>
		<div className={cardStyles} ref={elementRef} id={agentTask.id}>
			<div className={styles.topSection}>
				<Avatar
					key={agentTask.persona?.id}
					size="xxl"
					firstName={agentTask.persona?.name}
					url={agentTask.persona?.picture?.url || agentTask.persona?.avatarUri}
					className={styles.avatar}
				/>
				<div className={styles.generalInfo}>
					<Subheader className={styles.name} type="semibold">
						{renderAgentName(agentTask)}

					</Subheader>
					<Body size="s" color="text-secondary">
						{agentTask.persona?.personaType?.name}
					</Body>
				</div>
				{!disabled &&
					<CloseMediumIcon onClick={handleRemove} className={bStyles("closeIcon", {isWorkflowRunning})} />
				}
			</div>

			<TextArea
				className={styles.task}
				value={agentTask.task?.taskPrompt || ""}
				onChange={handleChangeTask}
				label="Task"
				disabled={isWorkflowRunning || disabled}
			/>

			<div className={styles.bottomSection}>
				<div className={styles.webAccessControl}>
					<Body color="text-secondary" size="s" type="medium">Web Access</Body>
					<Switch
						checked={agentTask.webAccess || false}
						onChange={handleWebAccessChange}
						disabled={isWorkflowRunning || disabled}
					/>
				</div>
				<Input
					type="number"
					label="Order"
					value={((agentTask.index ?? 0) + 1).toString()}
					onChange={value => {
						const newIndex = parseInt(value, 10) - 1;
						if (!isNaN(newIndex) && newIndex >= 0) {
							onChangeIndex(agentTask, newIndex);
						}
					}}
					min={1}
					max={agentsLength}
					disabled={isWorkflowRunning || disabled}
					className={styles.indexInput}
				/>
			</div>
		</div>
	</NoDraggableItem>
};
