import {
	DeleteHighlightData,
	DeleteHighlightVars,
	HighlightCategory,
	HighlightData,
	HighlightVars,
} from "../../../models/transcript";
import React, {ReactElement, useContext, useEffect, useRef, useState} from "react";
import {gql, Reference, StoreObject, useMutation} from "@apollo/client";
import {DELETE_HIGHLIGHT, HIGHLIGHT_TRANSCRIPT} from "../../../graphql/mutations/survey-mutations";
import {Portal} from "../portal";
import {Icon} from "../icon";
import classNames from "classnames";
import styles from "./highlight-dropdown.module.scss";
import {useDropdown} from "../../../hooks";
import {AddReelDropdown} from "../../../survey/components/add-reel-dropdown";
import {CREATE_CLIPS} from "../../../graphql/mutations/clip-mutations";
import {CreateClipsReturn, CreateClipsVars} from "../../../models/clip";
import {convertClockToMillis} from "../../utility/utility";
import {ToastContext} from "../../../context/toast-context";
import {DirtyReelContext} from "../../../context/dirty-reel-context";
import {AddReelModal} from "../../../modals/add-reel";
import {AddReelVars, CreatedReel} from "../../../models/reels";
import {ADD_REEL} from "../../../graphql/mutations/reel-mutations";
import {useWorkspaceContext} from "../../../context/workspace-context";

export interface HighlightDropdownProps {
	range?: Range;
	rect?: DOMRect;
	transcriptId?: string;
	answerId?: string;
	highlightCategories: HighlightCategory[];
	closeDropdown: () => void;
}

type HighlightRef = Reference | StoreObject;
type HighlightArray = HighlightRef[];

export const HighlightDropdown = ({answerId, range, rect, transcriptId, highlightCategories, closeDropdown}: HighlightDropdownProps): ReactElement | null => {
	const ref = useRef<HTMLDivElement>(null);
	const reelDropdownRef = useRef<HTMLDivElement>(null);
	const firstRef = useRef<HTMLElement | null>(null);
	const endRef = useRef<HTMLElement | null>(null);

	let deleteable = false;
	const commonNodeName = range?.commonAncestorContainer.nodeName;
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	// Contexts
	const {updateToast} = useContext(ToastContext);
	const {setDirtyReel} = useContext(DirtyReelContext);

	const {anchorEl, handleClick, handleClose} = useDropdown();
	const [startTime, setStartTime] = useState<number>();
	const [endTime, setEndTime] = useState<number>();
	const [isShowingReelModal, setIsShowingReelModal] = useState(false);

	const [highlightTranscript] = useMutation<HighlightData, HighlightVars>(HIGHLIGHT_TRANSCRIPT);
	const [deleteHighlight] = useMutation<DeleteHighlightData, DeleteHighlightVars>(DELETE_HIGHLIGHT);
	const [createReel] = useMutation<CreatedReel, AddReelVars>(ADD_REEL);
	const [createClips] = useMutation<CreateClipsReturn, CreateClipsVars>(CREATE_CLIPS, {
		onCompleted: x => {
			handleClose();
			closeDropdown();
			updateToast({
				description: `Highlight added to ${x.createClips[0].reel?.name}.`,
				type: "informational",
			});
			setDirtyReel(x.createClips[0].reel);
		},
	});

	const handleMousedown = ({target}): void => {
		if (ref.current?.contains(target)) return;
		if (reelDropdownRef.current?.contains(target)) return;
		handleClose();
		closeDropdown();
	};

	const handleHighlight = (reelHighlight?:boolean): void => {
		if (!(range && transcriptId && firstRef.current && endRef.current)) return;
		const start = parseInt(firstRef.current.getAttribute("data-id") as string);
		const end = parseInt(endRef.current.getAttribute("data-id") as string);
		highlightTranscript({
			variables: {
				transcriptId,
				highlightCategoryId: reelHighlight ? highlightCategories[2].id : highlightCategories[3].id,
				firstWord: start,
				lastWord: start === end ? undefined : end,
			},
			update(cache, {data: highlight}) {
				if (!highlight) return;
				const highlightRef = cache.writeFragment({
					data: highlight.highlightTranscript,
					fragment: gql`
					fragment Highlight on TranscriptHighlight {
						id
						transcriptId
						firstWord
						lastWord
					}`,
				});
				if (highlightRef) {
					cache.modify({
						id: `Transcript:${highlight.highlightTranscript.transcriptId}`,
						fields: {
							highlights(existing = []) {
								return [...existing, highlightRef];
							},
						},
					});
				}
				cache.modify({
					id: `Answer:${answerId}`,
					fields: {
						highlightCount(value: number) {
							return value + 1;
						},
					},
				});
			},
		});

		if (reelHighlight) return;
		closeDropdown();
	};

	const handleDeleteHighlight = (): void => {
		if (!range) return;
		const {commonAncestorContainer, startContainer, endContainer} = range;
		let markEl: HTMLElement | undefined | null;
		if (commonAncestorContainer.nodeName === "MARK") {
			markEl = (commonAncestorContainer as HTMLElement);
		} else if (startContainer.parentElement?.parentElement?.nodeName === "MARK") {
			markEl = startContainer.parentElement?.parentElement;
		} else {
			markEl = endContainer.parentElement?.parentElement;
		}
		if (!markEl) return;
		deleteHighlight({
			variables: {id: markEl.id},
			onCompleted: () => {
				markEl?.replaceWith(...markEl.children);
				closeDropdown();
			},
			update(cache, {data: deleted}) {
				if (!deleted) return;
				cache.modify({
					id: `Transcript:${deleted.deleteHighlight.transcriptId}`,
					fields: {
						highlights(existing = [], {readField}) {
							return existing.filter(item =>
								deleted.deleteHighlight.id !== readField("id", item)
							);
						},
					},
				});
				cache.modify({
					id: `Answer:${answerId}`,
					fields: {
						highlightCount(value: number) {
							return value - 1;
						},
					},
				});
				cache.evict({fieldName: "TranscriptHighlight", args: {id: deleted.deleteHighlight.id}, broadcast: false});
			},
		});
	};

	const handleCloseEverything = (): void => {
		setIsShowingReelModal(false);
		closeDropdown();
		handleClose();
	};

	const handleCreateAndSave = (name: string, description?: string): void => {
		handleHighlight(true);
		createReel({
			variables: {input: {name, workspaceId, description}},
			onCompleted: data => {
				if (!data.createReel) return;
				setIsShowingReelModal(false);
				createClips({
					variables: {reelId: data.createReel.id, input: [{answerId, startTime, endTime}]},
				});
			},
			onError: () => updateToast({description: "An error occurred, try again later", type: "failure"}),
		});
	};

	const handleAddClips = (reelId: string): void => {
		if (!(answerId && range)) return;
		handleHighlight(true);
		createClips({
			variables: {
				reelId,
				input: [{answerId, startTime, endTime}],
			},
		});
	};

	useEffect(() => {
		if (ref.current) {
			document.addEventListener("mousedown", handleMousedown);
		}
		return () => document.removeEventListener("mousedown", handleMousedown);
	}, [ref.current, handleMousedown]);

	useEffect(() => {
		if (!(range && rect)) return;
		firstRef.current = range.startContainer.parentElement;
		endRef.current = range.endContainer.parentElement;
		if (firstRef.current && endRef.current) {
			setStartTime(convertClockToMillis(firstRef.current.getAttribute("title")));
			setEndTime(convertClockToMillis(endRef.current.getAttribute("title")));
		}
	}, [range]);

	if (range) {
		deleteable = commonNodeName === "MARK" ||
		range.startContainer?.parentElement?.parentElement?.nodeName === "MARK" ||
		range.endContainer?.parentElement?.parentElement?.nodeName === "MARK";
	}

	if (!(rect && range)) return null;
	if (isShowingReelModal) {
		return <AddReelModal
			workspaceId={workspaceId}
			isOpen={isShowingReelModal}
			handleSave={handleCreateAndSave}
			onClose={handleCloseEverything}
		/>;
	}
	return (
		<Portal id={`highlight-dropdown-${transcriptId}`}>
			<div
				className={styles.dropdown}
				ref={ref}
				style={{
					top: rect && rect.top - 16,
					left: (rect && (rect.left + rect.right) / 2),
				}}
			>
				<div
					className={classNames(styles.action, commonNodeName === "MARK" && styles.inactive)}
					onClick={commonNodeName === "MARK" ? undefined : () => handleHighlight()}
				>
					<Icon
						name="highlight"
						fill="white"
						className={styles.highlightIcon}
						size="extrasmall"
					/>
					<span>Highlight</span>
				</div>
				<div
					className={classNames(styles.action, !deleteable && styles.inactive)}
					onClick={deleteable ? handleDeleteHighlight : undefined}
				>
					<Icon name="crossed-pen" fill="white" size="extrasmall"/>
					<span>Remove highlight</span>
				</div>
				<div
					className={styles.action}
					onClick={handleClick}
				>
					<Icon name="play-plus" fill="white"/>
					<span>Highlight and add to Reel</span>
				</div>
				<AddReelDropdown
					anchorEl={anchorEl}
					workspaceId={workspaceId}
					addClip={handleAddClips}
					closeCallback={handleClose}
					ref={reelDropdownRef}
					showModal={setIsShowingReelModal}
				/>
			</div>
		</Portal>
	);
};
