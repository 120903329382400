import React, {ReactElement, useCallback, useMemo, useState} from "react";
import classNames from "classnames/bind";
import {useDropzone} from "react-dropzone";

import {Button, Body, Tooltip, Modal, BaseModalProps} from "../../../../shared/v2/";
import {useToastContext} from "../../../../context/toast-context";
import {useTrainingSetUpload} from "../../../../hooks/useTrainingSetUpload";
import {FileItem} from "./file-item";
import {FileWithUploadResult} from "../../../../models/ai-model";
import {UploadCircles} from "./upload-circles";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {InformationCicrcleIcon} from "../../../../icons";
import {ConfirmActionModal} from "../confirm-action-modal";

import styles from "./upload-files-modal.module.scss";

const bStyles = classNames.bind(styles);

const MAX_DOCUMENT_SIZE = 50 * 1024 * 1024;

const isFileToLarge = (file: File): boolean => {
	return !file.type.includes("video") && file.size > MAX_DOCUMENT_SIZE;
};

export interface UploadFilesModalProps extends BaseModalProps {
	onFinish: () => void;
	files: FileWithUploadResult[];
	setFiles: (files) => void;
	clearOnFinish?: boolean;
}

export const UploadFilesModal = ({
	isOpen,
	onClose,
	onFinish,
	setFiles,
	files,
	clearOnFinish,
}: UploadFilesModalProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {uploadFile} = useTrainingSetUpload();
	const {updateToast} = useToastContext();

	const [isCancelUploadModalOpen, setIsCancelUploadModalOpen] = useState(false);

	const handleClearFiles = (): void => {
		setIsCancelUploadModalOpen(false);
		onClose();
		setFiles([]);
	};

	const handleClose = (event):void => {
		event.preventDefault();
		event.stopPropagation();
		if (!files.length) {
			onClose();
			return;
		}
		setIsCancelUploadModalOpen(true);
	};

	const deleteFile = (file: FileWithUploadResult): void => {
		setFiles(result => result.filter(f => f !== file));
	};

	const onDrop = useCallback(acceptedFiles => {
		if (acceptedFiles.length > 10) {
			updateToast({
				description: "You can only upload 10 files at a time",
				type: "failure",
			});
			return;
		}

		const isTooLarge = acceptedFiles.some(isFileToLarge);

		if (isTooLarge) {
			updateToast({
				description: "The file you are uploading is too large. Please select a file that is smaller than 50 MB.",
				type: "failure",
			});
			acceptedFiles = acceptedFiles.filter(file => !isFileToLarge(file));
		}

		setFiles(result => [...result, ...acceptedFiles]);

		acceptedFiles.forEach(file => {
			uploadFile({
				variables: {
					file,
				},
				onCompleted: data => {
					file = Object.assign(file, {isLoading: false, uploadResult: data.upload});
					setFiles(result => [...result]);
				},
			});
		});
	}, []);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDrop,
		accept: {
			"video/*": [],
			"text/plain": [],
			"text/csv": [],
			"text/markdown": [],
			"application/json": [],
			"application/pdf": [],
			"application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
			"text/*": [],
		},
		onDropRejected: () => {
			updateToast({
				description: "Unsupported file type",
				type: "failure",
			});
		},
	});

	const handleNext = (): void => {
		onFinish();
		if (clearOnFinish) {
			handleClearFiles();
		}
	};

	const stopPropagation = (event): void => {
		event.stopPropagation();
	};

	const areFilesLoading = useMemo(() => {
		return files.some(file => file.uploadResult === undefined);
	}, [files]);


	const modalDescription: ReactElement = <div className={styles.descriptionWrapper}>
		<Body
			color="text-secondary"
		>
      These files can be referenced and utilized for generating new content and insights.
		</Body>

		<Body color="text-secondary" type="semibold">
      Supported file types include:
		</Body>

		<ul className={styles.supportedList}>
			<li>
				<Body color="text-secondary" className={styles.supportedItem}>
          Videos
					<Tooltip
						content={<Body size="xs">
              All video formats (e.g., mp4, avi, mov)
						</Body>}
					>
						<div>
							<InformationCicrcleIcon className={styles.infoIcon} />
						</div>
					</Tooltip>

				</Body>
			</li>
			<li>
				<Body color="text-secondary" className={styles.supportedItem}>
          Documents
					<Tooltip
						content={<Body size="xs">pdf, txt, csv, json // (max file size: 50MB)</Body>}
					>
						<div>
							<InformationCicrcleIcon className={styles.infoIcon} />
						</div>
					</Tooltip>
				</Body>
			</li>
			<li>
				<Body color="text-secondary" className={styles.supportedItem}>
          MS Office
					<Tooltip
						content={<Body size="xs">Word (.docx), PowerPoint (.pptx), Excel (.xlsx)  // (max file size: 50MB)</Body>}
					>
						<div>
							<InformationCicrcleIcon className={styles.infoIcon} />
						</div>
					</Tooltip>
				</Body>
			</li>
		</ul>
	</div>;

	return <>
		<Modal
			className={styles.uploadModal}
			isOpen={isOpen}
			onClose={handleClose}
			onClick={stopPropagation}
			title="Upload files"
			description={modalDescription}
			portal
		>
			<>
				{files.length > 0 && <div className={styles.files}>
					{files.map((file, index) =>
						<FileItem key={index} file={file} onDelete={deleteFile} />)}
				</div>}

				<div
					className={bStyles("dragAndDrop", {isDarkMode, isDragActive})}
					id="drag-n-drop"
					{...getRootProps()}
				>
					<input {...getInputProps()} />
					<UploadCircles />

					<div className={styles.text}>
						<Body color="text-secondary">
							<span className={bStyles("important", {isDarkMode})}>
                Click to upload
							</span> or drag and drop.
						</Body>

						<Body color="text-secondary">
              You can upload up to <span className={bStyles("important", {isDarkMode})}>
                10 files
							</span>.
						</Body>
					</div>
				</div>

				{files.length > 0 && <div className={styles.footer}>
					<Button
						className={styles.button}
						onClick={handleClose}
						variant="outlined"

					>
            Cancel
					</Button>

					<Button
						className={styles.button}
						disabled={areFilesLoading}
						onClick={handleNext}
					>
						{areFilesLoading ? "Uploading..." : "Done"}
					</Button>
				</div>}
			</>
		</Modal>

		<ConfirmActionModal
			isOpen={isCancelUploadModalOpen}
			onClose={() => setIsCancelUploadModalOpen(false)}
			onConfirm={handleClearFiles}
			title="Upload Cancellation"
			description="Are you sure you want to close the uploader? This will cancel the upload of all files."
			confirmText="Cancel Upload"
			cancelText="Keep Uploading"
			onClick={stopPropagation}
		/>
	</>;
};
