/* eslint-disable react/prop-types */
import {NewAvatar as Avatar, Options} from "../../../shared";
import {AttributeValue, AttributeValuesPageData} from "../../../models/attribute";
import React, {ReactElement, useContext, useMemo, useState} from "react";
import {Row} from "react-table";
import {ADD_WORKSPACE_SEGMENT_MEMBERS} from "../../../graphql/mutations/mutations";
import {DELETE_ATTRIBUTE_VALUE} from "../../../graphql/mutations/attribute-mutations";
import {GET_ATTRIBUTE_VALUES} from "../../../graphql/queries/attribute-queries";
import {GET_SEGMENTS} from "../../../graphql/queries/queries";
import {ActionsButton} from "../../components/actions-button";
import {AlignText} from "../../../shared/typography/align-text";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {SegmentData} from "../../../models/segment";
import {Table} from "../../../shared/components/table";
import {ToastContext} from "../../../context/toast-context";
import {useParams} from "../../../route";
import styles from "./attribute-value.module.scss";
import {useLocation} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import {useLoadingQuery} from "../../../hooks";
import {updateCacheDeletePageItem} from "../../../shared/utility/update-cache";
import {Body, Subheader} from "../../../shared/v2/typography";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {SearchInput} from "../../../shared/v2/inputs";

const AttributeValuePage = (): ReactElement => {
	const {attrId} = useParams<{attrId: string}>();
	const {state} = useLocation();
	const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();

	const {updateToast} = useContext(ToastContext);
	// States
	const [search, setSearch] = useState("");
	const [selectedAttr, setSelectedAttr] = useState("");
	const [selectedCreators, setSelectedCreators] = useState<Row<AttributeValue>[]>([]);

	const {data, fragment} = useLoadingQuery<AttributeValuesPageData>(GET_ATTRIBUTE_VALUES, {
		variables: {id: attrId},
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
	});

	const {data: listData} = useQuery<SegmentData>(GET_SEGMENTS, {
		variables: {workspaceId},
	});

	const [deleteValue] = useMutation(DELETE_ATTRIBUTE_VALUE);
	const [sendToSegment] = useMutation(ADD_WORKSPACE_SEGMENT_MEMBERS);

	const handleSend = (id: string): void => {
		sendToSegment({
			variables: {
				segmentId: id,
				userIds: selectedCreators.map((item) => item.original.user.id),
				workspaceId,
			},
			onError: (err) => {
				updateToast({description: err.message, type: "failure"});
			},
			onCompleted: (sent) => {
				const addedUsersLength = sent.addSegmentMembers.addedUsers.length;
				const existingUsersLength = sent.addSegmentMembers.existingUsers.length;
				updateToast({
					description: (
						<div>
							{addedUsersLength > 0 && <div>{addedUsersLength} added to list</div>}
							{existingUsersLength > 0 && <div>{existingUsersLength} already in list</div>}
						</div>
					),
					type: "informational",
				});
				setSelectedCreators([]);
			},
		});
	};
	const handleDeleteValue = (): void => {
		deleteValue({
			variables: {id: selectedAttr},
			onCompleted: () => {
				setSelectedAttr("");
				updateToast({description: "Deleted value on creator", type: "informational"});
			},
			update(cache, {data: deleteData}) {
				if (!deleteData) return;
				updateCacheDeletePageItem(cache, "attributeValues", "attributeValue", deleteData.deleteAttributeValue.id);
			},
		});
	};

	const columns = useMemo(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(): any => [
			{
				id: "name",
				Header: "Name",
				accessor: "user.firstName",
				Cell: ({row: {original}}) => (
					<div className={styles.user}>
						<Avatar user={original.user} showName showTag />
					</div>
				),
			},
			{
				Header: "Value",
				accessor: "value",
				Cell: ({value}) => <Body size="s">{value || ""}</Body>,
			},
			{
				Header: " ",
				accessor: " ",
				maxWidth: 50,
				disableSortBy: true,
				Cell: ({row: {original}}) => (
					<AlignText align="right">
						<Options
							type="menu-vertical"
							position="right"
							options={[
								{
									name: "Delete",
									actionOptions: {onClick: () => setSelectedAttr(original.id)},
									icon: "trash",
									iconFill: "var(--color-text-body)",
								},
							]}
						/>
					</AlignText>
				),
			},
		],
		[data],
	);

	const headerText = (state as string) || data?.attributeValues.items[0]?.attribute.name || "Attribute Values";

	return (
		<>
			<div>
				<Subheader className={styles.subheader} size="l" type="medium">
					{headerText}
				</Subheader>
				<header className={styles.header}>
					<SearchInput id="search-value" value={search} onChange={setSearch} />
					<div className={styles.actions}>
						{selectedCreators.length > 0 && listData && (
							<ActionsButton
								text={`Bulk actions (${selectedCreators.length})`}
								options={[
									{
										name: "Add to list",
										actionOptions: {
											options: listData.segments.items.map((list) => ({
												children: list.name,
												options: {onClick: () => handleSend(list.id)},
											})),
										},
									},
								]}
							/>
						)}
					</div>
				</header>
				{fragment ||
					(data && (
						<>
							<Table
								columns={columns}
								data={data?.attributeValues?.items}
								selectedValues={selectedCreators}
								onSelectChange={setSelectedCreators}
								searchValue={search}
								filters={["name", "value"]}
							/>
						</>
					))}
				<DeleteConfirmModal
					isOpen={Boolean(selectedAttr)}
					onClose={() => setSelectedAttr("")}
					handleConfirm={handleDeleteValue}
					warningText="Are you sure you want to delete this value?"
				/>
			</div>
		</>
	);
};

export {AttributeValuePage};
