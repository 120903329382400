import {Dispatch, SetStateAction, useMemo, useState} from "react";
import {useElementSize} from "./useElementSize";

export interface DragConstraints {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}

export interface UseDragConstraintsResult<D extends HTMLElement, C extends HTMLElement> {
  constraints: DragConstraints;
  draggableEl: D;
  setDraggableEl: Dispatch<SetStateAction<D | null>>;
  constraintEl: C;
  setConstraintEl: Dispatch<SetStateAction<C | null>>;
}

export const useDragConstraints = <D extends HTMLElement, C extends HTMLElement>(props?: {
  draggableEl?: D | null
  constraintEl?: C | null;
}): UseDragConstraintsResult<D, C> => {
  const [draggableEl, setDraggableEl] = useState<D | null>(null);
  const [constraintEl, setConstraintEl] = useState<C | null>(null);

  const {width: draggableWidth, height: draggableHeight} = useElementSize((draggableEl || props?.draggableEl) || null);
  const {width: constraintWidth, height: constraintHeight} = useElementSize((constraintEl || props?.constraintEl) || null);


  const constraints = useMemo(() => {
    const left = draggableWidth > constraintWidth ? -(Math.round(draggableWidth / 2) - Math.round(constraintWidth / 2)) : 0
    const right = draggableWidth > constraintWidth ? Math.round(draggableWidth / 2) - Math.round(constraintWidth / 2) : 0
    return {
      left: left,
      right: right,
      // 600px is taken from the carouse padding bottom
      top: -draggableHeight + 600,
      bottom: 0,
    }
  }, [draggableWidth, constraintWidth, draggableHeight, constraintHeight]);

  return {
    constraints,
    draggableEl: draggableEl as D,
    setDraggableEl,
    constraintEl: constraintEl as C,
    setConstraintEl,
  }
}

