import {useSubscription} from "@apollo/client";
import React, {
	ReactElement,
	ReactNode,
	createContext,
	useEffect,
	useMemo,
	useState
} from "react";

import {CHAT_CONVERSATION_UPDATES} from "../../graphql/subscriptions";
import {useSearchParams} from "../../route";
import {useToastContext} from "../toast-context";
import {ConversationEventTypes, ConversationResponseCreated, ConversationResponseStateChanged, ConversationUserMessageCreated, VurveyConversationEvent} from "../../models/subscriptions";

export interface ChatConversationUpdatesResponse {
	chatConversationUpdates: VurveyConversationEvent;
}

interface Events {
  [ConversationEventTypes.RESPONSE_CREATED]: ConversationResponseCreated[];
  [ConversationEventTypes.USER_MESSAGE_CREATED]: ConversationUserMessageCreated[];
  [ConversationEventTypes.RESPONSE_STATE_CHANGED]: ConversationResponseStateChanged[];
}

export interface ChatEventsContextValue {
  isBroken: boolean;
  events: Events;
  removeEvent: (event: VurveyConversationEvent) => void;
}

export const ChatEventsContext =
  createContext<ChatEventsContextValue | undefined>(undefined);

export const ChatEventsContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const {updateToast} = useToastContext();
	const {canvasId} = useSearchParams();
	const [events, setEvents] = useState<Events>({
		[ConversationEventTypes.RESPONSE_CREATED]: [],
		[ConversationEventTypes.USER_MESSAGE_CREATED]: [],
		[ConversationEventTypes.RESPONSE_STATE_CHANGED]: [],
	});
	const {data: {chatConversationUpdates} = {}, error} = useSubscription<ChatConversationUpdatesResponse>(CHAT_CONVERSATION_UPDATES, {
		variables: {conversationId: canvasId},
		skip: !canvasId,
	});

	const isBroken = useMemo(() => {
		const result = Boolean(error);

    if (result) {
      console.error("Web Socket connection error. Switching to polling.");
    //  updateToast({
    //    type: "failure",
    //    description: "Reeltime connection error. You may experience delays in chat updates.",
    //  });
    }

		return result;
	}, [error]);

	useEffect(() => {
		setEvents((prevEvents) => {
			if (!chatConversationUpdates) {
				return prevEvents;
			}

			const {type} = chatConversationUpdates;

			if (type in prevEvents) {
				return {
					...prevEvents,
					[type]: [...prevEvents[type], chatConversationUpdates],
				};
			}

			return prevEvents;
		});
	}, [chatConversationUpdates]);

	const removeEvent = (event: VurveyConversationEvent) => {
		setEvents((prevEvents) => {
			const {type} = event;

			return {
				...prevEvents,
				[type]: prevEvents[type].filter((e) => e !== event),
			};
		});
	}

	return (
		<ChatEventsContext.Provider value={{isBroken, events, removeEvent}}>
			{children}
		</ChatEventsContext.Provider>
	);
};

export const useChatEventsContext = (): ChatEventsContextValue => {
	const context = React.useContext(ChatEventsContext);

	if (context === undefined) {
		throw new Error(
			"useChatEventsContext must be used within a ChatEventsContextProvider",
		);
	}

	return context;
};
