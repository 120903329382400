import React, {ReactElement, useContext} from "react";
import {useMutation} from "@apollo/client";

import {LIKE_ANSWER, UNLIKE_ANSWER} from "../../../graphql/mutations/survey-mutations";
import {Answer} from "../../../models/answer";
import {HeartIcon} from "../../../shared";
import {Transcript} from "../../../modal-components/transcript";
import {convertToClockTime} from "../../../shared/utility/utility";

import styles from "./video-row.module.scss";
import {TranscriptModalContext} from "../../../context/transcript-modal-context";

export interface VideoRowProps {
	/**
	 * The answer we are displaying
	 */
	answer: Answer;
	search?: string;
	showTranslation?: boolean;
}

const VideoRow = (props: VideoRowProps): ReactElement => {
	const {answer, search, showTranslation = false} = props;
	const {setAnswerId} = useContext(TranscriptModalContext);

	/**
	 * Set up the like / unlike mutations
	 */
	const [likeAnswer] = useMutation(LIKE_ANSWER);
	const [unlikeAnswer] = useMutation(UNLIKE_ANSWER);

	const handleLike = (): void => {
		likeAnswer({variables: {answerId: answer.id}});
	};

	const handleUnlike = (): void => {
		unlikeAnswer({variables: {answerId: answer.id}});
	};

	return (
		<div
			className={styles.container}
		>
			<HeartIcon
				isLiked={Boolean(answer.likedAt)}
				like={handleLike}
				unlike={handleUnlike}
			/>
			<div
				className={styles.imageContainer}
				onClick={() => setAnswerId(answer.id)}
			>
				<img className={styles.image} alt="video-still" src={answer.video.thumbnail} loading="lazy"/>
				<div className={styles.play} />
			</div>
			<div className={styles.info}>
				<p className={styles.tag}>
					{answer.user.creatorTag}
				</p>
				<span className={styles.name}>
					{answer.user.firstName && `${answer.user.firstName} ${answer.user.lastInitial}.`}
				</span>
				<p className={styles.time}>
					{convertToClockTime(answer.video.duration, "milliseconds")}
				</p>
			</div>
			<div className={styles.transcript}>
				<Transcript
					answerId={answer.id}
					search={search}
					transcript={answer.video?.transcript}
					showTranslation={showTranslation}
				/>
			</div>
		</div>
	);
};

export {VideoRow};

