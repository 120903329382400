import {ContactsPageData, ContactsPageVars, ContactsSort} from "../models/user";
import {WorkspaceMembersFilter} from "../models/filter";
import {GET_CONTACTS} from "../graphql/queries/queries";
import {useSearchParams} from "./useSearchParams";
import {useNavigate} from "./useNavigate";
import {LoadingQueryResult, useLoadingQuery} from "../hooks/useLoadingQuery";
import {useWorkspaceContext} from "../context/workspace-context";
import styles from "../shared/components/table/table.module.scss";

// Poll every 5 seconds for new contacts
const POLL_INTERVAL = 30000;

export const useContactsFilter = (segmentId?: string, limit?: number, name?: string, dynamicSegmentId?: string, sort?: ContactsSort): {
	membersFilter: WorkspaceMembersFilter;
	workspaceMembers: LoadingQueryResult<ContactsPageData, ContactsPageVars>;
	workspaceId: string;
	updateFilter: (newFilter: WorkspaceMembersFilter) => void;
} => {
	const parsedSearch = useSearchParams();
	const navigate = useNavigate();
	const {workspace: {id: workspaceId}} = useWorkspaceContext();

	const [min, max] = parsedSearch.ageRange?.toString()?.split("-", 2)?.map(v => {
		const p = parseInt(v, 10);
		return isNaN(p) ? undefined : p;
	}) ?? [];
	const ageRange = min || max ? {min, max} : undefined;
	const date = new Date();
	date.setDate(date.getDate() - 1);

	const customPropsFilter = parsedSearch?.customProperties ?
		JSON.parse(parsedSearch.customProperties)
		: undefined;

	/**
	 * All the survey date filters
	 */
	const firstSurveyFilter = parsedSearch?.firstSurveyResponseAt
		? JSON.parse(parsedSearch.firstSurveyResponseAt) : undefined;

	const lastSurveyFilter = parsedSearch?.lastSurveyResponseAt
		? JSON.parse(parsedSearch.lastSurveyResponseAt) : undefined;

	const firstFeedbackFilter = parsedSearch?.firstFeedbackSurveyResponseAt
		? JSON.parse(parsedSearch.firstFeedbackSurveyResponseAt) : undefined;

	const lastFeedbackFilter = parsedSearch?.lastFeedbackSurveyResponseAt
		? JSON.parse(parsedSearch.lastFeedbackSurveyResponseAt) : undefined;

	const membersFilter: WorkspaceMembersFilter = {
		gender: parsedSearch.gender ?? undefined,
		ageRange,
		countryId: parsedSearch.countryId,
		stateId: parsedSearch.stateId,
		firstSurveyResponseAt: firstSurveyFilter,
		lastSurveyResponseAt: lastSurveyFilter,
		firstFeedbackSurveyResponseAt: firstFeedbackFilter,
		lastFeedbackSurveyResponseAt: lastFeedbackFilter,
		ethnicityId: parsedSearch.ethnicityId,
		customProperties: customPropsFilter,
		segmentId,
		dynamicSegmentId,
		hasTakenSurvey: parsedSearch.surveyIds ? {
			surveyIds: parsedSearch.surveyIds,
			any: parsedSearch.any ?? undefined,
			completed: parsedSearch.completed ?? undefined,
			haveNot: parsedSearch.haveNot ?? undefined,
		} : undefined,
		name: name || undefined,
	};

	const workspaceMembers = useLoadingQuery<ContactsPageData, ContactsPageVars>(GET_CONTACTS, {
		variables: {workspaceId, filter: membersFilter, limit: limit || 50, sort},
		errorPolicy: "all",
		what: "Creators",
		fetchMoreClassName: styles.fetchMore,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-first", // Use cache first for immediate rendering
		pollInterval: POLL_INTERVAL, // Poll every 5 seconds for fresh data
	});

	const updateFilter = (newFilter: WorkspaceMembersFilter): void => {
		const {ageRange: ar} = newFilter;
		navigate({search: {
			...newFilter,
			ageRange: ar ? `${ar.min ?? ""}-${ar.max ?? ""}` : undefined,
			customProperties: (newFilter?.customProperties?.length || 0) > 0 ?
				JSON.stringify(newFilter.customProperties)
				: undefined,
			firstSurveyResponseAt: JSON.stringify(newFilter?.firstSurveyResponseAt),
			lastSurveyResponseAt: JSON.stringify(newFilter?.lastSurveyResponseAt),
			firstFeedbackSurveyResponseAt: JSON.stringify(newFilter?.firstFeedbackSurveyResponseAt),
			lastFeedbackSurveyResponseAt: JSON.stringify(newFilter?.lastFeedbackSurveyResponseAt),
			surveyIds: newFilter?.hasTakenSurvey?.surveyIds,
			any: newFilter?.hasTakenSurvey?.any,
			completed: newFilter?.hasTakenSurvey?.completed,
			haveNot: newFilter?.hasTakenSurvey?.haveNot,
			hasTakenSurvey: "",
		}}, {workspace: true});
	};

	return {membersFilter, workspaceMembers, workspaceId, updateFilter};
};
