import React, {ReactElement, useEffect, useState} from "react";
import classNames from "classnames/bind";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import {AiPersonaTask, ProcessingStep} from "../../../models/ai-orchestration";
import {ArrowSplitIcon, ChevronDownIcon} from "../../../icons";
import {Body} from "../../../shared/v2";
import {ChatScrollContextProvider} from "../../../context/chat-contexts/chat-scroll-context";
import {NoDraggableItem} from "../no-draggable-item";
import {OutputSteps} from "../output-steps";
import {useThemeMode} from "../../../context/theme-mode-context";
import LinkRenderer from "../../../canvas/chat/response-bubble/content/link-reader";

import styles from "./output-card.module.scss";

const bStyles = classNames.bind(styles);

export interface OutputCardProps {
	agentTask: AiPersonaTask;
}

export const OutputCard = ({agentTask}: OutputCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		// Set expanded when there's output OR processing steps
		if (agentTask?.output?.length || (agentTask?.processingSteps && agentTask.processingSteps.length > 0)) {
			setIsExpanded(true);
		}
	}, [agentTask?.output, agentTask?.processingSteps]);

	const cardStyles = bStyles("outputWrapper", {
		completed: agentTask?.processingState === "completed",
		processing: agentTask?.processingState === "processing",
		error: agentTask?.processingState === "error",
		isDarkMode,
	});

	const hasProcessingSteps = agentTask?.processingSteps && agentTask.processingSteps.length > 0;
	const steps: ProcessingStep[] = hasProcessingSteps && agentTask.processingSteps ? agentTask.processingSteps : [];

	return (
		<NoDraggableItem className={styles.noDraggableWrapper}>
			<div className={cardStyles}>
				<div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
					<div className={styles.leftSide}>
						<ArrowSplitIcon className={styles.arrowIcon} />
						<Body type="medium">Output</Body>
					</div>

					<ChevronDownIcon className={styles.chevronIcon} />
				</div>

				{isExpanded && (
					<div className={styles.content}>
						{hasProcessingSteps && <OutputSteps steps={steps} />}

						{agentTask?.output && (
							<div className={styles.outputSection}>
								<Body type="medium" className={styles.sectionTitle}>Task Output:</Body>
								<Body className={styles.message}>
									<ChatScrollContextProvider>
										<ReactMarkdown
											remarkPlugins={[remarkGfm]}
											components={{
												a: LinkRenderer,
											}}
										>
											{agentTask.output}
										</ReactMarkdown>
									</ChatScrollContextProvider>
								</Body>
							</div>
						)}
					</div>
				)}
			</div>
		</NoDraggableItem>
	);
};
