import {useQuery} from "@apollo/client";
import {useSearchParams} from "react-router-dom";
import React, {ReactElement, useEffect, useMemo, useState} from "react";

import {CampaignCard} from "./campaign-card";
import {CAMPAIGNS_SEARCH_STORAGE_KEY} from "../../../shared/constants/constants";
import {CreateCampaignCard} from "./create-campaign-card";
import {GET_ALL_SURVEYS} from "../../../graphql/queries/survey-queries";
import {SearchInput, LoadingContainer} from "../../../shared/v2";
import {SurveyPageData} from "../../../models/survey";
import {useMount} from "../../../hooks/useMount";
import {useWorkspaceContext} from "../../../context/workspace-context";

import styles from "./survey-dashboard.module.scss";

// Poll every 30 seconds for new campaigns
const POLL_INTERVAL = 30000;

const SurveyDashboard = (): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const [searchValue, setSearchValue] = useState<string>("");
	const [searchParams, setSearchParams] = useSearchParams();
	const [isMounted, setIsMounted] = useState(false);

	const searchParamsValue = searchParams.get("search");

	useMount(() => {
		setIsMounted(true);
		setSearchValue(searchParamsValue || "")
	});

	useEffect(() => {
		if (!isMounted) return;
		setSearchParams(searchValue.length ? {search: searchValue} : {});
		localStorage.setItem(CAMPAIGNS_SEARCH_STORAGE_KEY, searchValue);
	}, [searchValue])

	useEffect(() => {
		if (searchParamsValue === null) {
			setSearchValue("");
		}
	}, [searchParamsValue])

	const {
		data: {
			workspaceSurveys: {
				items: campaigns = []
			} = {}
		} = {},
		networkStatus,
	} = useQuery<SurveyPageData>(GET_ALL_SURVEYS, {
		skip: !workspaceId,
		errorPolicy: "all",
		variables: {workspaceId},
		fetchPolicy: "cache-first",
		pollInterval: POLL_INTERVAL,
		notifyOnNetworkStatusChange: true,
	});

	const filteredCampaigns = useMemo(() => {
		if (!searchValue) return campaigns;
		return campaigns.filter(campaign => campaign.name.toLowerCase().includes(searchValue.toLowerCase()));
	}, [campaigns, searchValue]);

	const renderContent = () => {
		if (networkStatus !== 7 && !campaigns.length) {
			return <LoadingContainer />;
		}

		return <div className={styles.list}>
			<CreateCampaignCard />
			{filteredCampaigns.map((campaign) => (
				<CampaignCard key={campaign.id} campaign={campaign} />
			))}
		</div>
	}

	return (
		<div className={styles.fullWidth}>
			<SearchInput
				value={searchValue}
				onChange={setSearchValue}
				className={styles.searchInput}
			/>
			{renderContent()}
		</div>
	);
};

export {SurveyDashboard};
