import classNames from "classnames/bind";
import React, {ReactElement, useMemo} from "react";

import {AiOrchestrationHistory} from "../../../models/ai-orchestration";
import {Body, Spinner} from "../../../shared/v2";
import {Persona} from "../../../models";
import {PersonasList} from "../personas-list";
import {useWorkflowContext} from "../../../context/workflow-contexts";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./history-drawer.module.scss";

const bStyles = classNames.bind(styles);

export const HistoryDrawer = (): ReactElement => {
  const {isDarkMode} = useThemeMode();
  const  {aiHistory, setCurrentHistory, currentHistory, isHistoryLoading} = useWorkflowContext();

  const formatDate = (entry: AiOrchestrationHistory): string => {
    const date = new Date(entry.completedAt);
    return date.toLocaleString().split(",")[0];
  }

  const renderContent = () => {
    if (isHistoryLoading) {
      return (
        <div className={styles.loading}>
          <Spinner className={styles.spinner}/>
        </div>
      );
    }
    return aiHistory.map((history, index) => (
      <div
        className={bStyles("entry", {active: currentHistory?.id === history.id})}
        key={history.id}
        onClick={() => setCurrentHistory(history)}
      >
        <div className={styles.textWrapper}>
          <Body
            size="xs"
            type="medium"
            color="text-tertiary"
            className={styles.date}
          >
            {formatDate(history)}
          </Body>
          <Body
            size="base"
            type="medium"
          >
            Run {aiHistory.length - index}
          </Body>
        </div>

        <PersonasList
          className={styles.personasList}
          size="sm"
          personas={
            history
              .outputHistoryTasks.map(task => task.persona)
              .filter(persona => Boolean(persona)) as Persona[]
          }
          max={4}
        />
      </div>
    ))
  }

  return (
    <div
      className={bStyles("historyDrawer", {isDarkMode})}
    >
      <Body
        className={styles.header}
        size="s"
        type="medium"
      >
        Workflow history
      </Body>

      <div className={styles.entriesList}>
        {renderContent()}
      </div>

    </div>
  );
}
