import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {useNavigate} from "react-router";

import {GET_SURVEY_TEMPLATE_CATEGORY_GROUPS_WITH_TEMPLATES} from "../../../graphql/queries/survey-template-queries";
import {
	CreateSurveyFromTemplateReturn,
	CreateSurveyFromTemplateVars,
	SurveyTemplateCategory,
	SurveyTemplateCategoryGroupsReturn,
} from "models/survey";
import {CREATE_SURVEY_FROM_TEMPLATE} from "../../../graphql/mutations/survey-template-mutations";
import {useToastContext} from "../../../context/toast-context";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {SurveyTemplateGroup} from "../../../survey/components/survey-template-group";
import {SurveyTemplateCard} from "../../../survey/components/survey-template-card";
import {Body, LoadingContainer} from "../../../shared/v2";

import styles from "./template-page.module.scss";

export const TemplatePage = (): ReactElement | null => {
	const navigate = useNavigate();
	const [activeCategory, setActiveCategory] = useState<SurveyTemplateCategory>();
	const {updateToast} = useToastContext();
	const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();

	const {
		data: surveyTemplateCategoryGroupsData,
		loading: surveyTemplateCategoryGroupsLoading,
	} = useQuery<SurveyTemplateCategoryGroupsReturn>(
		GET_SURVEY_TEMPLATE_CATEGORY_GROUPS_WITH_TEMPLATES
	);

	const [
		createSurveyFromTemplate,
	] = useMutation<CreateSurveyFromTemplateReturn, CreateSurveyFromTemplateVars>(
		CREATE_SURVEY_FROM_TEMPLATE,
		{
			onCompleted: (data) => {
				navigate(`/survey/${data.createSurveyFromTemplate.id}/questions`);
				updateToast({
					type: "informational",
					description: "Successfully created a campaign from the template.",
				});
			},
		}
	);

	const handleCreateSurveyFromTemplate = (templateId: string): void => {
		createSurveyFromTemplate({
			variables: {
				id: templateId,
				workspaceId,
			},
		});
	};

	useEffect(() => {
		if (surveyTemplateCategoryGroupsLoading) return;
		setActiveCategory(
			surveyTemplateCategoryGroupsData?.surveyTemplateCategoryGroups?.items
				.find((a) => a.name === "Featured")
				?.categories.find((cat) => cat.name === "Popular")
		);
	}, [surveyTemplateCategoryGroupsData, surveyTemplateCategoryGroupsLoading]);

	const sortedGroups = useMemo(() => {
		const order = ["Featured", "Use Case", "Function"];

		return order
			.map((groupName) =>
				surveyTemplateCategoryGroupsData?.surveyTemplateCategoryGroups?.items.find(
					({name}) => name === groupName
				)
			)
			.filter((group): group is NonNullable<typeof group> => group !== undefined);
	}, [surveyTemplateCategoryGroupsData?.surveyTemplateCategoryGroups?.items]);

	const handleChangeCategory = (category: SurveyTemplateCategory): void => {
		setActiveCategory(category);
	};

	if (surveyTemplateCategoryGroupsLoading) {
		return <LoadingContainer />;
	}

	if (!surveyTemplateCategoryGroupsData?.surveyTemplateCategoryGroups?.items) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div className={styles.groups}>
				{sortedGroups.map((group) => (
					<SurveyTemplateGroup
						group={group}
						activeCategory={activeCategory}
						changeCategory={handleChangeCategory}
						key={group.id}
					/>
				))}
			</div>
			<div className={styles.templates}>
				{activeCategory?.templates?.length ? (
					activeCategory.templates.map((template) => (
						<SurveyTemplateCard
							template={template}
							onClick={handleCreateSurveyFromTemplate}
							key={template.id}
						/>
					))
				) : (
					<Body size='xs'>
            There are no templates in this category yet.
					</Body>
				)}
			</div>
		</div>
	);
};
