import React, {ReactElement, useContext, useEffect, useRef, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";

import {
	formatDate,
	Heading,
	Icon,
	InputWithButton,
	ReelVideo,
} from "../../../shared";
import {
	ReelDisplayMode,
	ReelVideoData,
	UpdateReelChanges,
	UpdateReelDeletions,
	UpdateReelsData,
	UpdateReelVars,
} from "../../../models/reels";
import {GET_REEL_VIDEO} from "../../../graphql/queries/reel-queries";
import {ReelContext} from "../../../context/reel-context";
import {ToastContext} from "../../../context/toast-context";
import {TranscodingStatus} from "../../../models/answer";
import {UPDATE_REEL} from "../../../graphql/mutations/reel-mutations";
import {Button, Input, RadioGroup, Checkbox, Body} from "../../../shared/v2";

import styles from "./share-screen.module.scss";

export interface ShareScreenProps {
	reelId: string;
	reelName: string;
	createdAt?: Date;
	reelCreator?: string;
}

/**
 * This component is pretty similar to a modal, but the screen we have in figma
 * show this as taking up the full screen and essentially have a sidebar in the "modal".
 * I didn't want to modify the FullScreen modal or PortalModal a bunch to achieve the
 * look we want, so made this.
 *
 * @param isShowing Determines if this screen should show or not.
 */
const ShareScreen = (props: ShareScreenProps): ReactElement | null => {
	const {createdAt, reelId, reelName, reelCreator} = props;
	const {isShowingShareScreen: isShowing, toggleShareScreen} = useContext(ReelContext);

	const {updateToast} = useContext(ToastContext);
	const inputRef = useRef<HTMLInputElement>(null);
	const {data, loading, stopPolling} = useQuery<ReelVideoData>(GET_REEL_VIDEO, {
		variables: {reelId},
		skip: !isShowing,
		fetchPolicy: "network-only",
		pollInterval: 3000,
		notifyOnNetworkStatusChange: true,
		onCompleted: (vidData) => {
			if (
				vidData.reel.video?.transcodingStatus === TranscodingStatus.COMPLETED ||
				vidData.reel.video?.transcodingStatus === TranscodingStatus.FAILED
			)
				stopPolling();
		},
	});

	const [password, setPassword] = useState(data?.reel.password || "");

	const [updateReel] = useMutation<UpdateReelsData, UpdateReelVars>(UPDATE_REEL, {
		onCompleted() {
			updateToast({
				description: "Preferences updated",
				type: "informational",
			});
		},
		onError() {
			updateToast({
				description: "Operation failed, please check your connection",
				type: "failure",
			});
		},
	});

	 
	const handleUpdateReel = (changes?: UpdateReelChanges, deletions?: UpdateReelDeletions): void => {
		updateReel({
			variables: {
				id: reelId,
				changes,
				deletions,
			},
		});
	};

	const handleCopy = (): void => {
		if (inputRef.current) {
			inputRef.current.select();
			document.execCommand("copy");
			updateToast({
				description: "Copied to clipboard",
				type: "informational",
			});
		}
	};

	useEffect(() => {
		setPassword(data?.reel.password || "");
	}, [data?.reel.password]);

	if (!isShowing) return null;
	return (
		<div className={styles.container}>
			{
				!data && loading && <div className={styles.loading}>
					<p>Loading sharing information...</p>
				</div>
			}
			{ data &&
				 
				<div className={`${styles.content} ${styles[data.reel.displayMode.toLocaleLowerCase()]}`}>
					<header className={styles.header}>
						<Heading size="md">{reelName}</Heading>
						<p className={styles.info}>
							by {reelCreator}, {createdAt && formatDate(createdAt)}
						</p>
					</header>
					{
						data &&
						<div className={styles.videoContainer}>
							<ReelVideo video={data.reel.video} subtitles={data.reel.subtitles}/>
						</div>
					}
				</div>
			}
			<div className={styles.sidePanel}>
				<Icon
					clicked={toggleShareScreen}
					name="cross"
					size="extrasmall"
					className={styles.icon}
				/>
				<Heading size="md" additionalClasses={styles.sidepanelHeader}>
					Share Link
				</Heading>
				<InputWithButton
					id="copy-link"
					buttonText="Copy"
					ref={inputRef}
					defaultValue={`${window.location.origin}/share/${reelId}`}
					readOnly
					onClick={handleCopy}
				/>
				<RadioGroup
					size="s"
					value={data?.reel.displayMode || ReelDisplayMode.LIGHT}
					onChange={value => handleUpdateReel({displayMode: value})}
					className={styles.radio}
				>
					<RadioGroup.Option
						label="Dark background"
						value={ReelDisplayMode.DARK}
					/>
					<RadioGroup.Option
						label="Light background"
						value={ReelDisplayMode.LIGHT}
					/>
				</RadioGroup>
				<section className={styles.password}>
					<Checkbox
						id="password-checkbox"
						size="s"
						checked={data?.reel.requiresPassword || false}
						 
						onChange={e => handleUpdateReel({requiresPassword: e.target.checked}, {password: !e.target.checked})}
						className={styles.passwordCheckbox}
						text={(
							<Body size="xs">
								Require a password
							</Body>
						)}
					/>
					<div className={styles.passwordInput}>
						<Input
							id="reel-password"
							value={password}
							onChange={setPassword}
							disabled={!data?.reel.requiresPassword}
						/>
						<Button
							disabled={!data?.reel.requiresPassword || password === data?.reel.password}
							onClick={() => handleUpdateReel({password}, {password: password?.trim() === ""})}
						>
							Save
						</Button>
					</div>
				</section>
			</div>
		</div>
	);
};

export {ShareScreen};
