import {IdeaParsed, InsightsParsed} from "../../../models/survey";
import React, {ReactElement, useContext, useMemo, useState} from "react";
import {
	SurveyInsights,
	UpdateSurveyInsightsChanges,
	UpdateSurveyInsightsProps,
} from "../../../models/survey-insights";
import {GET_SUMMARY} from "../../../graphql/queries/survey-queries";
import {REGEN_INSIGHTS, UPDATE_SURVEY_INSIGHTS} from "../../../graphql/mutations/survey-mutations";
import {ContentEditable} from "../../../shared/components/content-editable/content-editable";
import {GenericTitleDesc} from "../../components/auto-reel-info";
import {SharePanel} from "../../components/share-panel";
import {SummaryReturn} from "../../../models/questions";
import {SurveyContext} from "../../../context/survey-context";
import styles from "./quick-summary.module.scss";
import {useLoadingQuery} from "../../../hooks";
import {useMutation} from "@apollo/client";
import {UserContext} from "../../../context/user-context";
import {UserRoles} from "../../../models/user";
import {ToastContext} from "../../../context/toast-context";
import {Icon} from "../../../shared";
import {useLocation} from "react-router";
import {ConfirmModal} from "../../../modals/confirm-modal";
import {Button, Spinner} from "../../../shared/v2";

/**
 * NOTES: This has been very much a work in progress so I comment stuff out more frequently.
 * Mostly because opinions have been changing a lot on what to show and not to show.
 */

export interface SurveySummaryProps {
	summary?: GenericTitleDesc[];
}

export interface ObjectivesProps {
	objective: string;
	questions: string[]; // Ids of related questions to objective
}
export interface QuestionSummaryProps {
	questionId: string;
}
export interface InsightSectionProps {
	insights: InsightsParsed;
}

export interface ProsAndConsProps {
	pros?: GenericTitleDesc[];
	cons?: GenericTitleDesc[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleUpdate: (surveyInsightsId: string, test: any) => void;
}

export interface IdeasProps {
	ideas?: IdeaParsed[] | string;
	updateIdeas?: (newText: string) => void;
	updateSteps?: (newText: string) => void;
}

export interface QuickSummaryPageProps {
	surveyId?: string // SurveyId in case we pass it in instead of using context
	password?: string // Also used for shared summary
}

const Ideas = ({ideas, updateIdeas, updateSteps}: IdeasProps): ReactElement | null => {
	if (typeof ideas === "string" || !ideas) return null;
	// The way ideas is structured makes this a bit annoying to do.
	const idea = ideas[0]?.["Idea starter"];
	const steps = ideas[1]?.["Next Steps"];
	return (
		<article className={styles.ideation}>
			{idea && <><span>💡<b>New idea:</b> </span> <ContentEditable el="p"
				handleUpdate={updateIdeas}
			>
				{idea.trim()}
			</ContentEditable></>}
			{steps && <><span>🚀 <b>Next steps:</b></span><ContentEditable el="p"
				handleUpdate={updateSteps}
			>
				{steps.trim()}
			</ContentEditable></>}
		</article>
	);
};

const QuickSummaryPage = ({surveyId, password}: QuickSummaryPageProps): ReactElement => {
	const {survey: {id}, setShowShare} = useContext(SurveyContext);
	const {user: {role}} = useContext(UserContext);
	const {updateToast} = useContext(ToastContext);
	const {pathname} = useLocation();
	const [showModal, setShowModal] = useState(false);
	const {data, fragment, error} = useLoadingQuery<SummaryReturn>(GET_SUMMARY, {
		variables: {surveyId: id || surveyId, password},
		what: "Summary",
	});

	const [updateInsight] =
		useMutation<SurveyInsights, UpdateSurveyInsightsProps>(UPDATE_SURVEY_INSIGHTS);
	const [regenerateInsights, {loading: regen}] = useMutation(REGEN_INSIGHTS);


	const handleUpdate = (surveyInsightsId: string, changes: Partial<UpdateSurveyInsightsChanges>): void => {
		updateInsight({
			variables: {id, surveyInsightsId, changes},
			onCompleted: () => updateToast({description: "Updated summary", type: "informational"}),
			onError: () => updateToast({description: "Error updating summary", type: "failure"}),
		});
	};

	const handleRegenerate = (): void => {
		setShowModal(false);
		regenerateInsights({
			variables: {id},
			refetchQueries: [GET_SUMMARY],
			awaitRefetchQueries: true,
			onCompleted: d => {
				// If regenerateInsights is false, notify the user
				if (!d.regenerateInsights) updateToast({description: "Regenerate failed", type: "failure"});
			},
			onError: () => updateToast({description: "Regenerate failed", type: "failure"}),
		});
	};

	const hasBeenEdited = useMemo((): boolean => {
		if (!data) return false;
		return data.survey.surveyInsights.some(ins => ins.edited);
	}, [data, data?.survey.surveyInsights]);
	/**
	 * I think this doesn't happen anymore? Should test to validate we don't see this.
	 */
	if (error && error?.graphQLErrors[0]?.extensions?.name.includes("Authentication")) {
		return <div className={styles.error}>
			<h1>This summary is not available</h1>
			{ }
			<p>Have the owner of the survey make sure that the survey is set to &quot;Public&quot; on the review page.</p>
		</div>;
	}


	const canEdit = role && id && (role === UserRoles.ENTERPRISE_MANAGER || role === UserRoles.SUPPORT);

	if (fragment) return fragment;
	if (regen || data?.survey.insightsProcessing) {
		return (
			<div className={styles.regenContainer}>
				<Spinner />
				<h1>We are currently regenerating insights, please come back later </h1>
			</div>
		);
	}

	return (
		<div className={styles.main}>
			{pathname.includes("survey") && canEdit && (
				<div className={styles.center}>
					<Button onClick={() => setShowModal(true)} className={styles.regen}>
						Regenerate summary
					</Button>
				</div>
			)}
			<div className={styles.container}>
				{data?.survey.surveyInsights.length ? (
					<div className={styles.top}>
						<span>✨ Vurvey AI summary</span>
						{hasBeenEdited && (
							<p className={styles.reviewed}>
								<Icon name="checkmark" />
								<em> Reviewed by Vurvey</em>
							</p>
						)}
						{pathname.includes("survey") && (
							<span className={styles.link} onClick={() => setShowShare(true)}>
								<Icon name="share" size="small" /> Share
							</span>
						)}
					</div>
				) : (
					<div className={styles.empty}>
						<h1>✨ Vurvey AI summary</h1>
						<p>Take your experience to the next level</p>
						<p>AI-powered summaries available for enterprise subscriptions</p>
						<p>
							Contact{" "}
							<a href="mailto: customersuccess@vurvey.co" className={styles.link}>
								Vurvey
							</a>{" "}
							to upgrade today!
						</p>
					</div>
				)}
				{data?.survey.surveyInsights?.map((insights, i) => (
					<section key={i} id={insights.id} className={styles.mainSection}>
						{insights?.headlines?.map(headline => (
							<header key={headline.headline} className={styles.header}>
								{i === 0 && (
									<ContentEditable
										el="h1"
										handleUpdate={
											canEdit
												? e => handleUpdate(insights.id, {headlines: {headline: e}})
												: undefined
										}
									>{headline.headline.trim()}</ContentEditable>
								)}
								{i > 0 && (
									<ContentEditable
										handleUpdate={
											canEdit
												? e => handleUpdate(insights.id, {headlines: {subheading: e}})
												: undefined
										}
										el="h2"
									>
										{headline.headline.trim()}
									</ContentEditable>
								)}
								{i === 0 && (
									<ContentEditable
										handleUpdate={
											canEdit
												? e => handleUpdate(insights.id, {headlines: {subheading: e}})
												: undefined
										}
										el="h2"
									>
										{headline.subheading.trim()}
									</ContentEditable>
								)}
							</header>
						))}
						{insights?.summary?.map(summary => (
							<article className={styles.summary} key={insights.id}>
								<ContentEditable
									el="p"
									className={styles.paragraph}
									handleUpdate={
										canEdit
											? t => handleUpdate(insights.id, {summary: {description: t}})
											: undefined
									}
								>
									{summary.description.trim()}
								</ContentEditable>
							</article>
						))}
						{insights?.insights?.map((insight, k) => (
							<article className={styles.summary} key={insight.insights[k].slice(0, 10)}>
								<h2 >Insights</h2>
								<ContentEditable
									el="p"
									className={styles.paragraph}
									handleUpdate={
										canEdit ? t => handleUpdate(insights.id, {insights: {insights: t}}) : undefined
									}
								>
									{insight.insights.trim()}
								</ContentEditable>
							</article>
						))}
						<article className={styles.procon}>
							<div className={styles.left}>
								{insights?.pros?.map(pro => (
									<React.Fragment key={pro.title}>
										<h2>{pro.title}</h2>
										<ContentEditable
											el="p"
											className={styles.list}
											handleUpdate={
												canEdit
													? t => handleUpdate(insights.id, {pros: {description: t}})
													: undefined
											}
										>
											{pro.description.trim()}
										</ContentEditable>
									</React.Fragment>
								))}
							</div>
							<div className={styles.right}>
								{insights?.cons?.map(con => (
									<React.Fragment key={con.title}>
										<h2>{con.title}</h2>
										<ContentEditable
											el="p"
											className={styles.list}
											handleUpdate={
												canEdit
													? t => handleUpdate(insights.id, {cons: {description: t}})
													: undefined
											}
										>
											{con.description.trim()}
										</ContentEditable>
									</React.Fragment>
								))}
							</div>
						</article>
						<Ideas
							ideas={insights?.ideas}
							updateIdeas={
								canEdit ? e => handleUpdate(insights.id, {ideas: {idea_starter: e}}) : undefined
							}
							updateSteps={canEdit ? e => handleUpdate(insights.id, {ideas: {nextSteps: e}}) : undefined}
						/>
					</section>
				))}
			</div>
			<SharePanel />
			{canEdit && (
				<ConfirmModal
					isOpen={showModal}
					confirm={handleRegenerate}
					text="Are you sure you want to regenerate the summary? This will completely
					change the summary from what it is now"
					onClose={() => setShowModal(false)}
				/>
			)}
		</div>
	);
};

export {QuickSummaryPage};
