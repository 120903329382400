import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {MultiSelect, Select, ColorPicker} from "../../../shared";
import {
	ActivitiesReturn,
	BenefitsQueryReturn,
	BrandColor,
	BrandUpdateProps,
	BrandUpdateReturn,
	CategoryQueryReturn,
} from "../../../models/brand";
import {
	ADD_BRAND_BENEFIT,
	CREATE_BRAND_BANNER,
	CREATE_BRAND_LOGO,
	REMOVE_BRAND_BENEFIT,
	UPDATE_BRAND,
} from "../../../graphql/mutations/workspace-mutations";
import {useWorkspaceContext} from "../../../context/workspace-context";
import styles from "./brand-settings.module.scss";
import {useMutation, useQuery} from "@apollo/client";
import {useMediaUpload} from "../../../hooks/useMediaUpload";
import {SimpleImageInput} from "../../../shared/components/input/simple-image-input";
import {ToastContext} from "../../../context/toast-context";
import {WarningModal} from "../../../modals/warning-modal";

import {GET_COUNTRIES} from "../../../graphql/queries/queries";
import {GET_ALL_BRAND_ATTRIBUTES} from "../../../graphql/queries/brand-queries";
import {findSingleDifference} from "../../../shared/utility/utility";
import {Button, Input, TextArea, Body} from "../../../shared/v2";

type ColorType = "primary" | "secondary" | "tertiary" | "quaternary";

const findColor = (colors: BrandColor[] | undefined, type: ColorType): string => {
	return colors?.find(color => color.type === type)?.color || "";
};


const isColorDifferent = (colors: BrandColor[] | undefined, type: ColorType, newColor: string): boolean => {
	const current = colors?.find(color => color.type === type)?.color || "";
	if (newColor !== current) return true;
	return false;
};

const BrandSettingsPage = (): ReactElement => {
	const {workspace: {brand}} = useWorkspaceContext();
	const {updateToast, onError} = useContext(ToastContext);

	const {data: countries} = useQuery(GET_COUNTRIES, {
		fetchPolicy: "no-cache",
	});

	const {data: attr} = useQuery<CategoryQueryReturn & BenefitsQueryReturn & ActivitiesReturn>(GET_ALL_BRAND_ATTRIBUTES, {
		fetchPolicy: "cache-and-network",
	});

	// States (so many)
	const [name, setName] = useState(brand?.name || "");
	const [description, setDesc] = useState(brand?.description || "");
	const [countryId, setCountryId] = useState(brand?.countryOfOriginId);
	const [primaryCat, setPrimaryCat] = useState(brand?.primaryCategory?.id || null);
	const [targetCIds, setTargetCIds] = useState(brand?.targetCountries?.map(t => t.id) || []);
	const [secCats, setSecCats] = useState(brand?.secondaryCategories?.map(cat => cat.id) || []);
	const [showWarning, setShowWarning] = useState(false);
	const [benefits, setBenefits] = useState(brand?.benefits?.map(b => b?.id) || []);
	const [activities, setActivities] = useState(brand?.activities?.map(a => a?.id) || []);
	const [primaryColor, setPrimaryColor] = useState(findColor(brand?.colors, "primary"));
	const [secondaryColor, setSecondaryColor] = useState(findColor(brand?.colors, "secondary"));
	const [tertiaryColor, setTertiaryColor] = useState(findColor(brand?.colors, "tertiary"));
	const [quaternaryColor, setquaternaryColor] = useState(findColor(brand?.colors, "quaternary"));

	const [createBrandLogo] = useMutation(CREATE_BRAND_LOGO);
	const [createBrandBanner] = useMutation(CREATE_BRAND_BANNER);

	const toggleWarning = (): void => setShowWarning(prev => !prev);

	const {
		media: bannerMedia,
		setMedia: setBannerMedia,
		handleChange: handleChangeBanner,
		handleSave: handleSaveBanner,
		mediaId: bannerId,
		isProcessing: bannerProcessing,
	} = useMediaUpload();

	const {
		media: logoMedia,
		setMedia: setLogoMedia,
		handleChange: handleChangeLogo,
		handleSave: handleSaveLogo,
		mediaId: logoId,
		isProcessing: logoProcessing,
	} = useMediaUpload();

	const [updateBrand, {loading: updating}]
	= useMutation<BrandUpdateReturn, BrandUpdateProps>(UPDATE_BRAND, {
		onCompleted: () => updateToast({description: "Changes saved", type: "informational"}),
		onError: error => {
			if (!error.message.includes("brands_url_unique")) {
				onError(error);
				return;
			}
			updateToast({
				description: "Your URL is not unique, please choose another one", type: "failure",
			});
		},
	});

	const [addBenefit] = useMutation(ADD_BRAND_BENEFIT);
	const [removeBenefit] = useMutation(REMOVE_BRAND_BENEFIT);

	const handleSave = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		if (!brand) return;
		if (brand.visible && !name) {
			updateToast({description: "A name is required for visible brands", type: "failure"});
			return;
		}
		updateBrand({
			variables: {
				brandId: brand.id,
				changes: {
					name,
					description: description || undefined,
					countryOfOriginId: countryId,
					logoPictureId: logoId,
					bannerPictureId: bannerId,
					primaryCategoryId: primaryCat,
					secondaryCategoryIds: secCats,
					targetCountryIds: targetCIds,
					activityIds: activities,
					colors: [
						{type: "primary", color: primaryColor},
						{type: "secondary", color: secondaryColor},
						{type: "tertiary", color: tertiaryColor},
						{type: "quaternary", color: quaternaryColor},
					],
				},
				deletions: {
					description: !description || undefined,
				},
			},
		});
	};

	// Banner useEffects
	useEffect(() => {
		if (bannerMedia.raw) handleSaveBanner(createBrandBanner);
	}, [bannerMedia.raw]);

	useEffect(() => {
		if (brand) {
			const {bannerPicture} = brand;
			if (bannerPicture) {
				setBannerMedia(prev => ({...prev, preview: bannerPicture.original}));
			}
		}
	}, [brand?.bannerPicture]);

	// Logo useEffects
	useEffect(() => {
		if (logoMedia.raw) handleSaveLogo(createBrandLogo);
	}, [logoMedia.raw]);

	useEffect(() => {
		if (brand) {
			const {logoPicture} = brand;
			if (logoPicture) {
				setLogoMedia(prev => ({...prev, preview: logoPicture.medium}));
			}
		}
	}, [brand?.logoPicture]);

	// Maybe overkill to make this a memo?
	const newChanges = useMemo(() => {
		return (name !== brand?.name) || (logoMedia.preview !== brand.logoPicture?.medium)
		|| (brand?.description !== description) || (brand?.countryOfOriginId !== countryId)
		|| (brand?.secondaryCategories?.length !== secCats?.length)
		|| (bannerMedia.preview !== brand.bannerPicture?.original)
		|| (brand?.targetCountries?.length !== targetCIds.length)
		|| (brand.primaryCategory?.id !== primaryCat)
		|| (brand?.benefits.length !== benefits.length)
		|| (brand.activities?.length !== activities.length)
		|| logoProcessing || isColorDifferent(brand?.colors, "primary", primaryColor)
		|| isColorDifferent(brand?.colors, "secondary", secondaryColor)
		|| isColorDifferent(brand?.colors, "tertiary", tertiaryColor)
		|| isColorDifferent(brand?.colors, "quaternary", quaternaryColor);
	}, [
		name,
		activities,
		benefits,
		description,
		logoMedia,
		bannerMedia,
		logoProcessing,
		countryId,
		secCats,
		targetCIds,
		primaryCat,
		primaryColor,
		secondaryColor,
		tertiaryColor,
		quaternaryColor,
	]);

	const handleMultiSelect = (n: string[], id: string): void => {
		if (id.includes("categories")) {
			setSecCats(n);
			// Awkward, but with API makes the most sense to me.
		} else if (id.includes("benefits")) {
			const {added, value} = findSingleDifference(n, benefits);
			setBenefits(n);
			if (added) {
				addBenefit({
					variables: {brandId: brand?.id, benefitId: value},
				});
			} else {
				removeBenefit({
					variables: {brandId: brand?.id, benefitId: value},
				});
			}
		} else if (id.includes("activities")) {
			setActivities(n);
		} else {
			setTargetCIds(n);
		}
	};

	function handlePrimaryCatChange(newValue): void {
		setPrimaryCat(newValue);
		setSecCats(prev => prev.filter(cat => cat !== newValue));
	}

	return (
		<>
			<div>
				<form className={styles.page} onSubmit={handleSave}>
					<SimpleImageInput
						id="brand-banner-input"
						media={bannerMedia}
						onChange={handleChangeBanner}
						className={styles.banner}
						emptyIcon="camera"
						isProcessing={bannerProcessing}
						helpText="960x240 for best look"
					/>
					<div className={styles.logoName}>
						<SimpleImageInput
							id="brand-logo-input"
							media={logoMedia}
							onChange={handleChangeLogo}
							className={styles.badge}
							emptyIcon="camera"
							isProcessing={logoProcessing}
							helpText="100x100"
						/>
						<Input
							id="brand-name"
							label="Name"
							onChange={setName}
							value={name}
							className={styles.name}
						/>
					</div>
					<TextArea
						id="brand-description"
						onChange={setDesc}
						value={description}
						label="Description"
						className={styles.textarea}
					/>
					<MultiSelect
						id="brand-activities"
						label="Activities"
						value={activities}
						onChange={handleMultiSelect}
						options={attr?.activities.map(activity => ({name: activity.name, id: activity.id})) ?? []}
					/>
					<MultiSelect
						id="brand-benefits"
						label="Benefits"
						value={benefits}
						onChange={handleMultiSelect}
						options={attr?.benefits.map(b => ({name: b.name, id: b.id})) ?? []}
					/>
					<Select
						id="primary-category"
						label="Primary Category"
						selectedValue={primaryCat}
						onChange={handlePrimaryCatChange}
						options={attr?.brandCategories
							.map(cat => ({text: cat.name, value: cat.id}))
							.sort((a, b) => a.text.localeCompare(b.text)) ?? []}
					/>
					<MultiSelect
						id="brand-categories"
						label="Secondary Categories"
						value={secCats}
						onChange={handleMultiSelect}
						options={
							attr?.brandCategories
								.filter(cat => cat.id !== primaryCat)
								.map(cat => ({name: cat.name, id: cat.id}))
								.sort((a, b) => a.name.localeCompare(b.name)) ?? []
						}
					/>
					<Select
						id="brand-origin-country"
						label="Origin country"
						selectedValue={countryId}
						onChange={setCountryId}

						options={countries?.countries.map(country => ({text: country.name, value: country.id})) ?? []}
					/>
					<MultiSelect
						id="brand-target-countries"
						label="Target Countries"
						value={targetCIds}
						onChange={handleMultiSelect}

						options={countries?.countries.map(country => ({name: country.name, id: country.id})) ?? []}
					/>
					<span className={styles.label}>Colors</span>
					<div className={styles.colorSection}>
						<ColorPicker id="primary-color" value={primaryColor} onChange={setPrimaryColor}>
							<Body>Primary</Body>
						</ColorPicker>
						<ColorPicker id="secondary-color" value={secondaryColor} onChange={setSecondaryColor}>
							<Body>Secondary</Body>
						</ColorPicker>
						<ColorPicker id="tertiary-color" value={tertiaryColor} onChange={setTertiaryColor}>
							<Body>Tertiary</Body>
						</ColorPicker>
						<ColorPicker id="quaternary-color" value={quaternaryColor} onChange={setquaternaryColor}>
							<Body>Quaternary</Body>
						</ColorPicker>
						<div className={styles.exampleCard} style={{backgroundColor: secondaryColor || "white"}}>
							<span style={{color: tertiaryColor || secondaryColor || "black"}}>
                Your question goes here
							</span>
							<button
								className={styles.cardButton}
								disabled
								style={{backgroundColor: primaryColor || "black"}}
							>
								<span style={{color: quaternaryColor || tertiaryColor || primaryColor || "white"}}>50</span>
								<img src={logoMedia.preview} />
							</button>
						</div>
					</div>
					<Button
						type="submit"
						disabled={!newChanges || logoProcessing || updating}
						className={styles.save}
					>
						Save Changes
					</Button>
				</form>
				<WarningModal
					isOpen={showWarning}
					onClose={toggleWarning}
					text="A Landing Page must have a URL and Name to be visible"
					title="Warning"
				/>
			</div>
		</>
	);
};

export {BrandSettingsPage};
