import {Dropdown, DropdownOptions} from "../dropdown";
import {IconTypes} from "../icon";
import React, {ReactElement} from "react";
import styles from "./options.module.scss";
import {useDropdown} from "../../../hooks";
import {Body} from "../../../shared/v2/typography";
import {ButtonIcon, Tooltip} from "../../v2";
import {DotsIcon, InformationCicrcleIcon} from "../../../icons";


export interface OptionsProps {
	/**
	 * Type of icon to click. Generally this will be "menu-vertical", but maybe we will
	 * eventually want others
	 */
	type: IconTypes;
	/**
	 * The options to build for the dropdown
	 */
	options: DropdownOptions[];
	/**
	 * Position the dropdown should try to go to
	 */
	position?: "left" | "right";
	/**
	 * If this should only show when hovering parent
	 */
	isHidden?: boolean;
}

const Options = (props: OptionsProps): ReactElement => {
	const {options, position = false} = props;
	const {anchorEl, handleClick, handleClose} = useDropdown();
	const handleOpen = (e: React.MouseEvent<HTMLElement>): void => {
		e.stopPropagation();
		handleClick(e);
	};
	return (
		<>
			<ButtonIcon onClick={handleOpen} icon={<DotsIcon />}/>
			<Dropdown
				anchorEl={anchorEl}
				closeMenu={handleClose}
				position={position as any}
			>
				{options.map(({name, actionOptions, icon, iconFill, tooltipText}) => (
					<Dropdown.Item
						key={name}
						options={actionOptions}
						icon={icon}
						iconFill={iconFill}
					>
						<Body size="s">{name} {tooltipText && <Tooltip content={<Body>{tooltipText}</Body>} containerClassname={styles.tooltip}>
							<InformationCicrcleIcon className={styles.small}/>
						</Tooltip>}
						</Body>

					</Dropdown.Item>
				))}
			</Dropdown>
		</>
	);
};

export {Options};
