export const calculatePathsFromContours = (contours: number[][]): number[][] => {
  const paths: {x: number, y: number}[][] = contours.map((contour) => {
    return contour.map((point) => {
      return {
        x: point[0] as number,
        y: point[1] as number,
      };
    });
  });

  return paths.map(path => {
    return path.flatMap(point => [point.x, point.y]);
  });
};
