import {isEqual, isNull} from "lodash-es";
import {useMutation, useQuery} from "@apollo/client";
import React, {ReactElement, ReactNode, createContext, useContext, useEffect, useMemo, useReducer, useState} from "react";

import {
	AGENT_INITIAL_STATE,
	agentCreationReducer,
	AgentCreationReducerActionTypes,
	AgentCreationState,
	AgentCreationUpdateInput,
} from "../reducer/agent-creation-reducer";
import {AGENT_MAX_TOKENS} from "../shared/constants/constants";
import {AGENTS_PUBLISHED, AI_PERSONA_BY_ID, AI_PERSONA_TYPES, AI_SKILLS, GET_AI_PERSONA_CATEGORIES, GET_PERSONAS} from "../graphql/queries/ai-models-queries";
import {AiPersonaByIdData, AiPersonaCategoryData, AiSkill} from "../models/ai-model";
import {CREATE_AI_PERSONA, PERSONA_DEACTIVATE, PERSONA_PUBLISH, UPDATE_PERSONA} from "../graphql/mutations/persona-mutations";
import {AiPersonaInput, CreatePersonaReturn, CreatePersonaVars, Persona, UpdatePersonaReturn, UpdatePersonaVars} from "../models/persona";
import {graphQLErrorFormatter} from "../shared/utility/graphql-error-formatter";
import {useNavigate, useSearchParams} from "../route";
import {useToastContext} from "./toast-context";
import {useValidation, UseValidationResults} from "../hooks/useValidation";
import {useWorkspaceContext} from "./workspace-context";

const {UPDATE, RESET} = AgentCreationReducerActionTypes;

export type SaveAgent = (agentProps?: Partial<Pick<AgentCreationState, "name" | "categoryId" | "picture" | "description">>) => void;

export interface AgentType {
  id: string;
  name: string;
  tooltip: string;
  outputTypeCode: string;
}

export interface CreatorScorllTopState {
  form: number;
  preview: number;
}

export interface AgentCreationContextValue {
  activate: () => void;
  deactivate: () => void;
  agent: Persona | undefined;
  isCreating: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  isVisualMode: boolean;
  changes: Partial<AgentCreationState>;
  isNavigationOpen: boolean;
  setScrollTop: (name: keyof CreatorScorllTopState, value: number) => void;
  stringifiedInstructions: string | undefined;
  reset: () => void;
  save: SaveAgent;
	saveInLocalStorage: () => void;
  state: AgentCreationState;
  update: (input: AgentCreationUpdateInput) => void;
  validation: UseValidationResults<AgentCreationState>;
  agentTypes: AgentType[];
  visualAgentType: AgentType;
  skills: AiSkill[]
}

export const AgentCreationContext =
  createContext<AgentCreationContextValue | undefined>(undefined);

const isArrayPropChanged = (
	prop: keyof AgentCreationState,
	state: AgentCreationState,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	agentValue: any,
): boolean => {
	const value = state[prop];

	return agentValue === undefined || agentValue === null ?
		value !== AGENT_INITIAL_STATE[prop] :
		!isEqual(value, agentValue);
}

const isPropChanged = (
	prop: keyof AgentCreationState,
	state: AgentCreationState,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	agentValue: any,
): boolean => {
	const value = state[prop];

	return agentValue === undefined || agentValue === null ?
		value !== AGENT_INITIAL_STATE[prop] :
		value !== agentValue;
}

export const AgentCreationContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {updateToast} = useToastContext();
	const {id} = useSearchParams();
	const navigate = useNavigate();
	const [creatorScrollTop, setCreatorScrollTop] = useState({form: 0, preview: 0});
	const [state, dispatch] = useReducer(agentCreationReducer, AGENT_INITIAL_STATE);
	const {results: validation, validate} = useValidation(state, {
		name: {
			ruleset: {
				required: true,
			},
		},
		personaTypeId: {
			ruleset: {
				required: true,
			},
			customErrorMessage: {
				required: () => "Type field is required",
			}
		},
		description: {
			ruleset: {
				required: true,
			},
		},
		instructions: {
			ruleset: {
				required: true,
			},
		},
		background: {
			ruleset: {
				required: true,
			},
		},
		skillIds: {
			ruleset: {
				custom: (value) => value.length > 0
			},
			customErrorMessage: {
				custom: () => "Tags field is required",
			}
		}
	})
	const isNavigationOpen = creatorScrollTop.form < 30 && creatorScrollTop.preview < 18;

	const [createPersona, {loading: isCreating}] = useMutation<CreatePersonaReturn, CreatePersonaVars>(CREATE_AI_PERSONA);
	const [updateAiPersona, {loading: isUpdating}] = useMutation<UpdatePersonaReturn, UpdatePersonaVars>(UPDATE_PERSONA);
	const [publishPersona] = useMutation(PERSONA_PUBLISH);
	const [deactivatePersona] = useMutation(PERSONA_DEACTIVATE);

	const {
		data: {
			aiPersonaById: agent,
		} = {},
		loading: isLoading,
	} = useQuery<AiPersonaByIdData>(AI_PERSONA_BY_ID, {
		variables: {
			personaId: id,
			workspaceId,
			version: "FULL_SIZE",
		},
		skip: !id,
	});

	const {data: aiCategoriesData} =
    useQuery<AiPersonaCategoryData>(GET_AI_PERSONA_CATEGORIES);

	const {data: agentTypesData} = useQuery(AI_PERSONA_TYPES, {
		fetchPolicy: "cache-first",
	});

	const {data: skillsData} = useQuery(AI_SKILLS, {
		fetchPolicy: "cache-first",
	});

	const agentTypes = useMemo(() => {
		if (!agentTypesData) {
			return [];
		}

		return agentTypesData.aiPersonaTypes.map(({id, name, description, outputType}) => ({
			id,
			name,
			tooltip: description,
			outputTypeCode: outputType.code
		}));
	}, [agentTypesData])

	const visualAgentType = useMemo(
		() => agentTypes.find(({outputTypeCode}) => outputTypeCode === "generate_image"),
		[agentTypes]
	);
	const isVisualMode = useMemo(
		() => state.personaTypeId === visualAgentType?.id,
		[state.personaTypeId, visualAgentType]
	);

	const changes = useMemo(() => {
		const changes: Partial<AgentCreationState> = {};

		if (isPropChanged("name", state, agent?.name)) {
			changes.name = state.name;
		}
		if (isPropChanged("background", state, agent?.parsedInstructions?.system_prompt)) {
			changes.background = state.background;
		}
		// TODO: Restore this line when the feature is implemented
		// if (isPropChanged("continuouslyLearning", state, agent?.continuouslyLearning)) {
		//   changes.continuouslyLearning = state.continuouslyLearning;
		// }
		if (isPropChanged("description", state, agent?.description)) {
			changes.description = state.description;
		}
		if (isPropChanged("instructions", state, agent?.parsedInstructions?.chat_prompt)) {
			changes.instructions = state.instructions;
		}
		if (isPropChanged("model", state, agent?.parsedInstructions?.model)) {
			changes.model = state.model;
		}
		if (isNull(state.picture) || state.picture?.id !== agent?.picture?.id) {
			changes.picture = state.picture;
		}
		if (isPropChanged("temperature", state, agent?.parsedInstructions?.temperature)) {
			changes.temperature = state.temperature;
		}
		if (isPropChanged("personaTypeId", state, agent?.personaType.id)) {
			changes.personaTypeId = state.personaTypeId;
		}
		if (isPropChanged("voiceId", state, agent?.voiceId)) {
			changes.voiceId = state.voiceId;
		}
		if (isPropChanged("voiceName", state, agent?.voiceName)) {
			changes.voiceName = state.voiceName;
		}
		if (isArrayPropChanged("skillIds", state, agent?.skills.map(({id}) => id))) {
			changes.skillIds = state.skillIds;
		}

		return changes;
	}, [state, agent]);

	const stringifiedInstructions = useMemo(() => {
		const background = state.background ?? agent?.parsedInstructions?.system_prompt;
		const temperature = state.temperature ?? agent?.parsedInstructions?.temperature;
		const instructions = state.instructions ?? agent?.parsedInstructions?.chat_prompt;
		const model = state.model ?? agent?.parsedInstructions?.model;
		const personaTypeName = agentTypes.find(({id}) => id === state.personaTypeId)?.name;
		const output_type = agentTypes.find(({id}) => id === state.personaTypeId)?.outputTypeCode;

		if (!background || !model || !instructions || temperature === undefined) {
			return undefined;
		}

		return JSON.stringify([
			{
				temperature,
				max_tokens: AGENT_MAX_TOKENS,
				model,
				system_prompt: background,
				chat_prompt: instructions,
				agent_type: personaTypeName,
				output_type: output_type,
			},
		]);
	}, [state, agent]);

	const handleScrollTop = (name: keyof CreatorScorllTopState, value: number): void => {
		setCreatorScrollTop((prevState) => ({
			...prevState,
			[name]: value,
		}));
	}

	const handleUpdate = (input: AgentCreationUpdateInput): void => {
		dispatch({
			type: UPDATE,
			payload: input,
		});
	}

	const handleReset = (base = agent): void => {
		dispatch({
			type: RESET,
			payload: base,
		});
	}

	const handleActivate = () => {
		if (!validate()) {
			return;
		}

		publishPersona({
			variables: {
				personaId: agent?.id,
			},
			refetchQueries: [AGENTS_PUBLISHED],
			onCompleted: () => {
				updateToast({description: "Agent Published", type: "informational"});
			}
		})
	}

	const handleDeactivate = () => {
		deactivatePersona({
			variables: {
				personaId: agent?.id,
			},
			onCompleted: () => {
				updateToast({description: "Agent Deactivated", type: "informational"});
			}
		})
	}

	const handleSave: SaveAgent = (agentProps = {}) => {
		const {
			name,
			picture,
			description,
			personaTypeId,
			voiceId,
			voiceName,
			skillIds,
		} = {...(agent ? changes : state), ...agentProps};

		if (!validate()) {
			return;
		}

		const categoryId = aiCategoriesData?.aiPersonaCategories.find(
			(category) => category.name === "vTeam"
		)?.id;


		if (!agent) {
			const input: AiPersonaInput = {
				description,
				instructions: stringifiedInstructions,
				name,
				personaCategoryId: categoryId,
				// eslint-disable-next-line no-extra-boolean-cast
				pictureId: Boolean(picture) ? picture?.id : undefined,
				workspaceId,
				personaTypeId,
				voiceId,
				voiceName,
				skillIds: [],
			}

			input.skillIds = skillIds;

			return createPersona({
				variables: {
					input,
				},
				onCompleted: (data) => {
					navigate({search: {id: data.createAiPersona.id}});
					updateToast({description: "Agent Created", type: "informational"});
				},
				onError: (error) => {
					updateToast({
						description: graphQLErrorFormatter(error, {
							name: "Name",
							personaType: "Type",
							personaCategoryId: "Type",
							instructions: "Background and instructions",
						})[0],
						type: "failure"
					});
				},
				refetchQueries: [AI_PERSONA_BY_ID, GET_PERSONAS]
			})
		}

		const input: AiPersonaInput = {
			description,
			instructions: stringifiedInstructions,
			name,
			personaCategoryId: categoryId,
			// eslint-disable-next-line no-extra-boolean-cast
			pictureId: Boolean(picture) ? picture?.id : undefined,
			personaTypeId,
			voiceId,
			voiceName,
			skillIds: [],
		}

		input.skillIds = skillIds;

		return updateAiPersona({
			variables: {
				personaId: agent.id,
				input,
			},
			onCompleted: () => {
				updateToast({description: "Agent Updated", type: "informational"});
			},
			onError: (error) => {
				updateToast({
					description: graphQLErrorFormatter(error, {
						name: "Name",
						personaType: "Type",
						personaCategoryId: "Type",
						instructions: "Background and instructions",
					})[0],
					type: "failure",
				});
			},
			refetchQueries: [AI_PERSONA_BY_ID, GET_PERSONAS]
		})
	}

	const saveInLocalStorage = () => {
		if (!agent) {
			return;
		}

		localStorage.setItem(`unsavedChanges:${agent.id}`, JSON.stringify(state));
	}

	const readFromLocalStorage = () => {
		if (!agent) {
			return;
		}

		const unsavedChanges = localStorage.getItem(`unsavedChanges:${agent.id}`);

		if (!unsavedChanges) {
			return;
		}

		localStorage.removeItem(`unsavedChanges:${agent.id}`);

		const parsedChanges = JSON.parse(unsavedChanges);
		handleUpdate(parsedChanges);
		updateToast({description: "Unsaved changes restored", type: "informational"});
	}

	useEffect(handleReset, [agent]);
	useEffect(readFromLocalStorage, [agent]);

	return (
		<AgentCreationContext.Provider
			value={{
				activate: handleActivate,
				deactivate: handleDeactivate,
				agent,
				isCreating,
				isLoading,
				isUpdating,
				changes,
				stringifiedInstructions,
				reset: handleReset,
				save: handleSave,
				saveInLocalStorage,
				state,
				update: handleUpdate,
				isNavigationOpen,
				setScrollTop: handleScrollTop,
				isVisualMode,
				agentTypes,
				validation,
				visualAgentType,
				skills: skillsData?.aiSkills,
			}}
		>
			{children}
		</AgentCreationContext.Provider>
	);
};

export const useAgentCreationContext = (): AgentCreationContextValue => {
	const context = useContext(AgentCreationContext);

	if (context === undefined) {
		throw new Error(
			"useAgentCreationContext must be used within a AgentCreationContextProvider",
		);
	}

	return context;
};

export const useUnsafeAgentCreationContext = (): AgentCreationContextValue | undefined => {
	return useContext(AgentCreationContext);
}
