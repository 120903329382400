import React, {ReactElement, useEffect, useMemo, useState} from "react";

import {Button, SearchInput, Modal, Spinner} from "../../shared/v2";
import {Campaign} from "../../models/ai-model";
import {CampaignCard} from "./campaign-card";
import {ConfirmExitModal} from "../../shared/v2/modals/confirm-exit-modal";
import {Separator} from "../../shared/v2/separator";
import {useChatCampaignContext} from "../../context/chat-contexts";
import {useToastContext} from "../../context/toast-context";

import styles from "./select-campaign-modal.module.scss";

export interface SelectCampaignModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const SelectCampaignModal = ({
	isOpen,
	onClose,
}: SelectCampaignModalProps): ReactElement => {
	const {campaigns, activeCampaigns, saveCampaigns, isLoading, isUpdatingDisabled} = useChatCampaignContext();
	const [isExitModalOpen, setIsExitModalOpen] = useState(false);
	const [search, setSearch] = useState("");
	const {updateToast} = useToastContext();
	const [selectedCampaigns, setSelectedCampaigns] = useState<Campaign[]>(activeCampaigns || []);

	const filteredCampaigns = useMemo(
		() => (
			campaigns?.filter(
				({name}) => (
					name
						.toLowerCase()
						.includes(search.toLowerCase())
				),
			)
		),
		[search, campaigns],
	);

	useEffect(() => {
		setSelectedCampaigns(activeCampaigns);
	}, [activeCampaigns.length]);

	useEffect(() => {
		if (isExitModalOpen && !isOpen) {
			setIsExitModalOpen(false);
		}
	}, [isOpen, isExitModalOpen]);

	const handleSelectCampaign = (campaign: Campaign): void => {
		if (selectedCampaigns.includes(campaign)) {
			setSelectedCampaigns(selectedCampaigns.filter(item => item !== campaign));
		} else {
			setSelectedCampaigns([...selectedCampaigns, campaign]);
		}
	};

	const handleConfirmExit = (): void => {
		setSelectedCampaigns(activeCampaigns);
		setSearch("");
		onClose();
	};

	const handleSave = (): void => {
		saveCampaigns(selectedCampaigns.map(campaign => campaign.id));
		updateToast({
			type: "success",
			description: "Campaigns successfully applied!",
		});
		onClose();
	};

	const renderCampaigns = () => {
		if (isLoading) {
			return <Spinner className={styles.spinner}/>;
		}

		return <div className={styles.campaigns}>
			{filteredCampaigns?.map(campaign => (
				<CampaignCard
					key={campaign.id}
					campaign={campaign}
					isSelected={selectedCampaigns.includes(campaign)}
					onSelect={handleSelectCampaign}
				/>
			))}
		</div>
	}

	return (
		<>
			<Modal
				title="Choose Campaign"
				description="Select from your existing campaigns to apply insights and strategies
          to your current project.
          Browse through the list to find the campaign that best aligns with your objectives."
				isOpen={isOpen}
				onClose={onClose}
				className={styles.modal}
				headerClassName={styles.modalText}
				descriptionClassName={styles.modalText}
				portal
			>
				<Separator color="charcoal-elevation-400" className={styles.separator} />
				<SearchInput
					value={search}
					onChange={setSearch}
					size="small"
				/>

				{renderCampaigns()}
				<div className={styles.commitButtonsWrapper}>
					<Button
						className={styles.commitButton}
						variant="outlined"
						onClick={() => setIsExitModalOpen(true)}
					>
						Cancel
					</Button>

					<Button
						className={styles.commitButton}
						disabled={!selectedCampaigns.length || isUpdatingDisabled}
						onClick={handleSave}
					>
						{selectedCampaigns.length ? "Use selected" : "Choose campaign"}
					</Button>
				</div>
			</Modal>

			<ConfirmExitModal
				title="Confirm Exit"
				description="
        Any changes you've made to your campaign selections haven't been saved. Do you want to exit?
				"
				isOpen={isExitModalOpen}
				onClose={() => setIsExitModalOpen(false)}
				onConfirm={handleConfirmExit}
				portal
			/>
		</>
	);
};
