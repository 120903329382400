/* eslint-disable @typescript-eslint/no-explicit-any*/
import {ApolloCache, Reference, StoreObject} from "@apollo/client";
import {updateObject} from "./utility";

interface CachePage {
	items: (Reference | StoreObject)[];
	[key: string]: any;
}

interface ModifierDetails {
	readField: (fieldName: string, reference?: Reference | StoreObject) => any;
	storeFieldName: string;
}

type CacheModifierFn = (
	existing: any,
	details: ModifierDetails
) => any;

/**
 * Used to update a cache field. This one specifically handles
 * cache values that have the "items" field (Pages).
 * @param cache ApolloCache we are updating
 * @param ref The reference we want to use.
 * @param field The field we are updating in cache. IE: workspaceSurveys
 * @param id ID of the item we are adding, to see if it already exists.
 *
 * @returns Boolean on if operation was successful.
 */
export const updateCacheAddPageItem = (
	cache: ApolloCache<any>,
	ref: Reference | undefined,
	field: string,
	id: string,
): boolean => {
	return cache.modify({
		fields: {
			[field](existingValue: any = {items: []}, {readField}) {
				const existingPage = existingValue as CachePage;
				if (existingPage.items?.some(
					(reference) => readField("id", reference) === id
				)) {
					return existingPage;
				}
				if (!ref) return existingPage;
				return updateObject(existingPage, {
					items: [...existingPage.items, ref],
				});
			},
		},
	});
};

/**
 * Used to update the cache on a deleted item. Specifically for
 * cache values that have "items" field (Pages).
 * @param cache ApolloCache we are updating
 * @param field The field we are updating in the cache. IE: myReels
 * @param fieldName The individual field to delete. IE: on "myReels" it would be a "reel"
 * @param id ID of the item being deleted
 */
export const updateCacheDeletePageItem = (
	cache: ApolloCache<any>,
	field: string,
	fieldName: string,
	id: string,
	fieldToRead?: string,
): void => {
	cache.modify({
		fields: {
			[field](existingValue: any = {items: []}, {readField}) {
				const existingPage = existingValue as CachePage;
				const items = [...existingPage.items];
				const updatedItems = items.filter(
					ref => id !== readField(fieldToRead || "id", ref)
				);
				return updateObject(existingPage, {
					items: updatedItems,
				});
			},
		},
	});
	cache.evict({fieldName, args: {id}, broadcast: false});
	cache.gc();
};

/**
 * Very similar to deleting page item above, but takes a string to
 * make sure we are deleting from the correct cache item
 * @param cache ApolloCache we are updating
 * @param field The field we are updating in the cache. IE: myReels
 * @param fieldName The individual field to delete. IE: on "myReels" it would be a "reel"
 * @param fieldId A unique ID that helps identify if we are looking at the right field
 * @param id ID of the item being deleted
 */
export const updateCacheDeletePageItemByFieldId = (
	cache: ApolloCache<any>,
	field: string,
	fieldName: string,
	fieldId: string,
	id: string,
): void => {
	cache.modify({
		fields: {
			[field](existingValue: any = {items: []}, {readField, storeFieldName}) {
				const existingPage = existingValue as CachePage;
				if (storeFieldName.includes(fieldId)) {
					const items = [...existingPage.items];
					const updatedItems = items.filter(
						ref => id !== readField("id", ref)
					);
					return updateObject(existingPage, {
						items: updatedItems,
					});
				}
				return existingPage;
			},
		},
	});
	cache.evict({fieldName, args: {id}, broadcast: false});
	cache.gc();
};

/**
 * Used to update a cache field. This one specifically handles
 * cache values that have the "items" field (Pages), and checks
 * the field ID to make sure it is the right one.
 *
 * Right now this is only used to update using the duplicate clip, so we add it
 * below using the index value. (This isn't a super general operation, might want to
 * update it later)
 * @param cache ApolloCache we are updating
 * @param ref The reference we want to use.
 * @param field The field we are updating in cache. IE: workspaceSurveys
 * @param fieldId Unique ID of the field to make sure we have the right one.
 * @param id ID of the item we are adding, to see if it already exists.
 * @param index Used to determine where to place new item.
 *
 * @returns Boolean on if operation was successful.
 */
export const updateCacheAddItemByFieldId = (
	cache: ApolloCache<any>,
	field: string,
	ref: Reference | undefined,
	fieldId: string,
	id: string,
	index?: number,
): boolean => {
	return cache.modify({
		fields: {
			[field](existingValue: any = {items: []}, {readField, storeFieldName}) {
				const existingPage = existingValue as CachePage;
				if (storeFieldName.includes(fieldId)) {
					if (existingPage.items?.some(
						(reference) => readField("id", reference) === id
					)) {
						return existingPage;
					}
					if (!ref) return existingPage;
					const updatedItems = [...existingPage.items];
					updatedItems.splice(index ?? updatedItems.length, 0, ref);
					return updateObject(existingPage, {
						items: updatedItems,
					});
				}
				return existingPage;
			},
		},
	});
};

/**
 * A way to update a cache page items' order. Done generally after dragging
 * @param cache ApolloCache object
 * @param field Field we are changing
 * @param fieldId fieldID to look up, in case of multiple pages of the same type
 * @param dragIndex The drag index (where it came from)
 * @param dropIndex The drop index (where we are placing it)
 * @returns Boolean based on operation success.
 */
export const updateCachePageItemOrder = (
	cache: ApolloCache<any>,
	field: string,
	fieldId: string,
	dragIndex: number,
	dropIndex: number,
): boolean => {
	return cache.modify({
		fields: {
			[field](existingValue: any = {items: []}, {storeFieldName}) {
				const existingPage = existingValue as CachePage;
				if (storeFieldName.includes(fieldId)) {
					const items = [...existingPage.items];
					items.splice(dropIndex, 0, items.splice(dragIndex, 1)[0]);
					return updateObject(existingPage, {
						items,
					});
				}
				return existingPage;
			},
		},
	});
};
