/* eslint-disable @typescript-eslint/no-explicit-any*/
import React from "react";
import {Argument} from "classnames";

import {useLoadingQuery} from "../../../hooks";
import {roundify, displayTime} from "../../";
import {GET_STATS} from "../../../graphql/queries/survey-queries";

import styles from "./statsHeader.module.scss";
import {Card} from "../../layout/card";

export interface Card {
	title: string;
	dataType?: "number" | "time";
	value: (data: any) => number;
	element?: (data: any) => JSX.Element | null;
}

export interface StatsHeaderProps {
	className?: Argument;
	cards: Card[];
	variables?: any;
}

const StatsHeader = (props: StatsHeaderProps): React.ReactElement => {
	const {data, fragment} = useLoadingQuery(GET_STATS, {
		display: "horizontal",
		what: "Stats",
		variables: props.variables,
		loadingClassName: styles.container,
		fetchPolicy: "no-cache",
	});

	if (fragment) return fragment;

	return (
		<Card className={styles.container}>
			{props.cards.map((card, i) => (
				<div key={i} className={styles.card}>
					<div className={styles.unit}>
						{(card.dataType === "time" ? displayTime : roundify)(card.value(data))}
					</div>
					<div className={styles.title}>{card.title}</div>
					{card.element?.(data)}
				</div>
			))}
		</Card>
	);
};

export {StatsHeader};
