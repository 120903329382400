import React, {ReactElement} from 'react'
import classNames from 'classnames/bind'

import {useThemeMode} from '../../../context/theme-mode-context';

import styles from './connector.module.scss';
import {NoDraggableItem} from '../no-draggable-item';

const bStyles = classNames.bind(styles);

export interface ConnectorProps {
	disabled?: boolean;
}

export const Connector = ({disabled}: ConnectorProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	return <NoDraggableItem className={styles.noDraggableContainer}>
		<div className={bStyles("connector", {disabled, isDarkMode})}>
			<div className={bStyles("dot", {disabled, isDarkMode})}></div>
		</div>
	</NoDraggableItem>
}
