import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {PlusIcon} from "../../../icons";

import styles from "./workflow-canvas-button.module.scss";
import {useThemeMode} from "../../../context/theme-mode-context";
import {NoDraggableItem} from "../no-draggable-item";

const bStyles = classNames.bind(styles);

export interface WorkflowCanvasButtonProps {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const WorkflowCanvasButton = ({
  className,
  onClick,
  disabled,
}: WorkflowCanvasButtonProps): ReactElement => {
  const {isDarkMode} = useThemeMode();

  return <NoDraggableItem className={styles.noDraggableWrapper}>
    <div
      className={bStyles("circleWrapper", className, {disabled, isDarkMode})}
      tabIndex={0}
      onClick={onClick}
      >
      <div className={styles.innerCircle}>
        <PlusIcon className={styles.icon} />
      </div>
    </div>
  </NoDraggableItem>
};
