import React, {ReactElement} from "react";
import {useQuery} from "@apollo/client";

import {Button, LoadingContainer} from "../../../shared/v2";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {GET_CHAT_CONVERSATIONS_WITH_PREVIEW} from "../../../graphql/queries/ai-models-queries";
import {ChatConversation} from "../../../models/ai-model";
import {useNavigate} from "../../../route";
import {PlusSmallIcon} from "../../../icons";
import {CreateCard} from "../../../shared/v2/cards";
import {ConversationCard} from "./conversation-card";

import styles from "./conversations-page.module.scss";

// Set a high limit to ensure we get all conversations
const CONVERSATIONS_LIMIT = 1000;

export const ConversationsPage = (): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const navigate = useNavigate();


	const {
		data: {chatConversations = []} = {},
		loading,
	} = useQuery(GET_CHAT_CONVERSATIONS_WITH_PREVIEW, {
		// Use cache-first for initial load, then background refresh
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-and-network",
		pollInterval: 30000,
		variables: {
			workspaceId,
			filter: {isPreview: false},
			limit: CONVERSATIONS_LIMIT
		},
	});

	const startNewChat = (): void => {
		navigate("/workflow/conversation");
	};

	if (loading) return <LoadingContainer />;
	return <div className={styles.canvasList}>
		<CreateCard
			wrapperClassname={styles.cardWrapper}
		>
			<Button
				leftIcon={<PlusSmallIcon />}
				onClick={startNewChat}
			>
          Create new
			</Button>
		</CreateCard>

		{chatConversations.map((canvas: ChatConversation) => (
			<ConversationCard
				key={canvas.id}
				conversation={canvas}
			/>
		))}
	</div>;
};
