import React, {createContext, useContext, useState} from "react";

interface WorkflowModalsContextProps {
  isEditWorkflowModalOpen: boolean;
  setIsEditWorkflowModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectAgentModalOpen: boolean;
  setIsSelectAgentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSourcesModalOpen: boolean;
  setIsSourcesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkflowModalsContext = createContext<WorkflowModalsContextProps | undefined>(undefined);

export const WorkflowModalsProvider = ({ children }: { children: React.ReactNode }) => {
  const [isEditWorkflowModalOpen, setIsEditWorkflowModalOpen] = useState(false);
  const [isSelectAgentModalOpen, setIsSelectAgentModalOpen] = useState(false);
  const [isSourcesModalOpen, setIsSourcesModalOpen] = useState(false);

  return (
    <WorkflowModalsContext.Provider
      value={{
        isEditWorkflowModalOpen,
        setIsEditWorkflowModalOpen,
        isSelectAgentModalOpen,
        setIsSelectAgentModalOpen,
        isSourcesModalOpen,
        setIsSourcesModalOpen,
      }}
    >
      {children}
    </WorkflowModalsContext.Provider>
  );
};

export const useWorkflowModalsContext = () => {
  const context = useContext(WorkflowModalsContext);
  if (!context) {
    throw new Error("useWorkflowModalsContext must be used within a WorkflowModalsProvider");
  }
  return context;
};
