export function addBorderToBinaryMask(binaryMask: number[][]) {
  const width = binaryMask[0].length;
  const height = binaryMask.length;
  const newMask: number[][] = [];

  // Add a top border row
  newMask.push(new Array(width + 2).fill(0));

  // Add left and right borders to each row
  for (let y = 0; y < height; y++) {
    const newRow = [0, ...binaryMask[y], 0];
    newMask.push(newRow);
  }

  // Add a bottom border row
  newMask.push(new Array(width + 2).fill(0));

  return newMask;
}
