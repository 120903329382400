import React, {ReactElement, useCallback, useContext, useMemo} from "react";
import styles from "./review-card.module.scss";
import {Answer} from "models/answer";
import {VideoSwapper} from "../../../../../shared/components/video-swapper";
import {DropdownOptions, HeartIcon, Icon, Options} from "../../../../../shared";
import {TranscriptModalContext} from "../../../../../context/transcript-modal-context";
import {useLazyQuery, useMutation} from "@apollo/client";
import {GET_UPDATED_MP4} from "../../../../../graphql/queries/survey-queries";
import {LIKE_ANSWER, UNLIKE_ANSWER} from "../../../../../graphql/mutations/survey-mutations";
import {InteractionCounter} from "../../../../../survey/components/interaction-counter";
import {Body} from "../../../../../shared/v2/typography";
import {useThemeMode} from "../../../../../context/theme-mode-context";
import classNames from "classnames/bind";

const bStyles = classNames.bind(styles);

export interface ReviewCardProps {
	answer: Answer;
	handleOpen: (answerId: string, description?: string) => void;
}

const ReviewCard = (props: ReviewCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {answer, handleOpen} = props;
	const {setAnswerId} = useContext(TranscriptModalContext);

	const [likeAnswer] = useMutation(LIKE_ANSWER);
	const [unlikeAnswer] = useMutation(UNLIKE_ANSWER);

	const handleLike = (): void => {
		likeAnswer({variables: {answerId: answer.id}});
	};

	const handleUnlike = (): void => {
		unlikeAnswer({variables: {answerId: answer.id}});
	};

	const [downloadVideoLink] = useLazyQuery(GET_UPDATED_MP4, {
		fetchPolicy: "network-only",
		onCompleted: (result) => {
			const link = document.createElement("a");
			link.href = result.answer.video.mp4;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
	});

	const handleDownload = (): void => {
		downloadVideoLink({
			variables: {id: answer.id},
		});
	};

	const getOptions = useCallback((): DropdownOptions[] => (
		[
			{
				name: "Download",
				actionOptions: {onClick: handleDownload},
				icon: "download",
				iconFill: "var(--color-text-body)",
			},
			{
				name: "View",
				icon: "eye",
				iconFill: "var(--color-text-body)",
				actionOptions: {onClick: () => setAnswerId(answer.id)},
			},
		]
	), []);

	const handleOpenCreateReel = (): void => handleOpen(answer.id, answer.text);

	const heartIcon = useMemo(() => {
		return <HeartIcon
			isLiked={Boolean(answer.likedAt)}
			like={handleLike}
			unlike={handleUnlike}
		/>;
	}, [answer.likedAt]);

	return (
		<div className={styles.container}>
			<div className={styles.vidAndName}>
				<VideoSwapper answerId={answer.id} video={answer.video} className={styles.video}/>
				<div className={styles.nameContainer}>

					<Body className={styles.name} onClick={() => setAnswerId(answer.id)}>
						{answer.user.firstName} {answer.user.lastInitial}
					</Body>
				</div>
			</div>
			<div className={bStyles("actions", {isDarkMode})}>
				{answer.reviewed ? <Icon name="green-checkmark" /> : <Icon name="grey-checkmark" />}
				<Icon
					size="extrasmall"
					name="play-plus"
					clicked={handleOpenCreateReel}
					className={styles.icon}
				/>
				<InteractionCounter
					count={answer.likeCount}
					icon={heartIcon}
					reverse
					className={styles.interaction}
				/>
				<Options
					type="menu-vertical"
					position="left"
					options={getOptions()}
				/>
			</div>
		</div>
	);
};

export {ReviewCard};
