import {Group, Rect} from "react-konva";
import {GroupConfig} from "konva/lib/Group";
import Konva from "konva";
import React, {ReactElement} from "react";

import {MemoizedLine} from "../memoized-line";
import {useCanvasContext} from "../../../../contexts/canvas-area-selection";

export const SelectedArea = ({...props}: GroupConfig): ReactElement => {
  const ref = React.useRef<Konva.Group>(null);
  const {lines} = useCanvasContext();

  React.useEffect(() => {
    if (!ref.current) {
      return
    }

    const size = (ref.current.getStage() as Konva.Stage).getSize();

    if (size.width === 0 && size.height === 0) {
      return
    }

    ref.current.cache();
  }, [lines, ref.current]);

  return (
    <Group opacity={0.5} ref={ref} {...props}>
      <Rect width={1} height={1} opacity={0} fill="transparent" />
      {lines.map((line, index) => (
        <MemoizedLine key={index} line={line} />
      ))}
    </Group>
  );
}
