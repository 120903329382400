import {KonvaEventObject} from "konva/lib/Node";
import React, {ReactElement, ReactNode, createContext, useCallback, useEffect, useState, useMemo} from "react";

type KonvaMouseEvent = (e: KonvaEventObject<MouseEvent>) => void;

type KonvaMouseEventType = "onMouseDown" | "onMouseMove" | "onMouseUp" | "onMouseLeave" | "onMouseEnter";

export interface CanvasMouseEventMiddlewareContextValue {
  mouseEvents: Record<KonvaMouseEventType, KonvaMouseEvent>;
  registerMiddleware: (middleware: Middleware) => void;
}

export const CanvasMouseEventMiddlewareContext =
  createContext<CanvasMouseEventMiddlewareContextValue | undefined>(undefined);

interface Middleware {
  name: string;
  order?: number;
  event: KonvaMouseEventType;
  callback: (e: KonvaEventObject<MouseEvent>) => KonvaEventObject<MouseEvent> | void;
}

export const CanvasMouseEventMiddlewareContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const [middlewares, setMiddlewares] = useState<Middleware[]>([]);

  // Function to create event listener for a specific event type
  const createListener = (event: KonvaMouseEventType) => {
    return (e: KonvaEventObject<MouseEvent>) => {
      // Iterate through middlewares and invoke the callback for matching event type
      for (const {callback} of middlewares.filter((m) => m.event === event)) {
        callback(e);
      }
    }
  };

  const handleMouseDown = useMemo(() => createListener("onMouseDown"), [middlewares]);
  const handleMouseEnter = useMemo(() => createListener("onMouseEnter"), [middlewares]);
  const handleMouseLeave = useMemo(() => createListener("onMouseLeave"), [middlewares]);
  const handleMouseMove = useMemo(() => createListener("onMouseMove"), [middlewares]);
  const handleMouseUp = useMemo(() => createListener("onMouseUp"), [middlewares]);

  const registerMiddleware = (middleware: Middleware) => {
    setMiddlewares((prev) => {
      // Check if middleware with the same name and event already exists
      const hasMiddleware = prev.some(
        (m) => m.name === middleware.name && m.event === middleware.event,
      );

      // Update existing middleware or add new middleware
      const updatedMiddlewares = hasMiddleware ? prev.map(m => {
        if (m.name === middleware.name && m.event === middleware.event) {
          return middleware;
        }

        return m;
      }) : [...prev, middleware];

      // Sort middlewares based on order (if provided)
      return updatedMiddlewares.sort((a, b) => (b.order ?? 0) - (a.order ?? 0));
    });

    // Return a cleanup function to remove the middleware
    return () => {
      setMiddlewares(prev => prev.filter(
        (m) => m.name !== middleware.name || m.event !== middleware.event,
      ));
    }
  }

	return (
		<CanvasMouseEventMiddlewareContext.Provider value={{
      mouseEvents: {
        onMouseDown: handleMouseDown,
        onMouseMove: handleMouseMove,
        onMouseUp: handleMouseUp,
        onMouseLeave: handleMouseLeave,
        onMouseEnter: handleMouseEnter,
      },
			registerMiddleware,
		}}>
			{children}
		</CanvasMouseEventMiddlewareContext.Provider>
	);
};

export const useCanvasMouseEventMiddlewareContext = (): CanvasMouseEventMiddlewareContextValue => {
	const context = React.useContext(CanvasMouseEventMiddlewareContext);

	if (context === undefined) {
		throw new Error(
			"useCanvasMouseEventMiddlewareContext must be used within a CanvasMouseEventMiddlewareContextProvider",
		);
	}

	return context;
};

interface MiddlewareOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps?: any[];
  event: KonvaMouseEventType;
  name: string;
  order?: number;
}

export function useCanvasMouseEventMiddleware(fn: KonvaMouseEvent, opts: MiddlewareOptions): void {
	const {registerMiddleware} = useCanvasMouseEventMiddlewareContext();

	const callback: KonvaMouseEvent = useCallback((e) => {
		return fn(e);
	}, opts.deps ?? []);
	useEffect(() => registerMiddleware({...opts, callback}), [callback]);
}
