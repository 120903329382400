import React, {ReactElement} from "react";
import styles from "./filter-display.module.scss";
import {Icon} from "../../../shared";
import {BrandFilter} from "../../../models/brand";

export interface FilterDisplayProps {
	filter: BrandFilter;
	handleClearFilters: () => void;
	handleRemoveFilter: (clearKey, clearValue?) => void;
}

/**
 * Looks at the current search query and displays
 * the filters of the page. (Will ignore workspaceId when implemented)
 */
const FilterDisplay = (
	props: FilterDisplayProps,
): ReactElement | null => {
	const {filter, handleClearFilters, handleRemoveFilter} = props;

	const {
		chatbotEnabled,
		workflowEnabled,
		allowAnonymous,
		aiInsightsEnabled,
		enableOfflineAssist,
		plan,
		visible,
		isVerified, // Comes from the workspaces/brands filter.
	} = filter;


	if (
		!Object.keys(filter)
			.filter(x => !x.includes("ame")) // don't show up for name && brandName
			.some(
				value => filter[value] !== undefined,
			)
	) return null;

	return (
		<ul className={styles.headerFilters}>
			{plan?.map((p, index) => (
				<li key={`${index}-${p}`} className={styles.filterItem}>
					<b>Plan:</b>&nbsp;{p.charAt(0) + p.slice(1).toLocaleLowerCase()}
					<Icon
						clicked={() => handleRemoveFilter("plan", p)}
						name="cross"
						size="tiny"
						isClickable
					/>
				</li>
			))}
			{allowAnonymous === undefined ? (
				<></>
			) : (
				<li className={styles.filterItem}>
					<b>Allow Anonymous:</b>&nbsp;{allowAnonymous ? "On" : "Off"}
					<Icon
						clicked={() => handleRemoveFilter("allowAnonymous")}
						name="cross"
						size="tiny"
						isClickable
					/>
				</li>
			)}
			{aiInsightsEnabled === undefined ? (
				<></>
			) : (
				<li className={styles.filterItem}>
					<b>AI Insights:</b>&nbsp;{aiInsightsEnabled ? "On" : "Off"}
					<Icon
						clicked={() => handleRemoveFilter("aiInsightsEnabled")}
						name="cross"
						size="tiny"
						isClickable
					/>
				</li>
			)}
			{chatbotEnabled === undefined ? (
				<></>
			) : (
				<li className={styles.filterItem}>
					<b>Enable Chatbot:</b>&nbsp;{chatbotEnabled ? "On" : "Off"}
					<Icon
						clicked={() => handleRemoveFilter("chatbotEnabled")}
						name="cross"
						size="tiny"
						isClickable
					/>
				</li>
			)}

			{workflowEnabled === undefined ? (
				<></>
			) : (
				<li className={styles.filterItem}>
					<b>Enable Workflow:</b>&nbsp;{workflowEnabled ? "On" : "Off"}
					<Icon
						clicked={() => handleRemoveFilter("workflowEnabled")}
						name="cross"
						size="tiny"
						isClickable
					/>
				</li>
			)}

			{enableOfflineAssist === undefined ? (
				<></>
			) : (
				<li className={styles.filterItem}>
					<b>Offline Assist:</b>&nbsp;{enableOfflineAssist ? "On" : "Off"}
					<Icon
						clicked={() => handleRemoveFilter("enableOfflineAssist")}
						name="cross"
						size="tiny"
						isClickable
					/>
				</li>
			)}
			{visible === undefined ? (
				<></>
			) : (
				<li className={styles.filterItem}>
					<b>Visible:</b>&nbsp;{visible ? "Yes" : "No"}
					<Icon
						clicked={() => handleRemoveFilter("visible")}
						name="cross"
						size="tiny"
						isClickable
					/>
				</li>
			)}
			{isVerified === undefined ? (
				<></>
			) : (
				<li className={styles.filterItem}>
					<b>Verified:</b>&nbsp;{isVerified ? "Yes" : "No"}
					<Icon
						clicked={() => handleRemoveFilter("isVerified")}
						name="cross"
						size="tiny"
						isClickable
					/>
				</li>
			)}
			<div className={styles.crossTag}>
				<span onClick={handleClearFilters}>Clear filters</span>
			</div>
		</ul>
	);
};

export {FilterDisplay};
