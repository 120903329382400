import {LineConfig} from "konva/lib/shapes/Line";

export function createImageOfLines(lines: LineConfig[], width: number, height: number): ImageData {
  const offScreenCanvas = document.createElement('canvas');
  offScreenCanvas.width = width;
  offScreenCanvas.height = height;
  const ctx = offScreenCanvas.getContext('2d');

  if (!ctx) {
    throw new Error('Could not get 2d context');
  }

  ctx.clearRect(0, 0, width, height);
  // Loop through lines and draw them
  lines.forEach((line) => {
    const points = line.points as number[];
    ctx.beginPath();
    ctx.moveTo(points[0], points[1]);
    for (let i = 2; i < points.length; i += 2) {
      ctx.lineTo(points[i], points[i + 1]);
    }
    ctx.strokeStyle = 'black';
    ctx.lineWidth = line.strokeWidth as number;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.globalCompositeOperation = line.globalCompositeOperation || 'source-over';
    ctx.stroke();
  });

  return ctx.getImageData(0, 0, width, height);
}
