import React, {ReactElement, useContext, useState} from "react";
import {useMutation, useQuery, useLazyQuery} from "@apollo/client";

import {COPY_CLIP, MOVE_CLIP} from "../../../graphql/mutations/clip-mutations";
import {
	Clip,
	ClipCopyVars,
	ClipMoveData,
	ClipMoveVars,
	ClipPageData,
	CopyClipData,
} from "../../../models/clip";
import {
	CopiedReel,
	CopyReelVars,
	DeleteReelVars,
	DeletedReel,
	ReelData,
	ReelVideoStatus,
	DownloadableReelVideoData,
} from "../../../models/reels";
import {
	DELETE_REEL,
	DUPLICATE_REEL,
	PUBLISH_REEL,
} from "../../../graphql/mutations/reel-mutations";
import {
	GET_REEL_AND_CLIPS,
	GET_REEL_DOWNLOAD_URL,
} from "../../../graphql/queries/reel-queries";
import {
	updateCacheAddItemByFieldId,
	updateCachePageItemOrder,
} from "../../../shared/utility/update-cache";
import {CLIP_FRAGMENT} from "../../../graphql/fragments/fragments";
import {ClipMetadata} from "../../components/clip-metadata";
import {ClipEditorModal} from "../../../modals/clip-editor";
import {ClipPreview} from "../../components/clip-preview";
import {ClipsEmpty} from "../../components/clips-empty";
import {ClipsList} from "../../components/clips-list";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {LayoutContainer} from "../../../shared";
import {ReelActions} from "../../components/reel-actions";
import {ReelContextProvider} from "../../../context/reel-context";
import {ShareScreen} from "../../components/share-screen";
import {ToastContext} from "../../../context/toast-context";
import {useWaitForReelPublish} from "../../../hooks/useWaitForReelPublish";
import {PublishStatus} from "../../components/publish-status";
import {useNavigate, useParams} from "../../../route";

import styles from "./reel.module.scss";
import {useMount} from "../../../hooks/useMount";
import {SearchModal} from "../../modals/search-modal";
import {ActionsButton} from "../../../contacts/components/actions-button";
import {MediaLibraryModal} from "../../modals/media-lib";
import {SelectContextProvider} from "../../../context/select-context";
import {UploadVideoModal} from "../../modals/upload-video";
import {PreviewModal} from "../../modals/preview-modal";


/**
 * A singular reel page. It will return the reel name and description, as well
 * as all of the clips currently available on the given reel.
 *
 * Lot of components in here will be using the ReelContext, so
 * there will not be a lot of passing props aside from functions
 * and some other info we don't want to store inside of the context.
 *
 * There will be many mutations in this, so gonna try to separate things a bit more
 * than usual.
 */
const ReelPage = (): ReactElement => {
	const navigate = useNavigate();
	const {reelId} = useParams<{reelId: string}>();
	const {updateToast} = useContext(ToastContext);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [clipId, setClipId] = useState("");
	const [showUpload, setShowUpload] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [showMedia, setShowMedia] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const {data, loading, error, refetch} = useQuery<ReelData & ClipPageData>(GET_REEL_AND_CLIPS, {
		variables: {reelId},
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
		errorPolicy: "all",
	});

	const [loadDownloadUrl] = useLazyQuery<DownloadableReelVideoData>(GET_REEL_DOWNLOAD_URL, {
		variables: {reelId},
	});

	const {loading: reelLoading, error: reelError} =
	useWaitForReelPublish(reelId, data?.reel.videoStatus);
	/** ******************************
	 * Mutations start below
	 *********************************/
	const [deleteReel] = useMutation<DeletedReel, DeleteReelVars>(DELETE_REEL, {
		onCompleted: () => {
			updateToast({description: "Reel deleted", type: "informational"});
			navigate("/campaigns/magic-reels", {search: true});
		},
	});
	const [copyReel] = useMutation<CopiedReel, CopyReelVars>(DUPLICATE_REEL, {
		onCompleted: ({duplicateReel}) => {
			updateToast({description: "Reel duplicated", type: "informational"});
			navigate(`/reel/${duplicateReel.id}`, {search: true});
		},
		onError: () => {
			updateToast({
				description: "Copy failed. Please check your connection and try again",
				type: "failure",
			});
		},
	});

	const [copyClip] = useMutation<CopyClipData, ClipCopyVars>(COPY_CLIP, {
		onCompleted: () => updateToast({description: "Clip copied", type: "informational"}),
	});
	const [moveClip] = useMutation<ClipMoveData, ClipMoveVars>(MOVE_CLIP);
	const [publishReel] = useMutation(PUBLISH_REEL);
	/** ******************************
	 * End of Mutations
	 *********************************/

	// Just used to update the page title.
	useMount(() => {
		document.title = "Reel Editor - Vurvey";
	});

	const toggleSearch = (): void => setShowSearch(prev => !prev);
	const toggleMedia = (): void => setShowMedia(prev => !prev);
	const toggleUpload = (): void => setShowUpload(prev => !prev);
	const togglePreview = (): void => setShowPreview(prev => !prev);

	/**
	 * Copies the given Clip by ID.
	 * Might want to look into abstracting a little.
	 * @param id ID of the clip
	 */
	const handleDuplicateClip = (id: string): void => {
		copyClip({
			variables: {id},
			update(cache, {data: copyData}) {
				if (copyData) {
					const clipDuration =
					copyData.duplicateClip.endTime - copyData.duplicateClip.startTime;
					const clipRef = cache.writeFragment({
						data: copyData.duplicateClip,
						fragment: CLIP_FRAGMENT,
					});
					updateCacheAddItemByFieldId(
						cache,
						"clips",
						clipRef,
						reelId,
						copyData.duplicateClip.id,
						copyData.duplicateClip.index,
					);
					cache.modify({
						id: `Reel:${reelId}`,
						fields: {
							duration(currentDuration: number) {
								return currentDuration + clipDuration;
							},
						},
					});
				}
			},
		});
	};

	/**
	 * Handles moving a clip to a new spot.
	 * @param clip Clip we are moving
	 * @param dragIndex Where it came from
	 * @param dropIndex Where it's going
	 */
	const handleMoveClip = (clip: Clip, dragIndex: number, dropIndex: number): void => {
		moveClip({
			variables: {id: clip.id, index: dropIndex},
			optimisticResponse: {
				moveClip: {
					...clip,
					index: dropIndex,
				},
			},
			update(cache) {
				updateCachePageItemOrder(cache, "clips", reelId, dragIndex, dropIndex);
			},
		});
	};
	/**
	 * Toggles delete modal on this page.
	 */
	const handleToggleDeleteModal = (): void => {
		setShowDeleteModal(prev => !prev);
	};

	/**
	 * Handles when user confirms deletion of the reel.
	 */
	const handleConfirmDelete = (): void => {
		setShowDeleteModal(prev => !prev);
		deleteReel({
			variables: {id: reelId},
		});
	};

	/**
	 * Copies the reel. When completed, we navigate to the copied reel.
	 */
	const handleCopyReel = (): void => {
		copyReel({
			variables: {id: reelId},
		});
	};

	/**
	 * Calls publish reel mutation.
	 * We want to make sure the status isn't in the "PENDING" status before we
	 * call the mutation to publish.
	 */
	const handlePublish = async(): Promise<void> => {
		await refetch();
		if (data?.reel.videoStatus !== ReelVideoStatus.PENDING) {
			publishReel({
				variables: {id: reelId},
			});
		}
	};

	const downloadReelCallback = async(): Promise<void> => {
		const {data: reelData} = await loadDownloadUrl();

		if (reelData?.reel.video.url) {
			const downloadElement = document.createElement("a");
			downloadElement.href = reelData?.reel.video.url;
			downloadElement.click();
		}
	};

	if (loading) return <div/>;
	if (data) {
		return (
			<ReelContextProvider
				reelId={reelId}
				duplicateClipCallback={handleDuplicateClip}
				updateToast={updateToast}
				setClipId={setClipId}
			>
				<div className={styles.grid}>
					<ReelActions
						handleToggleDelete={handleToggleDeleteModal}
						hasClips={data.clips.items.length > 0}
						name={data.reel.name}
						duplicateReelCallback={handleCopyReel}
						reelError={reelError}
						publishStatus={<PublishStatus
							reelLoading={reelLoading}
							reelStatus={data.reel.videoStatus}
						/>}
						publishCallback={handlePublish}
						downloadReelCallback={downloadReelCallback}
						reelLoading={reelLoading}
						sharedInBrand={data.reel.sharedInBrand}
						 
						isDownloadable={data.reel.videoStatus === ReelVideoStatus.CREATED && !reelLoading}
					/>
					<section className={styles.left}>
						<ClipPreview clipsData={data.clips} />
						<ClipMetadata reel={data.reel} togglePreview={togglePreview}/>
					</section>
					<section className={styles.clips}>
						{
							data.clips.items.length > 0 ? (
								<ClipsList
									moveClipCallback={handleMoveClip}
									clipsList={data.clips.items}
								/>
							) : (
								<ClipsEmpty />
							)
						}
						<ActionsButton
							text="Add Video"
							className={styles.add}
							options={[
								{
									name: "Upload Video",
									icon: "upload",
									actionOptions: {
										isLink: false,
										onClick: toggleUpload,
									},
								},
								{
									name: "Search Videos",
									icon: "search",
									actionOptions: {
										isLink: false,
										onClick: toggleSearch,
									},
								},
								{
									name: "From Media Library",
									icon: "video-slate",
									actionOptions: {
										isLink: false,
										onClick: toggleMedia,
									},
								},
							]}
						/>
					</section>
				</div>
				<DeleteConfirmModal
					isOpen={showDeleteModal}
					onClose={handleToggleDeleteModal}
					handleConfirm={handleConfirmDelete}
					warningText="Are you sure you want to delete this reel?"
				/>
				<ShareScreen
					createdAt={data.reel.createdAt}
					reelId={reelId}
					reelName={data.reel.name}
					reelCreator={data.reel.creator?.firstName}
				/>
				<ClipEditorModal
					isOpen={!!clipId}
					onClose={() => setClipId("")}
					clipId={clipId}
				/>
				<PreviewModal
					isOpen={showPreview}
					onClose={togglePreview}
					clips={data.clips.items}
				/>
				<UploadVideoModal isOpen={showUpload} onClose={toggleUpload}/>
				<SelectContextProvider>
					<SearchModal isOpen={showSearch} onClose={toggleSearch}/>
					<MediaLibraryModal isOpen={showMedia} onClose={toggleMedia} />
				</SelectContextProvider>
			</ReelContextProvider>
		);
	}
	return <LayoutContainer>An error occurred {error?.message}</LayoutContainer>;
};

export {ReelPage};
