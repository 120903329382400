import React, {ReactElement, useMemo, useState} from "react";
import classNames from "classnames/bind";

import {ChatMessageGroundingEntry} from "../../../../models/ai-model";
import {GroundingEntry} from "./grounding-entry";
import {Link} from "../../../../hoc/layout/navigation/link";
import {Body, Separator, Tooltip} from "../../../../shared/v2";
import {SizeTransition} from "../../../../shared/v2/size-transition";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {AssistiveChip} from "../../../../shared/v2/assistive-chip";
import {InformationCicrcleIcon} from "../../../../icons";

import styles from "./grounding-section.module.scss";

const bStyles = classNames.bind(styles);

const groundingHelpLink = "https://help.vurvey.com/en/articles/9491122-understanding-vurvey-s-grounding-system";

export interface GroundingSectionProps {
  grounding: ChatMessageGroundingEntry[];
}

export const GroundingSection = ({grounding}: GroundingSectionProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [isGroundingVisible, setIsGroundingVisible] = useState(false);

	const sortedGrounding = useMemo(
		() => [...grounding].sort((a, b) => b.score - a.score),
		[grounding]
	);

	return (
		<div className={styles.groundingWrapper}>
			<div className={styles.poweredByLink}>
				<Tooltip
					placement="top"
					interactive
					content={
						<Body size="xs">
              Understand &apos;Powered by&apos;.&nbsp;
							<a
								className={bStyles("tooltipLink", {isDarkMode})}
								target="_blank"
								rel="noreferrer"
								href={groundingHelpLink}
							>
                Click for details.
							</a>
						</Body>
					}
				>
					<InformationCicrcleIcon className={bStyles("poweredByIcon", {isDarkMode})} />
				</Tooltip>
				<Link
					isExpanded={isGroundingVisible}
					type="expand"
					onClick={() => setIsGroundingVisible(!isGroundingVisible)}
				>
          Powered by
				</Link>
			</div>

			<SizeTransition duration={0.08} dimensions="height">
				{isGroundingVisible && (
					<div className={styles.groundingSection}>
						<Separator
							color={isDarkMode ? "charcoal-elevation-400" : "gray-modern-300"}
							className={styles.groundingSeparator}
						/>
						{sortedGrounding.map((entry, index) => (
							<GroundingEntry key={index} position={index + 1} entry={entry} />
						))}

						<AssistiveChip
							text={
								<a
									className={bStyles("readMoreLink", {isDarkMode})}
									target="_blank"
									rel="noreferrer"
									href={groundingHelpLink}
								>
									<Body size="xs" type="medium">See how this works</Body>
								</a>
							}
							className={bStyles("assistiveChip", {isDarkMode})}
						/>
					</div>
				)}
			</SizeTransition>
		</div>
	);
}
