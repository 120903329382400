/* eslint-disable @typescript-eslint/no-explicit-any*/

import React, {ReactElement} from 'react';
import {useQuery} from '@apollo/client';

import {ActivityCharts} from '../../components/activity-charts';
import {WorkspaceHistoryReturn} from '../../../models/workspace';
import {GET_WORKSPACE_STATS} from '../../../graphql/queries/survey-queries';
import {useWorkspaceContext} from '../../../context/workspace-context';
import {useUserContext} from '../../../context/user-context';
import {LoadingContainer} from '../../../shared/v2';

export const UsagePage = (): ReactElement => {
	const {workspace} = useWorkspaceContext();
	const workspaceId = workspace?.id;
	const {user} = useUserContext();

	const {data: {workspaceHistory} = {}, loading} = useQuery<WorkspaceHistoryReturn>(GET_WORKSPACE_STATS, {
		variables: {workspaceId},
		skip: !workspaceId,
		onCompleted: data => {
			if (user.role === "REGULAR_USER") {
				if (data.workspaceHistory && workspace) {
					const lastMonthAnswerDuration = data.workspaceHistory.answerDuration?.lastMonth;
					const thisMonthAnswerDuration = data.workspaceHistory.answerDuration?.thisMonth;
					const olderAnswerDuration = data.workspaceHistory.answerDuration?.older;
					(window as any).Vitally.account({
						accountId: workspaceId,
						traits: {
							name: workspace.name, // A 'name' trait is required
							answerDurationLastMonth: (lastMonthAnswerDuration) ? (lastMonthAnswerDuration / 60000) : 0,
							answerDurationThisMonth: (thisMonthAnswerDuration.reduce((acc, x) => acc + Number(x), 0)) ? (thisMonthAnswerDuration.reduce((acc, x) => acc + Number(x), 0) / 60000) : 0,
							answerDurationOlderThanLastMonth:
								olderAnswerDuration ? (olderAnswerDuration / 60000) : 0,
							creatorCountLastMonth: data.workspaceHistory.contactCount?.lastMonth,
							creatorCountThisMonth: data.workspaceHistory.contactCount?.thisMonth.reduce((acc, x) => acc + Number(x), 0),
							creatorCountOlderThanLastMonth: data.workspaceHistory?.contactCount?.older,
							answerCountLastMonth: data.workspaceHistory.answerCount?.lastMonth,
							answerCountThisMonth: data.workspaceHistory.answerCount?.thisMonth.reduce((acc, x) => acc + Number(x), 0),
							answerCountOlderThanLastMonth: data.workspaceHistory.answerCount?.older,
						},
					});
					(window as any).Vitally.user({
						userId: user.id, // A unique ID assigned by your codebase to each user (i.e. a database ID)
						accountId: workspaceId, // Same account ID used in Vitally.account above - used to associate the user to the account
						traits: {
							name: `${user.firstName} ${user.lastName}`,
							email: user.email,
							workspaceRole: user.workspaceRole,
						},
					});
					(window as any).Vitally.nps("survey", {
						productName: "Vurvey",
						npsQuestion: "Hi, how would rate your experience with Vurvey today? Are you enjoying it?",
						delay: 1000,
					});
				}
			}
			return data;
		},
	});

	if (loading) {
		return <LoadingContainer />;
	}

	if (!workspaceHistory) {
		return <LoadingContainer />;
	}

	return <ActivityCharts data={workspaceHistory} />;
}
