import React, {ReactElement} from "react";
import styles from "./date-range.module.scss";
import {DateRange} from "../../../models/filter";
import {DateSetter} from "../../../hooks/useDateRange";
import {getCurrentDate} from "../../../shared/utility/utility";

/**
 * Maybe can eventually move this to the "Inputs" folder,
 * but it's kind of unique in having two inputs.
 */
export interface DateRangeProps {
	id: string;
	label?: string;
	value: DateRange;
	onChange: DateSetter;
}

const DateRangeInput = ({id, value, label, onChange}: DateRangeProps): ReactElement => (
	<div className={styles.container} id={id}>
		{label && <span className={styles.label}>{label}</span>}
		<div className={styles.inputContainer}>
			<input
				className={styles.input}
				id={`${id}-start`}
				type="date"
				value={value.start}
				max={value.end || getCurrentDate()}
				onChange={e => onChange(e.target.value, "start")}
			/>
			<span>to</span>
			<input
				id={`${id}-end`}
				type="date"
				className={styles.input}
				disabled={!value.start}
				value={value.end}
				min={value.start}
				max={getCurrentDate()}
				onChange={e => onChange(e.target.value, "end")}
			/>
		</div>
	</div>
);

export {DateRangeInput};
