import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {GET_FUNDING_AND_CAMPAIGNS, GET_REWARD_SETTINGS} from "../../graphql/queries/reward-queries";
import {
	SET_TREMENDOUS_API,
	UPDATE_TREMENDOUS_SETTINGS,
} from "../../graphql/mutations/reward-mutations";
import {Option, Select} from "../../shared";
import {useMutation, useQuery} from "@apollo/client";
import styles from "./tremendous.module.scss";
import {
	ParsedRewardConfig,
	SetTremendousKeyInput,
	SetTremendousKeyReturn,
	UpdateTremendousSettingsInput,
	UpdateTremendousSettingsReturn,
	WorkspaceFundingAndCampaignReturn,
	WorkspaceRewardSettingReturn,
} from "../../models/rewards";
import {useWorkspaceContext} from "../../context/workspace-context";
import {ToastContext} from "../../context/toast-context";
import {useLoadingQuery} from "../../hooks";
import {convertToDollars} from "../../shared/utility/utility";
import {BaseModalProps, Body, Button, Input, Modal} from "../../shared/v2";

const TremendousIntegrateModal = ({isOpen, onClose}: BaseModalProps): ReactElement => {
	const {workspace: {id}} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);
	const [text, setText] = useState("");
	const [step, setStep] = useState(0);
	const [campSource, setCampSource] = useState("");
	const [fundSource, setFundSource] = useState("");

	const {data, fragment} = useLoadingQuery<WorkspaceFundingAndCampaignReturn>(GET_FUNDING_AND_CAMPAIGNS, {
		skip: step !== 1 || !isOpen,
		variables: {id},
		what: "campaigns",
		fetchPolicy: "no-cache",
	});
	const {data: settings} = useQuery<WorkspaceRewardSettingReturn>(GET_REWARD_SETTINGS, {
		variables: {workspaceId: id},
	});

	const [setApiKey, {loading}] =
	useMutation<SetTremendousKeyReturn, SetTremendousKeyInput>(SET_TREMENDOUS_API);
	const [updateSettings, {loading: loadingUpdate}] =
	 
	useMutation<UpdateTremendousSettingsReturn, UpdateTremendousSettingsInput>(UPDATE_TREMENDOUS_SETTINGS);

	const increaseStep = (): void => {
		if (step !== 1) {
			setStep(prev => prev + 1);
			return;
		}
		updateSettings({
			variables: {
				workspaceId: id,
				enable: true,
				defaultCampaignId: campSource,
				defaultFundingId: fundSource,
			},
			onCompleted: () => {
				updateToast({description: "Updated Tremendous settings", type: "success"});
				onClose();
			},
			// Will try to find a better way to do this so the cache updates and recognizes settings
			refetchQueries: [{
				query: GET_REWARD_SETTINGS,
				variables: {workspaceId: id},
			}],
		});
	};
	const handleApiKey = (): void => {
		setApiKey({
			variables: {apiKey: text, workspaceId: id},
			onCompleted: increaseStep,
		});
	};

	const mappedCampaigns: Option[] | undefined = data?.workspace.tremendousCampaigns.map(
		source => ({text: source.name, value: source.id}),
	);

	const mappedFunds: Option[] | undefined = data?.workspace.tremendousFundingSources.map(
		source => ({
			value: source.id,
			 
			text: `${source.method}: $${convertToDollars(JSON.parse(source.meta as string).available_cents)}`,
		}),
	);


	useEffect(() => {
		if (isOpen) {
			// figure out what step we should be on based on settings
			if (!settings || settings.workspaceRewardSettings.length === 0) return;
			// This check is okay for now since we can only have one value in the settings
			const parsed: ParsedRewardConfig =
			JSON.parse(settings.workspaceRewardSettings[0].configuration);
			if (parsed.apiKeySet) {
				setStep(1);
				return;
			}
			setStep(0);
		}
		if (!isOpen) {
			setText("");
			setCampSource("");
			setFundSource("");
		}
	}, [isOpen]);

	// If we have defaults based on the settings we start with them selected.
	useEffect(() => {
		if (!settings || settings.workspaceRewardSettings.length === 0) return;
		const parsed: ParsedRewardConfig =
		JSON.parse(settings.workspaceRewardSettings[0].configuration);
		setCampSource(parsed.defaultCampaignId || "");
		setFundSource(parsed.defaultFundingId || "");
	}, [settings, isOpen]);

	const buttonDisabled = useMemo(() => {
		if (step === 0 && !text) return true;
		if (step === 1 && !campSource && !fundSource) return true;
		if (loading || loadingUpdate) return true;
		return false;
	}, [step, text, campSource, fundSource, loading, loadingUpdate]);

	return <Modal
		title="Integrate with Tremendous"
		isOpen={isOpen}
		onClose={onClose}
		size="medium"
	>
		<div className={styles.text}>
			<img src="/images/tremendous-icon.png" className={styles.icon}/>
			{step === 0 ? (
				<>
					<Body className={styles.text}>Vurvey Integrates with Tremendous to reward your creators.
						To start, create a <a
						target="_blank"
						rel="noreferrer"
						href="https://www.tremendous.com/"
					>Tremendous account</a> and paste your API key below.</Body>
					<Input value={text} onChange={setText}/>
				</>
			) : (
				<>
					<p>Select your default Tremendous campaign and funding source</p>
					{fragment || (mappedCampaigns && mappedFunds ? <div className={styles.selects}>
						<span>Campaign:</span> <Select
							id="campaign-select"
							selectedValue={campSource}
							onChange={setCampSource}
							options={mappedCampaigns}
						/>
						<span>Funding:</span> <Select
							id="funding-select"
							selectedValue={fundSource}
							onChange={setFundSource}
							options={mappedFunds}
						/>
					</div> :
						"You need to set up a campaign and funding source in order to start rewarding creators")
					}
				</>
			)}
		</div>
		<div className={styles.actions}>
			<Button onClick={onClose} variant="outlined">Cancel</Button>
			<Button
				onClick={step === 0 ? handleApiKey : increaseStep}
				disabled={buttonDisabled}
			>
				{step === 1 ? "Finish" : "Next"}
			</Button>
		</div>
	</Modal>;
};

export {TremendousIntegrateModal};
