import React, { ReactElement } from 'react';
import { Button } from '../buttons';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../icons';

import styles from './pagination.module.scss';

interface PaginationProps {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    onNextPage: () => void;
    onPreviousPage: () => void;
    totalCount: number;
    currentCount: number;
    className?: string;
    currentPage?: number;
    totalPages?: number;
    onPageChange?: (page: number) => void;
}

export const Pagination = ({
    hasNextPage,
    hasPreviousPage,
    onNextPage,
    onPreviousPage,
    totalCount,
    currentCount,
    className,
    currentPage = 1,
    totalPages = 1,
    onPageChange
}: PaginationProps): ReactElement => {
    const renderPageNumbers = () => {
        const pages: ReactElement[] = [];
        const maxVisiblePages = 5;
        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    className={`${styles.pageButton} ${i === currentPage ? styles.active : ''}`}
                    onClick={() => onPageChange?.(i)}
                >
                    {i}
                </button>
            );
        }

        if (startPage > 1) {
            pages.unshift(
                <span key="ellipsis-start" className={styles.ellipsis}>...</span>
            );
        }

        if (endPage < totalPages) {
            pages.push(
                <span key="ellipsis-end" className={styles.ellipsis}>...</span>
            );
        }

        return pages;
    };

    const handleFirstPage = () => {
        onPageChange?.(1);
    };

    const handleLastPage = () => {
        onPageChange?.(totalPages);
    };

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return (
        <div className={`${styles.container} ${className || ''}`}>
            <div className={styles.info}>
                {currentCount > 0 && `${(currentPage - 1) * currentCount + 1} - ${Math.min(currentPage * currentCount, totalCount)} of ${totalCount}`}
            </div>
            <div className={styles.controls}>
                <Button
                    color="gray"
                    onClick={handleFirstPage}
                    disabled={!hasPreviousPage || isFirstPage}
                    className={styles.navButton}
                    title="First page"
                >
                    <div className={styles.doubleChevron}>
                        <ChevronLeftIcon />
                        <ChevronLeftIcon />
                    </div>
                </Button>
                <Button
                    color="gray"
                    onClick={onPreviousPage}
                    disabled={!hasPreviousPage}
                    className={styles.navButton}
                    title="Previous page"
                >
                    <ChevronLeftIcon />
                </Button>
                <div className={styles.pageNumbers}>
                    {renderPageNumbers()}
                </div>
                <Button
                    color="gray"
                    onClick={onNextPage}
                    disabled={!hasNextPage}
                    className={styles.navButton}
                    title="Next page"
                >
                    <ChevronRightIcon />
                </Button>
                <Button
                    color="gray"
                    onClick={handleLastPage}
                    disabled={!hasNextPage || isLastPage}
                    className={styles.navButton}
                    title="Last page"
                >
                    <div className={styles.doubleChevron}>
                        <ChevronRightIcon />
                        <ChevronRightIcon />
                    </div>
                </Button>
            </div>
        </div>
    );
};
