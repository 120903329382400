import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {IntercomProvider} from "react-use-intercom";
import React, {ReactElement} from "react";
import ReactDom from "react-dom/client";

import {App} from "./app";
import {config} from "./config";
import {ErrorBoundaryContextProvider} from "./context/error-boundary-context";
import {MainErrorBoundary} from "./error-boundaries";
import {ApolloContextProvider} from "./context/apollo-context";
import {ConnectionHealthContextProvider} from "./context/connection-health-context";
import {loadErrorMessages, loadDevMessages} from "@apollo/client/dev";

if (import.meta.env.REACT_APP_ENV === "local" || import.meta.env.REACT_APP_ENV === "staging") {
	// Adds messages only in a dev environment
	loadDevMessages();
	loadErrorMessages();
}

const router = createBrowserRouter([
	{
		path: "*",
		element: <App />,
	},
])

const Setup = (): ReactElement => {
	return (
		<ErrorBoundaryContextProvider>
			<MainErrorBoundary>
				<ConnectionHealthContextProvider>
					<ApolloContextProvider>
						<DndProvider backend={HTML5Backend}>
							<IntercomProvider appId={config.intercomAppId}>
								<RouterProvider router={router} />
							</IntercomProvider>
						</DndProvider>
					</ApolloContextProvider>
				</ConnectionHealthContextProvider>
			</MainErrorBoundary>
		</ErrorBoundaryContextProvider>
	);
};

const rootElement = document.getElementById("root");

if (rootElement) {
	const root = ReactDom.createRoot(rootElement);

	root.render(
		<React.StrictMode>
			<Setup />
		</React.StrictMode>,
	);
}
