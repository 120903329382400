import React, {ReactElement, ReactNode, createContext, useState} from "react";

export interface EditorSettingsContextValue {
  brushSize: number;
  brushMode: "draw" | "erase";
  setBrushSize: (size: number) => void;
  setBrushMode: (mode: "draw" | "erase") => void;
}

export const EditorSettingsContext =
  createContext<EditorSettingsContextValue | undefined>(undefined);

export const EditorSettingsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const [brushSize, setBrushSize] = useState(64);
  const [brushMode, setBrushMode] = useState<"draw" | "erase">("draw");

  return (
    <EditorSettingsContext.Provider value={{
      brushSize,
      setBrushSize,
      brushMode,
      setBrushMode,
    }}>
      {children}
    </EditorSettingsContext.Provider>
  );
};

export const useEditorSettingsContext = (): EditorSettingsContextValue => {
  const context = React.useContext(EditorSettingsContext);

  if (context === undefined) {
    throw new Error(
      "useEditorSettingsContext must be used within a EditorSettingsContextProvider",
    );
  }

  return context;
};
