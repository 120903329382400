import {LineConfig} from "konva/lib/shapes/Line";
import Konva from "konva";
import React, {createContext, Dispatch, MutableRefObject, ReactElement, ReactNode, SetStateAction, useRef, useState} from "react";

import {useImageElementContext} from "./image-element";

export interface CanvasContextValue {
  stageRef: MutableRefObject<Konva.Stage | null>;
  lines: LineConfig[];
  setLines: Dispatch<SetStateAction<LineConfig[]>>;
  getMask: () => {hasMask: boolean, maskData: string};
}

export const CanvasContext =
  createContext<CanvasContextValue | undefined>(undefined);

const drawLineOnCanvas = (
  context: CanvasRenderingContext2D,
  line: LineConfig,
  stage: Konva.Stage,
  naturalWidth: number,
  naturalHeight: number
): void => {
  if (!line.points || line.points.length < 2) {
    console.warn("Invalid line points:", line);
    return;
  }

  const stageSize = stage.size();
  const stageWidth = stageSize.width;
  const stageHeight = stageSize.height;

  const scaleX = naturalWidth / stageWidth;
  const scaleY = naturalHeight / stageHeight;

  context.beginPath();
  context.moveTo(line.points[0] * scaleX, line.points[1] * scaleY);

  for (let i = 2; i < line.points.length; i += 2) {
    if (i + 1 < line.points.length) {
      context.lineTo(line.points[i] * scaleX, line.points[i + 1] * scaleY);
    }
  }

  context.strokeStyle = "rgb(254, 254, 254)";
  context.lineWidth = (line.strokeWidth as number) * ((scaleX + scaleY) / 2);
  context.lineCap = "round";
  context.lineJoin = "round";
  context.globalCompositeOperation = line.globalCompositeOperation || "source-over";
  context.stroke();
};

export const CanvasContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const [lines, setLines] = useState<LineConfig[]>([]);
  const {element: imageEl} = useImageElementContext();
  const stageRef = useRef<Konva.Stage | null>(null);

  const getMask = (): {hasMask: boolean, maskData: string} => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const stage = stageRef.current;

    if (!context || !imageEl || !stage) {
      throw new Error("Canvas context, image element, or stage is not available");
    }

    // Obtain the natural size of the image
    const naturalWidth = imageEl.naturalWidth;
    const naturalHeight = imageEl.naturalHeight;

    canvas.width = naturalWidth;
    canvas.height = naturalHeight;

    // Draw lines scaled to the natural image size
    lines.forEach((line) => {
      drawLineOnCanvas(context, line, stage, naturalWidth, naturalHeight);
    });

    const hasMask = context.getImageData(0, 0, naturalWidth, naturalHeight).data.some((value) => value === 255);

    return {
      hasMask,
      maskData: canvas.toDataURL("image/webp"),
    }
  };

  return (
    <CanvasContext.Provider value={{
      stageRef,
      lines,
      setLines,
      getMask,
    }}>
      {children}
    </CanvasContext.Provider>
  );
};

export const useCanvasContext = (): CanvasContextValue => {
  const context = React.useContext(CanvasContext);

  if (context === undefined) {
    throw new Error(
      "useCanvasContext must be used within a CanvasContextProvider",
    );
  }

  return context;
};
