import {Link} from "react-router-dom";
import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {ChatConversation} from "../../../../../../models";
import {Subheader} from "../../../../../../shared/v2";
import {useThemeMode} from "../../../../../../context/theme-mode-context";

import styles from "./related-conversation-entry.module.scss";

const cx = classNames.bind(styles);

export interface RelatedConversationEntryProps {
	conversation: ChatConversation;
	index: number;
}

export const RelatedConversationEntry = ({conversation, index}: RelatedConversationEntryProps): ReactElement => {
	const hasPreviews = Number(conversation.preview?.images.length) > 0;
	const {isDarkMode} = useThemeMode();

	return (
		<Link
			to={`/workflow/conversation?canvasId=${conversation.id}`}
			key={conversation.id}
			className={cx("relatedConversation", `color-${index % 4 + 1}`, {isDarkMode})}
		>
			<Subheader size="base" type="medium">
				{conversation.name}
			</Subheader>

			<div className={styles.previews}>
				{hasPreviews ? (
					conversation.preview?.images.map(url => (
						<img key={url} className={styles.previewImage} src={url} />
					))
				) : (
					<>
						<div className={cx("previewImage", "firstPlaceholder")} />
						<div className={cx("previewImage", "secondPlaceholder")} />
						<div className={cx("previewImage", "thirdPlaceholder")} />
						<div className={cx("previewImage", "fourthPlaceholder")} />
						<div className={cx("previewImage", "fifthPlaceholder")} />
					</>
				)}
			</div>
		</Link>
	);
}
