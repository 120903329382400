import React, {ReactElement} from "react";

import {
	ChatCampaignContextProvider,
	ChatConversationContextProvider,
	ChatImageContextProvider,
	ChatPersonaContextProvider,
	ChatTrainingSetContextProvider
} from "../../../../../context/chat-contexts";

export interface DrawerContextsProps {
	children: ReactElement;
}

export const DrawerContexts = ({children}: DrawerContextsProps): ReactElement => {
	return (
		<ChatConversationContextProvider>
			<ChatTrainingSetContextProvider>
				<ChatCampaignContextProvider>
					<ChatPersonaContextProvider>
						<ChatImageContextProvider>
							{children}
						</ChatImageContextProvider>
					</ChatPersonaContextProvider>
				</ChatCampaignContextProvider>
			</ChatTrainingSetContextProvider>
		</ChatConversationContextProvider>
	);
}
