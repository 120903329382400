import React, {ReactElement, ReactNode, createContext, useState} from "react";
import {Persona} from "../models";
import {useQuery} from "@apollo/client";
import {GET_PERSONAS} from "../graphql/queries/ai-models-queries";
import {PersonasData} from "../models/persona";
import {useWorkspaceContext} from "./workspace-context";

export interface AgentsPageContextValue {
	agents: Persona[];
	isLoading: boolean;
	currentAgent: Persona | null;
	setCurrentAgent: (agent: Persona | null) => void;
}

export const AgentsPageContext =
	createContext<AgentsPageContextValue | undefined>(undefined);

export const AgentsPageContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const [currentAgent, setCurrentAgent] = useState<Persona | null>(null);

	const {data: {aiPersonasForWorkspace: agents = []} = {}, loading} = useQuery<PersonasData>(GET_PERSONAS, {
		variables: {
			workspaceId,
			version: "FULL_SIZE",
		},
		// Use cache-first for initial load, then background refresh
		fetchPolicy: "cache-first",
		nextFetchPolicy: "cache-and-network",
	});

	return (
		<AgentsPageContext.Provider value={{
			isLoading: loading,
			agents,
			currentAgent,
			setCurrentAgent,
		}}>
			{children}
		</AgentsPageContext.Provider>
	);
};

export const useAgentsPageContext = (): AgentsPageContextValue => {
	const context = React.useContext(AgentsPageContext);

	if (context === undefined) {
		throw new Error(
			"useAgentsPageContext must be used within a AgentsPageContextProvider",
		);
	}

	return context;
};
