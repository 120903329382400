import React, {ReactElement, useEffect} from "react";
import {Outlet, useNavigate} from "react-router";

import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {MentionsChatsIcon, SparkAiStarsIcon} from "../../../icons";
import {PageSubNavigation} from "../../../hoc/layout/layout/page-sub-navigation";
import {NavigationPageLayout} from "../../../hoc/layout/navigation-page-layout";
import {NavigationTooltip} from "../../../hoc/layout/navigation/navigation-tooltip";
import {useUserContext} from "../../../context/user-context";
import {useMount} from "@/hooks/useMount";

export const MentionsPage = (): ReactElement => {
	const {isEnterpriseManagerOrSupport} = useUserContext();
	const navigate = useNavigate();

	useMount(() => {document.title = "Vurvey - Mentions"})


	useEffect(() => {
		if (!isEnterpriseManagerOrSupport) {
			navigate("/");
		}
	}, [isEnterpriseManagerOrSupport]);

	return <NavigationPageLayout>
		<PageSubNavigation header="Mentions">
			<ButtonGroup>
				<NavigationTooltip text="View community-inspired content directed to your brand">
					<ButtonGroupElement
						icon={<MentionsChatsIcon />}
						text="All mentions"
						to=""
						end
					/>
				</NavigationTooltip>
				<NavigationTooltip text="View emerging topics, sentiment, and themes to discover opportunities">
					<ButtonGroupElement
						icon={<SparkAiStarsIcon />}
						text="Magic Topics"
						to="magic-topics"
					/>
				</NavigationTooltip>
			</ButtonGroup>
		</PageSubNavigation>

		<Outlet />
	</NavigationPageLayout>;
}
