import {useEffect} from "react";

import {useChatConversationContext, useChatMessagesContext} from "../../../context/chat-contexts";
import {isChatMessageLoading} from "@/canvas/chat/utils/isChatMessageLoading";
import {ChatConversationMessageRole} from "@/models/ai-model";

export const useChatListInitialPopulate = () => {
	const {conversation: {id, messages} = {}} = useChatConversationContext();
	const {setMessages, replaceMessage, messages: currentMessages} = useChatMessagesContext();

	const trigger = `${id}-${messages?.length}`;

	const shouldUpdate = () => {
		return Boolean(messages && messages.length >= 2);
	}
	/*
		Detects a race condition where the conversation refetch (third request)
		completes before the actual response message (second request) is received.
		Specifically, it checks if:
		- There are exactly two messages: one user message and one empty assistant message.
		- The current messages also have two messages, with the first being linked to the conversation ID
			or not yet linked, and the second message is still loading.
		If this condition is met, it indicates that the refetch prematurely populated the messages
		with an empty assistant response.
	*/
	const isPremature = () => {
		const lastMessage = messages?.find(m => m.role !== ChatConversationMessageRole.USER);

		return (
			(messages && messages?.length === 2 && lastMessage?.content === "") &&
			(currentMessages?.length === 2 && (currentMessages[0].conversationId === id || !currentMessages[0].conversationId) && isChatMessageLoading(currentMessages[1]))
		)
	};

	useEffect(() => {
		if (!shouldUpdate()) {
			return;
		}

		if (isPremature()) {
			const userFakeMessage = messages?.find(m => m.role === ChatConversationMessageRole.USER);
			const userRealMessage = currentMessages.find(m => m.role === ChatConversationMessageRole.USER);

			if (!userFakeMessage || !userRealMessage) {
				throw new Error("Something wen't wrong with initial chat population");
			}

			replaceMessage(userRealMessage.id, userFakeMessage);
		} else {
			setMessages(messages || []);
		}
	}, [trigger]);
};
