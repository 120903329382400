import React, {ReactElement, useContext, useState} from "react";
import {gql, useMutation, ApolloError} from "@apollo/client";
import {CREATE_ATTRIBUTE_CATEGORY} from "../../graphql/mutations/attribute-mutations";
import {ToastContext} from "../../context/toast-context";
import styles from "./attribute-category.module.scss";
import {updateCacheAddItemByFieldId} from "../../shared/utility/update-cache";
import {useWorkspaceContext} from "../../context/workspace-context";
import {BaseModalProps, Button, Input, Modal} from "../../shared/v2";

interface CreateAttributeCategoryData {
	createAttributeCategory: {
		id: string;
		workspaceId: string;
		name: string;
	};
}

interface CreateAttributeCategoryVars {
	workspaceId: string;
	name: string;
}

const AttributeCategoryModal = ({isOpen, onClose}: BaseModalProps): ReactElement => {
	const {updateToast} = useContext(ToastContext);
	const [name, setName] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const {workspace: {id: workspaceId}} = useWorkspaceContext();

	const [createCategory] = useMutation<CreateAttributeCategoryData, CreateAttributeCategoryVars>(
		CREATE_ATTRIBUTE_CATEGORY
	);

	const closeModal = (): void => {
		setName("");
		setErrorMessage("");
		onClose();
	};

	const onError = (error: ApolloError): void => {
		if (error.message.toString().includes("duplicate key value violates unique constraint")) {
			setErrorMessage("Name already used. Choose a different name.");
		}
	};

	const handleNameChange = (value: string): void => {
		setName(value);
		setErrorMessage("");
	};

	const createCategoryFunc = async() => {
		return createCategory({
			variables: {
				workspaceId,
				name,
			},
			onCompleted: () => updateToast({description: "Created new category", type: "informational"}),
			onError,
			update(cache, {data}) {
				if (!data) return;
				const ref = cache.writeFragment({
					data: data.createAttributeCategory,
					fragment: gql`
						fragment NewAttributeCategory on AttributeCategory {
							id
							workspaceId
							name
						}
					`,
				});
				updateCacheAddItemByFieldId(
					cache,
					"attributeCategories",
					ref,
					workspaceId,
					data.createAttributeCategory.id,
				);
			},
		});
	};

	const handleSave = async(): Promise<void> => {
		if (!name.length) {
			return;
		}

		try {
			const {data} = await createCategoryFunc();
			if (data) {
				closeModal();
			}
		} catch (error) {
			// Error is handled by onError callback
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeModal}
			title="New Category"
		>
			<Input
				label="Category Name"
				value={name}
				onChange={handleNameChange}
			/>
			<span className={styles.errorMessage}>
				{errorMessage}
			</span>
			<Button
				className={styles.submitButton}
				onClick={handleSave}
				disabled={!name.length || !!errorMessage.length}
			>
				Save
			</Button>
		</Modal>
	);
};

export {AttributeCategoryModal};
