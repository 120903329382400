import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Card} from "../../../shared/v2";
import {Persona} from "../../../models/persona";
import {useThemeMode} from "../../../context/theme-mode-context";
import {PersonaCardContent} from "./persona-card-content";

import styles from "./agent-card.module.scss";
import {useUserContext} from "../../../context/user-context";

const cx = classNames.bind(styles);

export interface AgentCardProps {
	persona: Persona;
	onClick?: (persona: Persona) => void;
  className?: string;
  onSelected?: (persona: Persona) => void;
  isSelected?: boolean;
}

export const AgentCard = ({
	persona,
	onClick,
	onSelected,
	isSelected,
	className,
}: AgentCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const handleClick = (): void => {
		if (onSelected) {
			return onSelected(persona);
		}
		onClick?.(persona)
	};

	return (
		<Card
			className={cx("personaCard", className, {isSelected, isDarkMode})}
			onClick={handleClick}
		>
			<>
				<PersonaCardContent persona={persona} />
			</>
		</Card>
	);
};
