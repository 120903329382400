import classNames from "classnames/bind";
import React, {ReactElement} from "react";
import {useNavigate} from "react-router";

import {useThemeMode} from "../../../context/theme-mode-context";
import {BinDeleteIcon, DotsIcon, PenEditIcon} from "../../../icons";
import {AiOrchestration} from "../../../models/ai-orchestration";
import {Body, Caption, Card, Dropdown, Subheader} from "../../../shared/v2";
import {PersonasList} from "../personas-list";

import styles from "./workflow-card.module.scss";

const bStyles = classNames.bind(styles);

export interface WorkflowCardProps {
	workflow: AiOrchestration;
	onClick: (id: string) => void;
	handleDelete: (id: string) => void;
}


const WorkflowCard = ({workflow, handleDelete, onClick}: WorkflowCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const navigate = useNavigate();

	const openWorkflowPage = (): void => {
		navigate(`/workflow/flows/${workflow.id}`);
	};

	const renderTeam = () => {
		if (!workflow.aiPersonaTasks.length) {
			return <div className={styles.noTeamWrapper}>
				<Body size="xs" type="regular" className={styles.noTeamText}>
					No agents assigned yet.
				</Body>
			</div>
		}
		return <PersonasList
			size="sm"
			max={7}
			variant="card"
			personas={
				workflow
					.aiPersonaTasks.map(({persona}) => persona)
					.filter((persona) => Boolean(persona)) as any
			}
		/>
	}

	return (
		<Card
			className={bStyles("card", {isDarkMode})}
			onClick={() => onClick(workflow.id)}
		>
			<Dropdown
				className={styles.dropdown}
				trigger={<DotsIcon className={styles.trigger} />}
				items={[
					{
						label: "Edit",
						icon: <PenEditIcon />,
						onClick: () => openWorkflowPage(),
					},
					{
						label: "Delete",
						icon: <BinDeleteIcon />,
						color: "danger",
						onClick: () => handleDelete(workflow.id)
					}
				]}
				position="bottom-start"
			/>
			<Subheader className={styles.title} type="medium">{workflow.name}</Subheader>
			<Body className={styles.description} size="xs" color="text-secondary">
				{workflow.description}
			</Body>

			<div className={styles.teamsSection}>
				<Caption className={styles.team}>
					Team:
				</Caption>

				{renderTeam()}
			</div>
		</Card>
	);
};

export {WorkflowCard};
