import {DocumentNode} from "graphql";
import {TranscodingStatus} from "../models/answer";
import {useQuery} from "@apollo/client";

/**
 * This may eventually turn into a useQuery in order to deal with
 * using this on a page that isn't inside the modal.
 *
 * @param imageId The image id given to this hook
 * @param query query to run image id against
 *
 * @returns The transcoding status
 */
export const useWaitForUpload =
(imageId: string | undefined, query: DocumentNode): TranscodingStatus | undefined => {
	const {data, stopPolling} = useQuery(query, {
		pollInterval: 1000,
		skip: !imageId,
		fetchPolicy: "network-only",
		variables: {id: imageId},
	});

	if (data) {
		const [name] = Object.keys(data);
		if (data[name].transcodingStatus === "COMPLETED") stopPolling();
		return data[name].transcodingStatus;
	}
	return undefined;
};
