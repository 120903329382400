
import React, {ReactElement, ReactNode, RefObject, createContext} from "react";
import {useTask} from "../../../hooks/useTask";
import {client} from "../../../shared/utility/client";
import {useCanvasContext} from "./canvas-area-selection";
import {useImageHistoryContext} from "./image-history";
import config from "../../../config";
import {useToastContext} from "@/context/toast-context";
import {useChatSendQuestionContext} from "@/context/chat-contexts";
import {useImageElementContext} from "./image-element";
import {imageToDataUrl} from "@/shared/utility/image-to-data-url";

export interface AiActionsContextValue {
  enhanceImage: (prompt: string) => Promise<void>;
  changeImage: (prompt: string) => Promise<void>;
  saveImage: () => Promise<void>;
  isEnhancingImage: boolean;
  isChangingImage: boolean;
  isUpdatingImage: boolean;
  isSavingImageRef: RefObject<boolean>;
}

export const AiActionsContext =
  createContext<AiActionsContextValue | undefined>(undefined);

export const AiActionsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {setLines, getMask} = useCanvasContext();
  const {imageSrc, setImageSrc, clearImage} = useImageHistoryContext();
  const {updateToast} = useToastContext();
  const {sendQuestion} = useChatSendQuestionContext();
  const {element: imageEl} = useImageElementContext();

  const {run: handleEnhance, loading: isEnhancing} = useTask(async (prompt: string) => {
		try {
			const {urls} = await client.post(`${config.apiHost}/rest/upscale`, {
				body: JSON.stringify({
					url: imageSrc,
					prompt,
				}),
			}).json<{urls: string[]}>();
      const result = urls[0];
      if (!result) {
        updateToast({type: "failure", description: "Failed to generate edited image"});
        throw new Error("Failed to generate edited image");
      }
      setImageSrc(result);
      setLines([]);
		} catch (error) {
			console.error("Failed to generate edited image");
			throw error;
		}
	})

  const {
    run: handleGenerateImage,
    loading: isGeneratingImage,
  } = useTask(async (prompt: string) => {
    const {hasMask, maskData} = getMask();

    if (!hasMask) {
      handleEnhance(prompt);
      return;
    }

    const data = {
			maskData: maskData,
			baseImage: imageSrc,
      prompt,
		};

		try {
			const {editedImageUrls} = await client.post(`${config.apiHost}/rest/imgeditor`, {
				body: JSON.stringify(data),
			}).json<{editedImageUrls: string[]}>();
      const result = editedImageUrls[0];
      if (!result) {
        updateToast({type: "failure", description: "Failed to generate edited image"});
        throw new Error("Failed to generate edited image");
      }
      setImageSrc(result);
		} catch (error) {
			console.error("Error generating edited image:", error);
			throw error;
		} finally {
			setLines([]);
		}
	});

  const {run: handleSaveImage, loadingRef: isSavingImageRef} = useTask(async () => {
    // to data url
    if (!imageEl) {
      throw new Error("Image element is not available");
    }

    const image = imageToDataUrl(imageEl);

    sendQuestion("Describe the image", {
      sendQuestionInput: {image},
    });

    clearImage();
  });

  return (
    <AiActionsContext.Provider value={{
      enhanceImage: handleEnhance,
      changeImage: handleGenerateImage,
      saveImage: handleSaveImage,
      isUpdatingImage: isEnhancing || isGeneratingImage,
      isEnhancingImage: isEnhancing,
      isChangingImage: isGeneratingImage,
      isSavingImageRef,
    }}>
      {children}
    </AiActionsContext.Provider>
  );
};

export const useAiActionsContext = (): AiActionsContextValue => {
  const context = React.useContext(AiActionsContext);

  if (context === undefined) {
    throw new Error(
      "useAiActionsContext must be used within a AiActionsContextProvider",
    );
  }

  return context;
};
