import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router";

import {CanvasPageState} from "../../../canvas";
import {useChatConversationContext, useChatSendQuestionContext} from "../../../context/chat-contexts";

export const useChatInvokeFromLocation = () => {
	const {conversation} = useChatConversationContext();
	const {sendQuestion, isSendingQuestion} = useChatSendQuestionContext();
	const {state} = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const {invoke} = (state as CanvasPageState | undefined) || {};
		if (!invoke || isSendingQuestion || !conversation) {
			return;
		}

		const {message, persona, image} = invoke;

		sendQuestion(message, {
			sendQuestionInput: {
				image,
				persona,
			},
		}).then(() => {
			navigate(`?canvasId=${conversation.id}`, {replace: true});
		})
	}, [conversation]);
};
