export function createBinaryMask(imageData) {
  const width = imageData.width;
  const height = imageData.height;
  const data = imageData.data;
  const binaryMask: number[][] = [];

  for (let y = 0; y < height; y++) {
    const row: number[] = [];
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const alpha = data[index + 3];
      row.push(alpha > 0 ? 1 : 0);
    }
    binaryMask.push(row);
  }

  return binaryMask;
}
