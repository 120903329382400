import React, { ReactElement } from 'react';
import { SelectInput } from '../../../shared/v2';
import type { Option } from '../../../shared/v2/inputs/select-input';

interface WorkflowSortProps {
    value: string;
    onChange: (value: string) => void;
    className?: string;
}

const sortOptions: Option[] = [
    { name: 'Newest Updated', id: 'UPDATED_AT_DESC' },
    { name: 'Oldest Updated', id: 'UPDATED_AT_ASC' },
    { name: 'Name (A-Z)', id: 'NAME_ASC' },
    { name: 'Name (Z-A)', id: 'NAME_DESC' },
    { name: 'Newest Created', id: 'CREATED_AT_DESC' },
    { name: 'Oldest Created', id: 'CREATED_AT_ASC' },
    { name: 'Default', id: 'DEFAULT' },
];

export const WorkflowSort = ({
    value,
    onChange,
    className
}: WorkflowSortProps): ReactElement => {
    return (
        <SelectInput
            className={className}
            options={sortOptions}
            placeholder="Sort"
            onChange={onChange}
            value={value}
        />
    );
};
