import {Circle, Group} from "react-konva";
import {throttle} from "lodash-es";
import Konva from "konva";
import React, {ReactElement, useEffect, useMemo, useRef, useState} from "react";

import {useCanvasMouseEventMiddleware} from "../../../../contexts/canvas-mouse-event-middleware";
import {useEditorSettingsContext} from "../../../../contexts";


export const Cursor = (): ReactElement => {
  const cursorRef = useRef<Konva.Group | null>(null);
  const {brushSize} = useEditorSettingsContext();
  const [cursorTheme, setCursorTheme] = useState<"light" | "dark">("light");

  useEffect(() => {
    if (!cursorRef.current) return;

    const stage = cursorRef.current.getStage();
    if (!stage) return;

    stage.container().style.cursor = "none";
  }, [cursorRef.current]);

  const handleMouseMove = throttle((e: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    const pointerPosition = stage?.getPointerPosition();
    if (!pointerPosition || !cursorRef.current) return;

    // Update cursor position
    const cursor = cursorRef.current;
    cursor.position(pointerPosition);
    cursor.getLayer()?.batchDraw();

    // Adjust cursor color based on background brightness
    const ctx = stage?.toCanvas().getContext("2d");
    if (ctx) {
      const { x, y } = pointerPosition;
      const pixelData = ctx.getImageData(Math.floor(x), Math.floor(y), 1, 1).data;
      const brightness = (pixelData[0] + pixelData[1] + pixelData[2]) / 3;

      const newCursorTheme = brightness > 128 ? "dark" : "light";
      setCursorTheme(newCursorTheme);
    }
  }, 16);

  useCanvasMouseEventMiddleware(handleMouseMove, {
    name: "cursor",
    event: "onMouseMove",
    order: 1,
  });

  useCanvasMouseEventMiddleware((e) => {
    const stage = e.target.getStage();
    if (!stage) return;

    const pointerPosition = stage.getPointerPosition();
    if (!pointerPosition) return;

    const cursor = cursorRef.current;
    if (!cursor) return;

    cursor.visible(true);
    cursor.position(pointerPosition);
    cursor.getLayer()?.batchDraw();
  }, {
    name: "cursor",
    event: "onMouseEnter",
    order: 1,
  });

  useCanvasMouseEventMiddleware((e) => {
    const stage = e.target.getStage();
    if (!stage) return;

    const pointerPosition = stage.getPointerPosition();
    if (!pointerPosition) return;

    const cursor = cursorRef.current;
    if (!cursor) return;

    cursor.visible(false);
    cursor.getLayer()?.batchDraw();
  }, {
    name: "cursor",
    event: "onMouseLeave",
    order: 1,
  });

  const circleDefaultProps = {
    radius: brushSize / 2,
    x: 0,
    y: 0,
  };

  return (
    <Group visible={false} ref={cursorRef}>
      <Circle
        {...circleDefaultProps}
        stroke={cursorTheme === "light" ? "#000000" : "#EAEAEA"}
        strokeWidth={1}
      />
      <Circle
        {...circleDefaultProps}
        radius={brushSize / 2 + 2}
        stroke={cursorTheme === "light" ? "#EAEAEA" : "#000000"}
        strokeWidth={2}
      />
      <Circle
        {...circleDefaultProps}
        radius={brushSize / 2 + 3}
        stroke={cursorTheme === "light" ? "#000000" : "#EAEAEA"}
        strokeWidth={1}
      />
    </Group>
  );
}
