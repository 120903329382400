import React, {ReactElement} from "react";

import {WorkflowCanvas} from "../../../components/workflow-canvas"
import {HistoryDrawer} from "../../../components/history-drawer";
import {useWorkflowContext} from "../../../../context/workflow-contexts";

import styles from "./flow-history.module.scss";

export const FlowHistory = (): ReactElement => {
  const {activeHistoryTasks} = useWorkflowContext();

  return <>
    <div className={styles.content}>
      <HistoryDrawer />
      <WorkflowCanvas agents={activeHistoryTasks} disabled />
    </div>
  </>
}
