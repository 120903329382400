import {AiSkill} from "./ai-model";
import {AiPersonaTask} from "./ai-orchestration";

export type PersonaCategory = "Research" | "Creation" | "Marketing" | "E-Commerce";

export enum PersonaStatus {
	DRAFT = "draft",
	PUBLISHED = "published",
}

export enum PersonaTransformedStatus {
	ACTIVE = "active",
	INACTIVE = "inactive",
}

export interface PersonaType {
	id: string;
	name: string;
	description: string;
}

export enum PersonaDefaultVoice {
	NAME = "Hope - natural conversations",
	ID = "OYTbf65OHHFELVut7v2H",
}

export enum PersonaModel {
	GEMINI = "gemini-pro",
	CLAUDE = "claude",
	GPT = "gpt-4o",
	IMAGE_GEN = "imagegeneration",
	STABLE_DIFFUSION = "sd3",
	DALL_E = "dall-e-3",
}

export interface Persona {
	id: string;
	name: string;
	description: string;
	instructions: string;
	personaCategory: {
		id: string;
		name: PersonaCategory;
	}
	picture: PersonaPicture;
	voiceId: string;
	voiceName: string;
	chatPrompt?: string;
	parsedInstructions?: ParsedInstructions;
	isVurvey?: boolean;
	avatarUri?: string;
	personaStatus: PersonaStatus;
	personaType: PersonaType;
	createdAt: string;
	skills: AiSkill[];
}

export interface PersonaPicture {
	id: string;
	url: string;
}

export interface ParsedInstructions {
	agent_type: string;
	chat_prompt: string;
	max_tokens: number;
	model: PersonaModel;
	output_type: string
	system_prompt: string;
	temperature: number;
}

export interface ParsedTaskInstructions {
	description: string;
	allow_delegation: boolean;
	expected_output: string;
}

export interface PersonasData {
	aiPersonasForWorkspace: Persona[];
}

export interface UpdatePersonaReturn {
	updateAiPersona: Persona;
}

export interface UpdatePersonaVars {
	personaId: string;
	input: Omit<AiPersonaInput, "workspaceId">;
}

export interface DeletePersonaReturn {
	deleteAiPersona: Pick<Persona, "id">;
}

export interface CreatePersonaReturn {
	createAiPersona: Persona;
}

export interface AiPersonaInput {
	name?: string;
	description?: string;
	personaCategoryId?: string;
	voiceId?: string;
	voiceName?: string;
	pictureId?: string;
	workspaceId?: string;
	instructions?: string;
	personaTypeId?: string;
	skillIds?: string[];
}

export interface CreatePersonaVars {
	input: AiPersonaInput
}

export interface AiPersonaTaskLog {
	id: string;
	personaTaskId: string;
	outputLog: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface AiPersonaTaskCreate {
	personaId: string;
	orchestrationId: string;
	index: number;
	asyncExecution?: boolean;
	instructions?: string;
}

// "id" is the personaId we are updating
export type AiPersonaTaskUpdate = Omit<AiPersonaTaskCreate, "personaId" | "orchestrationId"> & {id: string};

export interface AiPersonaCreateVars {
	input: AiPersonaTaskCreate;
}

export interface AiPersonaTaskUpdateVars {
	input: AiPersonaTaskUpdate;
}

export interface AiPersonaCreateData {
	createPersonaTask: AiPersonaTask;
}

export interface AiPersonaTaskUpdateData {
	updatePersonaTask: AiPersonaTask;
}

export interface AiPersonaTaskDeleteVars {
	id: string;
}

export interface AiPersonaTaskDeleteData {
	deletePersonaTask: {id: string, personaId: string};
}
