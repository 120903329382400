import {gql} from "@apollo/client";
import {GROUNDING_FRAGMENT} from "../fragments/chat";

export const CREATE_TRAINING_SET = gql`
  mutation CreateTrainingSet($name: String!, $description: String, $workspaceId: GUID!) {
    createTrainingSet(name: $name, description: $description, workspaceId: $workspaceId) {
      id
      name
      description
    }
  }
`;

export const TRAINING_SET_UPDATE = gql`
  mutation UpdateTrainingSet($trainingSetId: GUID!, $changes: TrainingSetChanges) {
    updateTrainingSet(trainingSetId: $trainingSetId, changes: $changes) {
      id
      alias
      description
    }
  }
`;

export const CREATE_TRAINING_SET_FILE = gql`
  mutation CreateTrainingSetFile($trainingSetId: GUID!, $uploadItemId: GUID!) {
    createTrainingSetFile(trainingSetId: $trainingSetId, uploadItemId: $uploadItemId) {
      id
      originalFilename
      originalMimetype
    }
  }
`;

export const CREATE_TRAINING_SET_VIDEO = gql`
  mutation CreateTrainingSetVideo($trainingSetId: GUID!, $uploadItemId: GUID!) {
    createTrainingSetVideo(trainingSetId: $trainingSetId, uploadItemId: $uploadItemId) {
      id
      originalFilename
      originalMimetype
    }
  }
`;

export const ADD_FILE_TO_TRAINING_SET = gql`
  mutation AddFileToTrainingSet($fileId: String!, $trainingSetId: GUID!) {
    addFileToTrainingSet(fileId: $fileId, trainingSetId: $trainingSetId) {
      id
      name
    }
  }
`;

export const ADD_VIDEO_TO_TRAINING_SET = gql`
  mutation AddVideoToTrainingSet($videoId: String!, $trainingSetId: GUID!) {
    addVideoToTrainingSet(videoId: $videoId, trainingSetId: $trainingSetId) {
      id
      name
    }
  }
`;

export const DELETE_TRAINING_SET_VIDEO = gql`
  mutation DeleteTrainingSetVideo($videoId: GUID!) {
    deleteTrainingSetVideo(videoId: $videoId)
  }
`;

export const DELETE_TRAINING_SET_FILE = gql`
  mutation DeleteTrainingSetFile($fileId: GUID!) {
    deleteTrainingSetFile(fileId: $fileId)
  }
`;

export const REMOVE_FILE_FROM_TRAINING_SET = gql`
  mutation RemoveFileFromTrainingSet($fileId: String!, $trainingSetId: GUID!) {
    removeFileFromTrainingSet(fileId: $fileId, trainingSetId: $trainingSetId)
  }
`;

export const REMOVE_VIDEO_FROM_TRAINING_SET = gql`
  mutation RemoveVideoFromTrainingSet($videoId: String!, $trainingSetId: GUID!) {
    removeVideoFromTrainingSet(videoId: $videoId, trainingSetId: $trainingSetId)
  }
`;

export const CHAT_CREATE_CONVERSATION = gql`
  mutation ChatCreateConversation(
    $workspaceId: GUID!
    $name: String!
    $trainingSetIds: [String!]
    $aiPersonaId: GUID
    $surveyIds: [GUID!]
    $isPreview: Boolean
  ) {
    chatCreateConversation(
      workspaceId: $workspaceId
      name: $name
      trainingSetIds: $trainingSetIds
      aiPersonaId: $aiPersonaId
      surveyIds: $surveyIds
      isPreview: $isPreview
    ) {
      id
      name
      isPreview
      trainingSets {
        id
        name
      }
      messages {
        id
      }
    }
  }
`;

export const CHAT_UPDATE_CONVERSATION = gql`
  mutation ChatUpdateConversation(
    $conversationId: GUID!
    $changes: UpdateChatConversationChanges!
  ) {
    chatUpdateConversation(conversationId: $conversationId, changes: $changes) {
      id
      name
      isPreview
      trainingSets {
        id
        name
      }
    }
  }
`;

export const CHAT_SEND_QUESTION = gql`
	mutation ChatSendQuestion(
		$workspaceId: GUID!
		$question: String!
		$conversationId: GUID!
		$surveyIds: [GUID!]
		$instructions: String
		$image: String
    $systemAgentId: GUID
    $messageId: GUID
	) {
		chatSendQuestion(
			workspaceId: $workspaceId
			question: $question
			conversationId: $conversationId
			surveyIds: $surveyIds
			instructions: $instructions
			image: $image
      systemAgentId: $systemAgentId
      messageId: $messageId
		) {
			id
			content
      conversationId
			persona {
				id
				name
				description
        voiceId
        voiceName
        personaStatus
				personaCategory {
					name
				}
				picture {
					id
					url(version: THUMBNAIL)
				}
			}
			role
			type
			position
			suggestedFollowUps
			groundingData {
				...GroundingFields
			}
		}
	}
	${GROUNDING_FRAGMENT}
`;

export const CREATE_PERSONA_TASK = gql`
  mutation CreatePersonaTask($input: AiPersonaTaskInputCreate!) {
    createPersonaTask(input: $input) {
      id
    }
  }
`;

export const UPDATE_PERSONA_TASK = gql`
  mutation UpdatePersonaTask($input: AiPersonaTaskInputUpdate!) {
    updatePersonaTask(input: $input) {
      id
    }
  }
`;

export const DELETE_PERSONA_TASK = gql`
  mutation DeletePersonaTask($id: GUID!) {
    deletePersonaTask(id: $id) {
      id
      personaId
    }
  }
`;

export const CREATE_AI_ORCHESTRATION = gql`
	mutation CreateAiOrchestration(
		$workspaceId: String!
		$name: String!
		$description: String
		$inputParameters: String
		$instructions: String
		$outputTypeId: String
		$surveyIds: [String]
		$trainingSetIds: [String]
		$autoWorkflow: Boolean
	) {
		createAiOrchestration(
			workspaceId: $workspaceId
			name: $name
			description: $description
			inputParameters: $inputParameters
			instructions: $instructions
			outputTypeId: $outputTypeId
			autoWorkflow: $autoWorkflow
			surveyIds: $surveyIds
			trainingSetIds: $trainingSetIds
		) {
			id
			workspaceId
			name
			description
			outputTypeId
			surveys {
				id
				name
			}
			trainingSets {
				id
				alias
			}
		}
	}
`;

export const UPDATE_AI_ORCHESTRATION = gql`
  mutation UpdateAiOrchestration(
    $id: String!
    $name: String
    $description: String
    $inputParameters: String
    $instructions: String
    $outputTypeId: String
    $surveyIds: [String]
    $trainingSetIds: [String]
  ) {
    updateAiOrchestration(
      id: $id
      name: $name
      description: $description
      inputParameters: $inputParameters
      instructions: $instructions
      outputTypeId: $outputTypeId
      surveyIds: $surveyIds
      trainingSetIds: $trainingSetIds
    ) {
      id
      workspaceId
      name
      description
      outputTypeId
      surveys {
        id
        name
      }
      trainingSets {
        id
        alias
      }
    }
  }
`;

export const DELETE_AI_ORCHESTRATION = gql`
  mutation DeleteAiOrchestration($id: String!) {
    deleteAiOrchestration(id: $id) {
      id
    }
  }
`;

export const CHAT_LIKE_MESSAGE = gql`
  mutation ChatLikeMessage($messageId: GUID!) {
    chatLikeMessage(messageId: $messageId) {
      id
      likedByUsers {
        id
      }
      dislikedByUsers {
        id
      }
    }
  }
`;

export const CHAT_DISLIKE_MESSAGE = gql`
  mutation ChatDislikeMessage($messageId: GUID!) {
    chatDislikeMessage(messageId: $messageId) {
      id
      likedByUsers {
        id
      }
      dislikedByUsers {
        id
      }
    }
  }
`;

export const CHAT_REMOVE_MESSAGE_REACTION = gql`
  mutation ChatRemoveMessageReaction($messageId: GUID!) {
    chatRemoveMessageReaction(messageId: $messageId) {
      id
      likedByUsers {
        id
      }
      dislikedByUsers {
        id
      }
    }
  }
`;

export const CHAT_DELETE_CONVERSATION = gql`
  mutation ChatDeleteConversation($id: GUID!) {
    chatDeleteConversation(id: $id)
  }
`;

export const TEST_CONVERSATION_SUBSCRIPTION = gql`
  mutation TestConversationSubscription($conversationId: GUID!) {
    testConversationSubscription(conversationId: $conversationId)
  }
`;

export const RUN_WORKFLOW = gql`
  mutation RunWorkflow($orchestrationId: GUID!) {
    runWorkflow(orchestrationId: $orchestrationId)
  }
`;
