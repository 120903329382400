import {useCallback, useEffect, useMemo, useState} from "react";
import {useObserver} from "./useObserver";

interface ElementSize {
  width: number;
  height: number;
}

export const useElementSize = (element: HTMLElement | null): ElementSize => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleResize = useCallback(() => {
    if (element) {
      setWidth(element.offsetWidth);
      setHeight(element.offsetHeight);
    }
  }, [element]);

  useEffect(() => {
    handleResize();
  }, [handleResize, element]);

  useObserver(element, ResizeObserver, handleResize);

  return useMemo(() => ({width, height}), [width, height]);
}
