import classNames from "classnames/bind";
import {motion} from "framer-motion";
import React, {ReactElement} from "react";
import {useNavigate} from "react-router";

import {useThemeMode} from "../../../../context/theme-mode-context";
import {PenEditIcon} from "../../../../icons";
import {Persona} from "../../../../models";
import {Body, Button, Display, Separator, Subheader} from "../../../../shared/v2";
import {OverflowTooltip} from "../../../../shared/v2/tooltip/overflow-tooltip";
import {DrawerChatBubble} from "./drawer-chat-bubble";
import {DrawerImage} from "./drawer-image";
import {RelatedConversationsSection} from "./related-conversations-section";

import styles from "./drawer.module.scss";

const cx = classNames.bind(styles);

export interface DrawerProps {
	agent: Persona;
}

export const Drawer = ({agent}: DrawerProps): ReactElement => {
	const navigate = useNavigate();
	const {isDarkMode} = useThemeMode();

	const handleEdit = () => {
		navigate(`/agents/builder?id=${agent.id}`);
	};

	return (
		<motion.div
			className={cx("drawer", {isDarkMode})}
			initial={{x: "100%"}}
			animate={{x: 0}}
			exit={{x: "100%"}}
			transition={{
				type: "tween",
				duration: 0.3,
				bounce: 0,
			}}
		>
			<div className={styles.mainWrapper}>
				<DrawerImage agent={agent} />

				<div className={styles.headerWrapper}>
					<div className={styles.headerText}>
						<OverflowTooltip>
							<Display className={styles.header} size="l" type="semibold">
								{agent.name}
							</Display>
						</OverflowTooltip>

						<Subheader className={styles.agentType} size="base" type="medium">
							{agent.personaType.name}
						</Subheader>
					</div>
					<Button
						leftIcon={<PenEditIcon />}
						size="small"
						variant="outlined"
						onClick={handleEdit}
					>
						Edit
					</Button>
				</div>

				<Separator className={styles.firstSeparator} color="navigation" orientation="horizontal" />

				<Body
					size="base"
					type="regular"
					color="text-body"
					className={styles.description}
				>
					{agent.description}
				</Body>

				<RelatedConversationsSection agent={agent} />
			</div>

			<Separator className={styles.secondSeparator} color="navigation" orientation="horizontal" />

			<div className={styles.chatBubbleContainer}>
				<DrawerChatBubble agent={agent} />
			</div>
		</motion.div>
	);
}
