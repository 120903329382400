/* eslint-disable */
import {Dropdown} from "../../../shared";
import React, {ReactElement, useContext, useState} from "react";
import styles from "./invite-campaign-dropdown.module.scss";
import {gql, useMutation, useQuery} from "@apollo/client";
import {AddSurveyMemberData, AddSurveyMemberVars} from "../../../models/user";
import {ADD_SURVEY_MEMBERS} from "../../../graphql/mutations/survey-mutations";
import {ToastContext} from "../../../context/toast-context";
import {GET_ALL_SURVEYS} from "../../../graphql/queries/survey-queries";
import {Survey, SurveyPageData} from "../../../models/survey";
import {InviteToSurveyModal} from "../../../modals/invite-to-survey";
import {AnswersPageFilter} from "../../../models/answer";
import {SurveyStatus} from "../../../shared/constants/constants";
import {SearchInput} from "../../../shared/v2/inputs";

export interface InviteCampaignDropdownProps {
	anchorEl: HTMLElement | null;
	workspaceId: string;
	closeCallback: () => void;
	showModal?: () => void;

	/**
	 * Set to true to supply new members via userIds. Takes precedence over useFilter.
	 */
	useUserIds?: boolean;

	/**
	 * The ids of users to add. Used only if useUserIds is set to true.
	 */
	userIds?: string[];

	/**
	 * Set to true to supply new members via a filter. "useUserIds" takes precedence over this.
	 */
	useFilter?: boolean;

	/**
	 * The filter to use to find users to add. Used only if useFilter is set to true.
	 */
	filter?: AnswersPageFilter;

	/**
	 * The number of items selected by the filter
	 */
	numFilterSelected?: number;

	className?: string;
}
/**
 * @param anchorEl HTMLElement the dropdown attaches to
 * @param closeCallback How to handle closing dropdown
 */

const InviteCampaignDropdown
	= React.forwardRef<HTMLDivElement, InviteCampaignDropdownProps>((props, ref): ReactElement => {
		const {
			anchorEl,
			workspaceId,
			closeCallback,
			useUserIds,
			userIds,
			useFilter,
			filter,
			numFilterSelected,
			className
		} = props;
		const [filterValue, setFilterValue] = useState<string>("");
		const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
		const [selectedSurvey, setSelectedSurvey] = useState<undefined | Survey>(undefined);
		const {updateToast} = useContext(ToastContext);
		const handleToggleInvite = (): void => {
			setShowEmailModal(prev => !prev);
			if (showEmailModal) {
				closeCallback();
			}
		};
		const {data: surveyData, loading} = useQuery<SurveyPageData>(GET_ALL_SURVEYS, {
			skip: !anchorEl,
			variables: {workspaceId, filter: {name: filterValue, status: SurveyStatus.OPEN}},
			fetchPolicy: "no-cache",
		});

		const handleOptionSelected = (survey): void => {
			setSelectedSurvey(survey);
			setShowEmailModal(true);
		};

		const [addToSurvey] =
	useMutation<AddSurveyMemberData, AddSurveyMemberVars>(ADD_SURVEY_MEMBERS, {
		errorPolicy: "all",
		onCompleted: data => {
			handleToggleInvite();
			const filterNulls = data.addSurveyMembers.users.filter(v => v !== null);
			const {length} = filterNulls;
			updateToast({
				description: `${length} Creators added`,
				type: "informational",
			});
			closeCallback();
		},
		onError: error => {
			const {message} = error;
			// "Could not identify object null" appears when trying to add someone that exists
			if (message.includes("null")) {
				handleToggleInvite();
				return;
			}
			updateToast({
				description: message,
				type: "failure",
			});
			closeCallback();
		},
	});

		const handleInviteSurveyMembers = (
			emails: string[],
			anonymous: boolean,
		): Promise<any> => {
			if (!selectedSurvey) return Promise.resolve();
			return addToSurvey({
				variables: {
					surveyId: selectedSurvey.id,
					emails,
					anonymous,
					useUserIds,
					userIds,
					useFilter,
					filter,
				},
				update(cache, {data}) {
					if (data) {
						cache.modify({
							fields: {
							/**
							 * Right now it seems like the mutation here always returns email
							 * fields as null, so we can't really update anything.
							 */
								surveyMembers: previous => {
								// A user could be null if they are already in the participant list

									const newItemsRefs = data.addSurveyMembers.users?.filter(user => user !== null)
										.map(user => (
											cache.writeFragment({
												data: user,
												fragment: gql`
												fragment NewUser on User {
													id
													firstName
													lastInitial
													picture {
														id
														badge
													}
												}
											`,
											})
										));
									return {
										...previous,
										items: [...previous.items, ...newItemsRefs],
									};
								},
							},
						});
					}
				},
			});
		};

		return (
			<>
				<Dropdown className={className} anchorEl={anchorEl} closeMenu={closeCallback} keepOpen width={240} height={250}>
					<div ref={ref}>
						<p className={styles.header}>Invite to Campaign</p>
						<div className={styles["input-container"]}>
							<SearchInput
								id="filter-campaigns"
								value={filterValue}
								onChange={setFilterValue}
							/>
						</div>
						<div className={styles.list}>
							{loading ?
								<div className={styles.campaign}>Loading...</div>
								: surveyData && surveyData.workspaceSurveys.items.length > 0 ?
									surveyData.workspaceSurveys.items.map(survey =>
										<Dropdown.Item
											key={survey.id}
											options={{onClick: () => handleOptionSelected(survey)}}
											className={styles.item}
										>
											{survey.name}
										</Dropdown.Item>)
									: <div className={styles.campaign}>No campaigns found</div>
							}
						</div>
					</div>
				</Dropdown>
				<InviteToSurveyModal
					isOpen={showEmailModal}
					onClose={handleToggleInvite}
					onSend={handleInviteSurveyMembers}
					useFilter={useFilter}
					filter={filter}
					useUserIds={useUserIds}
					userIds={userIds}
					numFilterSelected={numFilterSelected}
					overrideSurvey={selectedSurvey}
				/>
			</>
		);
	});

InviteCampaignDropdown.displayName = "InviteCampaignDropdown";

export {InviteCampaignDropdown};
