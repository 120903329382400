import React, {ReactElement, useEffect, useMemo, useState} from "react";

import {Button, SearchInput, Modal, Spinner} from "../../shared/v2";
import {ConfirmExitModal} from "../../shared/v2/modals/confirm-exit-modal";
import {TrainingCardSelect} from "./training-card-select";
import {useChatTrainingSetContext} from "../../context/chat-contexts";
import {TrainingSet} from "../../models";

import styles from "./select-training-set-modal.module.scss";

export interface SelectTrainingSetModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SelectTrainingSetModal = ({isOpen, onClose}: SelectTrainingSetModalProps): ReactElement => {
	const {
		trainingSets,
		activeTrainingSets,
		isLoading,
		isUpdatingDisabled,
		saveTrainingSetIds,
	} = useChatTrainingSetContext();

	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [isExitModalOpen, setIsExitModalOpen] = useState(false);
	const [search, setSearch] = useState("");
	const activeTrainingSetIds =
    activeTrainingSets?.map((trainingSet) => trainingSet.id) || [];

	const selectTrainingSets = (trainingSet: TrainingSet): void => {
		if (selectedIds.includes(trainingSet.id)) {
			setSelectedIds(selectedIds.filter((id) => id !== trainingSet.id));
		} else {
			setSelectedIds([...selectedIds, trainingSet.id]);
		}
	};

	const filteredTrainingSets = useMemo(
		() =>
			trainingSets?.filter(({alias}) =>
				alias.toLowerCase().includes(search.toLowerCase()),
			),
		[search, trainingSets],
	);

	useEffect(() => {
		setSelectedIds(activeTrainingSetIds);
	}, [activeTrainingSetIds.length]);

	useEffect(() => {
		if (isExitModalOpen && !isOpen) {
			setIsExitModalOpen(false);
		}
	}, [isOpen, isExitModalOpen]);

	const handleConfirmExit = (): void => {
		setSelectedIds(activeTrainingSetIds);
		setSearch("");
		onClose();
	};

	const renderTrainingSets = () => {
		if (isLoading) {
			return <Spinner className={styles.spinner} />;
		}

		return (
			<div className={styles.trainingSetsCollection}>
				{filteredTrainingSets?.map((trainingSet) => (
					<TrainingCardSelect
						key={trainingSet.id}
						trainingSet={trainingSet}
						onSelected={selectTrainingSets}
						isSelected={selectedIds.includes(trainingSet.id)}
					/>
				))}
			</div>
		);
	};

	return (
		<>
			<Modal
				title="Choose Dataset"
				description="
					Select from your existing datasets to apply to your current project.
					You can choose multiple sets for broader insights.
				"
				isOpen={isOpen}
				onClose={onClose}
				className={styles.modal}
				portal
			>
				<div className={styles.divider} />
				<div className={styles.searchWrapper}>
					<SearchInput value={search} onChange={setSearch} />
				</div>

				{renderTrainingSets()}
				<div className={styles.commitButtonsWrapper}>
					<Button
						variant="outlined"
						className={styles.commitButton}
						onClick={() => setIsExitModalOpen(true)}
					>
            Cancel
					</Button>

					<Button
						className={styles.commitButton}
						disabled={!selectedIds.length || isUpdatingDisabled}
						onClick={() => {
							saveTrainingSetIds(selectedIds);
							onClose();
						}}
					>
						{selectedIds.length ? "Use selected" : "Choose dataset"}
					</Button>
				</div>
			</Modal>
			<ConfirmExitModal
				title="Confirm Exit"
				description="
					Any changes you've made to your dataset selections haven't been saved.
					Do you want to exit?
				"
				isOpen={isExitModalOpen}
				onClose={() => setIsExitModalOpen(false)}
				onConfirm={handleConfirmExit}
				portal
			/>
		</>
	);
};
