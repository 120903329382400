import {WorkspaceMembersFilter} from "../models/filter";
import {useSearchParams} from "./useSearchParams";
import {useNavigate} from "./useNavigate";
import {ResponsesFilter} from "../models/response";
import {AnswersPageQueryParams} from "../models/answer";


export const useFilter = (segmentId?: string, name?: string, dynamicSegmentId?: string): {
	membersFilter: WorkspaceMembersFilter;
	updateFilter: (newFilter: WorkspaceMembersFilter) => void;
	clearFilters: () => void;
	removeFilter: (clearKey: string | number, clearValue?) => void;
	removeCustomProp: (id: string, clearValue?) => void;
	navigateAndKeepFilter: (to: string) => void
} => {
	const parsedSearch = useSearchParams();
	const navigate = useNavigate();

	const [min, max] = parsedSearch.ageRange?.toString()?.split("-", 2)?.map(v => {
		const p = parseInt(v, 10);
		return isNaN(p) ? undefined : p;
	}) ?? [];
	const ageRange = min || max ? {min, max} : undefined;

	const customPropsFilter = parsedSearch?.customProperties ?
		JSON.parse(parsedSearch.customProperties)
		: undefined;
	/**
	 * All the survey date filters
	 */
	const firstSurveyFilter = parsedSearch?.firstSurveyResponseAt
		? JSON.parse(parsedSearch.firstSurveyResponseAt) : undefined;

	const lastSurveyFilter = parsedSearch?.lastSurveyResponseAt
		? JSON.parse(parsedSearch.lastSurveyResponseAt) : undefined;

	const firstFeedbackFilter = parsedSearch?.firstFeedbackSurveyResponseAt
		? JSON.parse(parsedSearch.lastSurveyResponseAt) : undefined;

	const lastFeedbackFilter = parsedSearch?.lastFeedbackSurveyResponseAt
		? JSON.parse(parsedSearch.lastSurveyResponseAt) : undefined;

	const membersFilter = {
		gender: parsedSearch.gender ?? undefined,
		ageRange,
		countryId: parsedSearch.countryId,
		customProperties: customPropsFilter,
		ethnicityId: parsedSearch.ethnicityId,
		stateId: parsedSearch.stateId,
		firstSurveyResponseAt: firstSurveyFilter,
		lastSurveyResponseAt: lastSurveyFilter,
		firstFeedbackSurveyResponseAt: firstFeedbackFilter,
		lastFeedbackSurveyResponseAt: lastFeedbackFilter,
		segmentId,
		dynamicSegmentId,
		hasTakenSurvey: parsedSearch.surveyIds ? {
			surveyIds: parsedSearch.surveyIds,
			any: parsedSearch.any ?? undefined,
			completed: parsedSearch.completed ?? undefined,
			haveNot: parsedSearch.haveNot ?? undefined,
		} : undefined,
		name: name || undefined,
	};

	const updateFilter = (newFilter: WorkspaceMembersFilter): void => {
		const {ageRange: ar} = newFilter;
		navigate({search: {
			...newFilter,
			ageRange: ar ? `${ar.min ?? ""}-${ar.max ?? ""}` : undefined,
			customProperties: (newFilter?.customProperties?.length || 0) > 0 ?
				JSON.stringify(newFilter.customProperties)
				: undefined,
			firstSurveyResponseAt: JSON.stringify(newFilter?.firstSurveyResponseAt),
			lastSurveyResponseAt: JSON.stringify(newFilter?.lastSurveyResponseAt),
			firstFeedbackSurveyResponseAt: JSON.stringify(newFilter?.firstFeedbackSurveyResponseAt),
			lastFeedbackSurveyResponseAt: JSON.stringify(newFilter?.lastFeedbackSurveyResponseAt),
		}}, {workspace: true});
	};

	const clearFilters = (): void => {
		updateFilter({});
	};

	const navigateAndKeepFilter = (to: string): void => {
		const {ageRange: ar, hasTakenSurvey, ...remainingFilters} = membersFilter;
		navigate({
			pathname: to,
			search: {
				...remainingFilters,
				...hasTakenSurvey,
				ageRange: ar ? `${ar.min ?? ""}-${ar.max ?? ""}` : undefined,
				customProperties: (membersFilter?.customProperties?.length || 0) > 0 ?
					JSON.stringify(membersFilter.customProperties)
					: undefined,
			},
		}, {workspace: true});
	};

	const removeFilter = (clearKey: string | number, clearValue?): void => {
		const newFilter = {...membersFilter};
		if (Array.isArray(newFilter[clearKey])) {
			newFilter[clearKey] = newFilter[clearKey].filter(item => item !== clearValue);
		} else {
			newFilter[clearKey] = null;
		}
		updateFilter(newFilter);
	};

	const removeCustomProp = (id: string, clearValue?): void => {
		const newCustom = membersFilter?.customProperties || [];
		membersFilter?.customProperties?.forEach((prop, index) => {
			if (prop.id === id) {
				const newValues = prop.values.filter(item => item !== clearValue);
				if (newValues.length > 0) {
					newCustom[index] = {
						...prop,
						values: newValues,
					};
				} else {
					newCustom.splice(index, 1);
				}
			}
		});
		updateFilter({...membersFilter, customProperties: newCustom});
	};


	return {membersFilter, clearFilters, updateFilter, removeFilter, removeCustomProp, navigateAndKeepFilter};
};

/**
 * Converts types that can't be put in a address bar to types that can
 * @param newFilter A ResponseFilter or subclass of ResponseFilter
 * @returns A ResponseFilter or subclass of ResponseFilter with CustomPropsFilter[] & AgeRange[]
 * encoded as json strings
 */
export const convertResponsesFilter = (newFilter: ResponsesFilter | AnswersPageQueryParams):
(Omit<ResponsesFilter | AnswersPageQueryParams, "customProperties"|"ageRange"> &
{customProperties?: string, ageRange?: string}) => {
	return {
		...newFilter,
		customProperties: (newFilter?.customProperties?.length || 0) > 0 ?
			JSON.stringify(newFilter.customProperties)
			: undefined,
		ageRange: (newFilter?.ageRange?.length || 0) > 0 ?
			JSON.stringify(newFilter.ageRange)
			: undefined,
	};
};

/**
 * Reverts convertResponsesFilter to retrieve the original ResponseFilter or subclass of
 * ResponseFilter
 * @param parsed A ResponseFilter or subclass of ResponseFilter with CustomPropsFilter[]
 * & AgeRange[] encoded as json strings
 * @returns A ResponseFilter or subclass of ResponseFilter
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unconvertResponsesFilter = (parsed: Record<string, any>): Record<string, any> => {
	return {
		...parsed,
		customProperties: parsed?.customProperties ?
			JSON.parse(parsed.customProperties)
			: undefined,
		ageRange: parsed?.ageRange ?
			JSON.parse(parsed.ageRange)
			: undefined,

	};
};
