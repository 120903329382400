import {AnimatePresence, motion, useAnimationControls} from "framer-motion";
import classNames from "classnames/bind";
import React, {ReactElement, useEffect, useState} from "react";

import {ButtonIcon} from "../../../../shared/v2";
import {ChevronDownIcon, ChevronUpIcon} from "../../../../icons";
import {useAiActionsContext, useImageHistoryContext} from "../../contexts";
import {useIsOverflowing} from "../../../../hooks/useIsOverflowing";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./history.module.scss";

const cx = classNames.bind(styles);

export const History = (): ReactElement => {
  const [hasScrollDownButton, setHasScrollDownButton] = useState(false);
  const [hasScrollUpButton, setHasScrollUpButton] = useState(false);
  const [historyEl, setHistoryEl] = useState<HTMLDivElement | null>(null);
  const [listEl, setListEl] = useState<HTMLDivElement | null>(null);
  const [y, setY] = useState(0);
  const {imageSrc, history, setImageSrc} = useImageHistoryContext();
  const {isUpdatingImage} = useAiActionsContext();
  const {isDarkMode} = useThemeMode();
  const {y: isHistoryOverflowing} = useIsOverflowing(historyEl, [history]);

  const handleSetImageSrc = (src: string) => {
    if (isUpdatingImage) {
      return;
    }

    setImageSrc(src);
  }

  const entryVariants = {
    default: {
      width: "110px",
      height: "110px",
      opacity: 0.6,
      outlineWidth: 0,
    },
    active: {
      width: "130px",
      height: "130px",
      opacity: 1,
      outlineWidth: "4px",
    },
  }

  const entryImageVariants = {
    default: {
      outlineWidth: 0
    },
    active: {
      outlineWidth: "2px"
    }
  }

  useEffect(() => {
    if (isHistoryOverflowing) {
      const {top, bottom} = calculateConstraints();
      setHasScrollDownButton(y > bottom);
      setHasScrollUpButton(y < top);
    } else {
      setHasScrollDownButton(false);
      setHasScrollUpButton(false);
    }

  }, [isHistoryOverflowing, y]);

  useEffect(() => {
    if (!isHistoryOverflowing) {
      setY(0);
    }
  }, [isHistoryOverflowing]);

  const controls = useAnimationControls();

  const calculateConstraints = () => {
    const historyHeight = historyEl!.clientHeight;
    const listHeight = listEl!.clientHeight;

    return {
      top: 0,
      bottom: -(listHeight - historyHeight) - 32,
    }
  }

  const handleWheel = (e: React.WheelEvent) => {
    const constraints = calculateConstraints();

    if (isHistoryOverflowing) {
      const newValue = Math.max(Math.min(y + e.deltaY, constraints.top), constraints.bottom);
      setY(newValue);
    }
  }

  const scrollHistory = (direction: "top" | "bottom") => {
    const historyHeight = historyEl!.clientHeight;
    const change = (direction === "top" ? historyHeight : -historyHeight) / 2;
    const constraints = calculateConstraints();
    const newValue = Math.max(Math.min(y + change, constraints.top), constraints.bottom);

    setY(newValue);
  }

  useEffect(() => {
    controls.start({y});
  }, [y]);

  return (
    <div
      ref={setHistoryEl}
      className={cx("history", {isDarkMode, disabled: isUpdatingImage})}
    >
      <AnimatePresence>
        {hasScrollUpButton && (
          <motion.div
            className={styles.scrollUp}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
          >
            <ButtonIcon
              icon={<ChevronUpIcon />}
              onClick={() => scrollHistory("top")}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        ref={setListEl}
        className={styles.list}
        animate={controls}
        onWheel={handleWheel}
        transition={{duration: 0.3}}
      >
        {history.map((src) => (
          <motion.div
            className={styles.entry}
            key={src}
            variants={entryVariants}
            initial="default"
            animate={imageSrc === src ? "active" : "default"}
            onClick={() => handleSetImageSrc(src)}
          >
            <motion.img
              src={src}
              className={styles.image}
              variants={entryImageVariants}
              initial="default"
              animate={imageSrc === src ? "active" : "default"}
            />
          </motion.div>
        ))}
      </motion.div>

      <AnimatePresence>
        {hasScrollDownButton && (
          <motion.div
            className={styles.scrollDown}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
          >
            <ButtonIcon
              icon={<ChevronDownIcon />}
              onClick={() => scrollHistory("bottom")}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
