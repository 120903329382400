import React, {ReactElement} from "react";
import {WorkflowCanvas} from "../../../components/workflow-canvas";
import {WorkflowModals} from "../../../components/workflow-modals";
import {useWorkflowContext} from "../../../../context/workflow-contexts";

export const FlowBuilder = (): ReactElement => {
  const {
		activeAgentTasks,
	} = useWorkflowContext();

  return <>
    <WorkflowCanvas agents={activeAgentTasks} />

    <WorkflowModals />
  </>
}

