/* eslint-disable react/prop-types */
import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {NetworkStatus, useMutation, useQuery} from "@apollo/client";
import {NewAvatar as Avatar, Options, DropdownOptions, FilterDisplay, formatDate} from "../../../shared";
import {CreateSegmentData, CreateSegmentVars, RemoveSegmentMemberData} from "../../../models/segment";
import {
	ADD_ATTRIBUTE_TO_USERS,
	ADD_WORKSPACE_SEGMENT_MEMBERS,
	CREATE_SEGMENT,
	INVITE_CONTACTS,
	REMOVE_CONTACT,
	REMOVE_FROM_SEGMENT,
} from "../../../graphql/mutations/mutations";
import {GET_DYNAMIC_SEGMENT_NAME, GET_SEGMENT_NAME} from "../../../graphql/queries/queries";
import {AddContactData, Contact, ContactsSort, UserStatus} from "../../../models/user";
import {updateCacheAddItemByFieldId, updateCacheDeletePageItemByFieldId} from "../../../shared/utility/update-cache";
import {GET_SHOW_INVITE} from "../../../graphql/queries/client-queries";
import {ActionsButton} from "../../components/actions-button";
import {AlignText} from "../../../shared/typography/align-text";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {EmptyScreen} from "../../components/empty-screen";
import {FilterPanel} from "../../../survey/components/filter-panel";
import {GET_ALL_ATTRIBUTES_W_EXTANT} from "../../../graphql/queries/attribute-queries";
import {InviteModal} from "../../modals/invite-member";
import {PaginatedTable} from "../../../shared/components/table/paginated-table";
import {Column, HeaderGroup, Row} from "react-table";
import {ToastContext} from "../../../context/toast-context";
import {useContactsFilter, useNavigate, useParams} from "../../../route";
import {UserContext} from "../../../context/user-context";
import styles from "./contacts.module.scss";
import {toggleShowInviteModal} from "../../../cache";
import {SEGMENT_FRAGMENT} from "../../../graphql/fragments/fragments";
import {AddListModal} from "../../../modals/add-list";
import {useSegmentOptions} from "../../../hooks/useSegmentOptions";
import {ContactModal} from "../../../modals/contact-modal";
import {Body, Subheader} from "../../../shared/v2/typography";
import {Button, DebounceSearch} from "../../../shared/v2";
import {FilterIcon, SearchIcon} from "../../../icons";
import {IdName} from "../../../models/generic";
import {AddPropertyModal} from "../../modals/add-property";
import {AddAttributeToUserVars, AttributesWithExtantPageData, RuleAction} from "../../../models/attribute";
import {useSearchParams} from "react-router-dom";

const TABLE_PAGE_SIZE = 100;

export interface ContactsPageProps {
	isDynamicSegment?: boolean;
	isSegment?: boolean;
}
const ContactsPage = React.memo(({isDynamicSegment, isSegment}: ContactsPageProps): ReactElement => {
	const {updateToast} = useContext(ToastContext);
	const {
		user: {status},
		workspaces,
	} = useContext(UserContext);
	const {segmentId} = useParams<{segmentId: string | undefined}>();
	const navigate = useNavigate();
	const displayFilters = !isDynamicSegment;
	// States
	const [search, setSearch] = useState("");
	const [selectedUser, setSelectedUser] = useState("");
	const [params, setSearchParams] = useSearchParams();
	const [forDelete, setForDelete] = useState("");
	const [showFilter, setShowFilter] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [showAddAttrModal, setShowAddAttrModal] = useState(false);
	const [selectedCreators, setSelectedCreators] = useState<Row<Contact>[]>([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [showNewList, setShowNewList] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [sortBy, setSortBy] = useState(ContactsSort.DEFAULT);
	const userId = params.get("userId");

	useEffect(() => {
		setSelectedUser(userId ?? "");
	}, [userId]);

	const {membersFilter, workspaceMembers, workspaceId, updateFilter} =

		useContactsFilter(
			isSegment ? segmentId : undefined,
			TABLE_PAGE_SIZE,
			search,
			isDynamicSegment ? segmentId : undefined,
			sortBy,
		);
	const {data, error, refetch, fetchMoreFragment, fragment, handleFetchMore, networkStatus} = workspaceMembers;
	// Queries
	const {data: attrData} = useQuery<AttributesWithExtantPageData>(GET_ALL_ATTRIBUTES_W_EXTANT, {
		variables: {workspaceId},
	});
	const {data: list} = useQuery<{segment: IdName}>(GET_SEGMENT_NAME, {
		skip: !isSegment,
		variables: {id: segmentId},
	});
	const {data: dynamicSeg} = useQuery<{dynamicSegment: IdName}>(GET_DYNAMIC_SEGMENT_NAME, {
		skip: !isDynamicSegment,
		variables: {id: segmentId},
	});
	const title = list?.segment.name || dynamicSeg?.dynamicSegment.name || "";
	// Mutations
	const [removeFromSegment] = useMutation<RemoveSegmentMemberData>(REMOVE_FROM_SEGMENT);
	const [deleteContact] = useMutation(REMOVE_CONTACT);
	const [sendToSegment] = useMutation(ADD_WORKSPACE_SEGMENT_MEMBERS);
	const [createList] = useMutation<CreateSegmentData, CreateSegmentVars>(CREATE_SEGMENT);
	const [addProperty, {loading: addPropLoading}] = useMutation<{id: string}, AddAttributeToUserVars>(
		ADD_ATTRIBUTE_TO_USERS,
	);
	const [inviteContact] = useMutation<AddContactData>(INVITE_CONTACTS, {
		onCompleted: (invites) => {
			const {length} = invites.addContactsByEmail;
			updateToast({
				description: `${length} creators added`,
				type: "informational",
			});
			// Mutation returns array of emails, so should refetch.
			refetch();
		},
	});

	const toggleInviteModal = useQuery(GET_SHOW_INVITE);
	const {toggleShowInviteModal: toggleInvite} = toggleInviteModal.data;
	const handleShowUserModal = (id: string): void => {
		setSearchParams({userId: id});
	};
	const handleSingleAddProperty = (id: string): void => {
		setSelectedUser(id);
		setShowAddAttrModal(true);
	};

	if (!workspaces?.length || status !== "CONFIRMED") {
		navigate("/me", {workspace: true});
	}

	const handleRemoveOneFromList = (id: string): void => {
		removeFromSegment({
			variables: {segmentId, userIds: id, workspaceId},
			update(cache, {data: deleteData}) {
				if (!deleteData) return;
				updateCacheDeletePageItemByFieldId(
					cache,
					"contacts",
					"contact",
					deleteData.removeSegmentMembers.segment.id,
					deleteData.removeSegmentMembers.users[0].id,
				);
			},
		});
	};

	const currentData =

		useMemo(
			() => data?.contacts?.items?.slice(currentPage * TABLE_PAGE_SIZE, (currentPage + 1) * TABLE_PAGE_SIZE),
			[data, currentPage],
		);
	const columns = useMemo((): Column<Contact>[] => {
		const column: Column<Contact>[] = [
			{
				id: "name",
				Header: "Name",
				accessor: "firstName",
				minWidth: 250,
				Cell: ({row: {original}}) => (
					<div className={styles.nameColumn}>
						<Avatar
							user={original}
							showName
							showTag
							onClick={original.status !== UserStatus.PENDING ? () => handleShowUserModal(original.id) : undefined}
						/>
					</div>
				),
			},
			{
				Header: "Age",
				accessor: "age",
				Cell: ({value}) => <Body size="s">{value}</Body>,
			},
			{
				Header: "Last Active",
				accessor: "lastActive",
				Cell: ({value}) => <Body size="s">{value ? formatDate(value) : "-"}</Body>,
			},
		];
		if (!isDynamicSegment) {
			column.push({
				id: "options",
				Header: " ",
				maxWidth: 50,
				disableSortBy: true,
				Cell: ({row: {original}}) => (
					<AlignText align="right">
						<Options
							type="menu-vertical"
							position="right"
							options={[
								{
									name: segmentId ? "Remove" : "Delete",
									actionOptions: {
										onClick: segmentId ? () => handleRemoveOneFromList(original.id) : () => setForDelete(original.id),
									},
									icon: "trash",
									iconFill: "var(--color-text-body)",
								},
								{
									name: "Add Property",
									actionOptions: {
										onClick: () => handleSingleAddProperty(original.id),
									},
									icon: "add-members",
									iconFill: "var(--color-text-body)",
								},
							]}
						/>
					</AlignText>
				),
			});
		}
		return column;
		/**
		 * When coming from a list directly to the main creator table, there's
		 * a brief moment where segmentId is defined and screws up the Options to be Remove.
		 * Doing this to remedy that, might try to find a better way to deal with it.
		 */
	}, [segmentId]);

	const handleRemoveCustomProp = (id, clearValue?): void => {
		const newCustom = membersFilter?.customProperties || [];
		membersFilter?.customProperties?.forEach((prop, index) => {
			if (prop.id === id) {
				const newValues = prop.values.filter((item) => item !== clearValue);
				if (newValues.length > 0) {
					newCustom[index] = {
						...prop,
						values: newValues,
					};
				} else {
					newCustom.splice(index, 1);
				}
			}
		});
		updateFilter({...membersFilter, customProperties: newCustom});
	};
	const handleRemoveFilter = (clearKey, clearValue?): void => {
		const newFilter = {...membersFilter};

		if (Array.isArray(newFilter[clearKey])) {
			newFilter[clearKey] = newFilter[clearKey].filter((item) => item !== clearValue);
		} else {
			newFilter[clearKey] = undefined;
		}
		updateFilter(newFilter);
	};
	const handleClearFilters = (): void => {
		setSearch("");
		setCurrentPage(0);
		updateFilter({});
	};

	const showUser = (): void => {
		setSearchParams({});
	};

	/**
	 * Toggles the invite modal
	 */
	const showInvite = (): void => {
		const currentValue = toggleShowInviteModal();
		toggleShowInviteModal(!currentValue);
	};

	// Removes multiple users at once from a segment (list in UI)
	const handleRemove = (): void => {
		removeFromSegment({
			variables: {
				segmentId,
				userIds: selectedCreators.map((c) => c.original.id),
				workspaceId,
				useFilter: selectAll,
				filter: {...membersFilter},
			},
			onCompleted: () => {
				updateToast({description: "Removed creators from list", type: "informational"});
				setSelectedCreators([]);
				setShowConfirm(false);
				setSelectAll(false);
			},
			// Update function so we don't refetch
			update(cache, {data: deleteData}) {
				if (!deleteData) return;
				deleteData.removeSegmentMembers.users.forEach((user) =>
					updateCacheDeletePageItemByFieldId(
						cache,
						"contacts",
						"contact",
						deleteData.removeSegmentMembers.segment.id,
						user.id,
					),
				);
			},
		});
	};

	/**
	 *
	 * @param selectedContacts The selected contacts in the contact list
	 *
	 * @returns Promise from the graphql mutation.
	 */

	const handleDelete = (): void => {
		deleteContact({
			variables: {
				workspaceId,
				userIds: selectedCreators.map((c) => c.original.id),
				useFilter: selectAll,
				filter: {...membersFilter},
			},
			onCompleted: () => {
				setSelectedCreators([]);
				setSelectAll(false);
				setShowConfirm(false);
				updateToast({description: "Deleted Creator(s)", type: "informational"});
				refetch();
			},
		});
	};

	const handleDeleteCreator = (): void => {
		deleteContact({
			variables: {workspaceId, userIds: forDelete},
			onCompleted: () => {
				setForDelete("");
				updateToast({description: "Deleted Creator", type: "informational"});
				refetch();
			},
		});
	};

	const handleShowNewList = (): void => setShowNewList(true);
	const handleShowConfirm = (): void => setShowConfirm(true);
	const handleAddContact = (emails: string[], segId?: string): void => {
		inviteContact({
			variables: {workspaceId, emails, segmentId: segId || segmentId},
			update(cache, {data: inviteData}) {
				if (inviteData) {
					cache.modify({
						id: `Workspace:${workspaceId}`,
						fields: {
							contactCount(current: number) {
								return current + inviteData.addContactsByEmail.length;
							},
						},
					});
					if (segmentId) {
						cache.modify({
							id: `Segment:${segmentId}`,
							fields: {
								memberCount(current: number) {
									return current + inviteData.addContactsByEmail.length;
								},
							},
						});
					}
				}
			},
		});
	};

	const handleSort = (value: HeaderGroup<Contact>): void => {
		if (!value.canSort) return;

		switch (value.Header) {
		case "Name":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ContactsSort.CREATORTAGASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ContactsSort.CREATORTAGDESC);
			setSortBy(ContactsSort.DEFAULT);
			break;
		case "Age":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ContactsSort.AGEASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ContactsSort.AGEDESC);
			setSortBy(ContactsSort.DEFAULT);
			break;
		case "Last Active":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ContactsSort.LASTACTIVEASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ContactsSort.LASTACTIVEDESC);
			setSortBy(ContactsSort.DEFAULT);
			break;
		default:
			setSortBy(ContactsSort.DEFAULT);
		}

	};

	const handleSearch = (newValue: string): void => {
		setSearch(newValue);
		setCurrentPage(0); // In case there's only one page of results, want to start at the first page.
	};
	const handleSend = (id: string): void => {
		sendToSegment({
			variables: {
				segmentId: id,
				userIds: selectedCreators.map((creator) => creator.original.id),
				useContactsFilter: selectAll,
				workspaceId,
				contactsFilter: {...membersFilter},
			},
			onError: (err) => {
				updateToast({description: err.message, type: "failure"});
			},
			onCompleted: (sent) => {
				const addedUsersLength = sent.addSegmentMembers.addedUsers.length;
				const existingUsersLength = sent.addSegmentMembers.existingUsers.length;
				updateToast({
					description: (
						<div>
							{addedUsersLength > 0 && <div>{addedUsersLength} added to list</div>}
							{existingUsersLength > 0 && <div>{existingUsersLength} already in list</div>}
						</div>
					),
					type: "informational",
				});
				setSelectedCreators([]);
				setShowNewList(false);
				setSelectAll(false);
			},
		});
	};

	const handleCreateNewList = async (name: string): Promise<void> => {
		await createList({
			variables: {input: {workspaceId, name}},
			onCompleted: (createData) => {
				if (!createData) return;
				handleSend(createData.createSegment.id);
			},
			onError: (error) => {
				if (error.graphQLErrors[0].extensions?.name === "UniqueViolationError") {
					updateToast({description: "This list name is already taken", type: "failure"});
				} else {
					updateToast({description: "An error occurred", type: "failure"});
				}
			},
			update(cache, {data: createSeg}) {
				if (createSeg) {
					const newSegmentRef = cache.writeFragment({
						data: createSeg.createSegment,
						fragment: SEGMENT_FRAGMENT,
					});
					updateCacheAddItemByFieldId(cache, "segments", newSegmentRef, workspaceId, createSeg.createSegment.id);
				}
			},
		});
	};

	const handleAddProperty = (id: string, ruleAction: RuleAction, value?: string): void => {
		const userIds = selectedUser || selectedCreators.map((creator) => creator.original.id);
		addProperty({
			variables: {
				attributeId: id,
				userIds,
				value,
				ruleAction,
			},
			onCompleted: () => {
				updateToast({description: "Property added to user(s)", type: "informational"});
				setSelectedCreators([]);
				setSelectedUser("");
				setShowAddAttrModal(false);
			},
			onError: () => {
				updateToast({description: "An error occurred", type: "failure"});
				setSelectedUser("");
				setSelectedCreators([]);
				setShowAddAttrModal(false);
			},
		});
	};

	// Options for the action button add to list
	const options = useSegmentOptions(handleSend, handleShowNewList);
	if (!workspaceId) {
		// If there isn't a workspaceID then the user shouldn't be on this page
		navigate("/");
	}
	const isFiltered = Object.keys(membersFilter).some((value) => membersFilter[value] !== undefined);

	/**
	 * Generate the actions of our actions button
	 * Could maybe make this into a useCallback instead, but similar purpose.
	 */
	const actions = useMemo((): DropdownOptions[] => {
		const temp: DropdownOptions[] = [];
		if (!isDynamicSegment) {
			temp.push({
				name: segmentId ? "Remove" : "Delete",
				actionOptions: {onClick: handleShowConfirm},
			});
		}
		if (options) {
			temp.push({name: "Add to list", actionOptions: {options}});
		}
		temp.push({
			name: "Add Property To Users",
			actionOptions: {onClick: () => setShowAddAttrModal(true)},
		});
		return temp;
	}, [isDynamicSegment, options]);

	return (
		<>
			<div>
				{title && (
					<Subheader size="l" type="medium" className={styles.title}>
						{title}
					</Subheader>
				)}
				{error && <p className={styles.err}>Error loading contact list: {error.message}</p>}
				{currentData && currentData?.length === 0 && !isFiltered && !fetchMoreFragment ? (
					<></>
				) : (
					<header className={styles.header}>
						<DebounceSearch
							id="search-input"
							value={search}
							onChange={handleSearch}
							size="small"
							placeholder="Search"
							leftIcon={<SearchIcon />}
						/>
						{displayFilters && (
							<Button leftIcon={<FilterIcon />} variant="outlined" onClick={() => setShowFilter(true)}>
								Filter
							</Button>
						)}
						<div className={styles.actions}>
							{selectedCreators.length > 0 && actions.length > 0 && data && (
								<ActionsButton
									text={`Bulk actions (${
										selectAll ? data.contacts.items?.length + data.contacts.remaining : selectedCreators.length
									})`}
									options={actions}
								/>
							)}
							{displayFilters && <Button onClick={showInvite}>Add Creators</Button>}
						</div>
					</header>
				)}
				{isFiltered && (
					<FilterDisplay
						filter={membersFilter}
						attributes={attrData?.attributes?.items}
						handleClearFilters={handleClearFilters}
						handleRemoveFilter={handleRemoveFilter}
						handleRemoveCustomProp={handleRemoveCustomProp}
					/>
				)}
				{(!data && fragment) ||
					(data && currentData && data.contacts.items?.length > 0 ? (
						<>
							<PaginatedTable
								columns={columns}
								data={currentData}
								selectedValues={selectedCreators}
								onSelectChange={setSelectedCreators}
								pageState={[currentPage, setCurrentPage]}
								pageSize={TABLE_PAGE_SIZE}
								totalCount={data.contacts.items?.length + data.contacts.remaining}
								handleFetchMore={handleFetchMore}
								fetchMoreFragment={fetchMoreFragment}
								selectAllState={[selectAll, setSelectAll]}
								dataLength={data.contacts.items?.length}
								sortLoading={networkStatus === NetworkStatus.setVariables}
								onSort={handleSort}
								disablePagination={networkStatus === NetworkStatus.fetchMore}
							/>
						</>
					) : data?.contacts.items?.length === 0 && !isFiltered ? (
						<EmptyScreen whatIsEmpty="CREATORS" action={showInvite} invite={showInvite} />
					) : (
						<Body size="xs">No results found</Body>
					))}
				<InviteModal
					isOpen={toggleInvite}
					onClose={showInvite}
					handleAddToList={handleAddContact}
					handleInvite={handleAddContact}
				/>
				<ContactModal isOpen={!!params.get("userId")} onClose={showUser} userId={selectedUser} />
				<AddListModal isOpen={showNewList} onClose={() => setShowNewList(false)} handleSaveList={handleCreateNewList} />
				<AddPropertyModal
					isOpen={showAddAttrModal}
					onClose={() => setShowAddAttrModal(false)}
					handleSave={handleAddProperty}
					loading={addPropLoading}
				/>
				<DeleteConfirmModal
					isOpen={showConfirm}
					onClose={() => setShowConfirm(false)}
					handleConfirm={segmentId ? handleRemove : handleDelete}
					warningText={
						segmentId
							? "Are you sure you want to remove these Creators from this list?"
							:
							  "This will completely remove the selected creators from your workspace and any lists or segments. Are you sure you want to continue?"
					}
				/>
				<DeleteConfirmModal
					isOpen={Boolean(forDelete)}
					onClose={() => setForDelete("")}
					handleConfirm={handleDeleteCreator}
					warningText="This will completely remove the selected creator from your
				lists and any segments. Are you sure you want to continue?"
				/>
				{showFilter && (
					<FilterPanel segmentId={segmentId} closeFilter={setShowFilter} setPage={setCurrentPage} campaignFilter />
				)}
			</div>
		</>
	);
});


ContactsPage.displayName = "ContactsPage";
export {ContactsPage};
