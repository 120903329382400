import React, {ReactElement} from "react";

import {BaseModalProps} from "../modal";
import {ConfirmActionModal} from "../confirm-action-modal";

import styles from "./confirm-exit-modal.module.scss";

export interface ConfirmExitModalProps extends BaseModalProps {
	title: string;
	description: string;
	onConfirm: () => void;
	portal?: boolean;
}

export const ConfirmExitModal = ({
	isOpen,
	onClose,
	onConfirm,
	title,
	description,
	portal = false,
}: ConfirmExitModalProps): ReactElement => {
	return  <ConfirmActionModal
		title={title}
		description={description}
		isOpen={isOpen}
		onClose={onClose}
		onConfirm={onConfirm}
		confirmText="Exit"
		cancelText="Continue Selecting"
		className={styles.modal}
		portal={portal}
	/>
};
