import React, {ReactElement, useEffect} from "react";
import {Outlet} from "react-router-dom";
import classNames from "classnames/bind";

import {cleanupCache} from "../../../shared/utility/cache-cleanup";
import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {
	LabelTagPropertiesIcon,
	SparkAiStarsIcon,
	UserListSegmentsIcon,
	UsersListPeopleIcon
} from "../../../icons";
import {useUserContext} from "../../../context/user-context";
import {PageSubNavigation} from "../../../hoc/layout/layout/page-sub-navigation";
import {NavigationPageLayout} from "../../../hoc/layout/navigation-page-layout";
import {NavigationTooltip} from "../../../hoc/layout/navigation/navigation-tooltip";
import {useMount} from "../../../hooks/useMount";

import styles from "./crm-landing.module.scss";

const cx = classNames.bind(styles);

const CRMLandingPage = (): ReactElement => {
	const {isEnterpriseManagerOrSupport} = useUserContext();;

	useEffect(() => {
		cleanupCache(["contacts", "segments", "attributes"]);
	}, []);
	useMount(() => {document.title = "Vurvey - Audience"})

	return (
		<NavigationPageLayout
			className={cx("layout", {isLong: isEnterpriseManagerOrSupport})}
		>
			<PageSubNavigation
				className={cx("subNavigation", {isLong: isEnterpriseManagerOrSupport})}
				header="Audience"
			>
				<ButtonGroup>
					<NavigationTooltip text="Manage everyone who has joined your space.">
						<ButtonGroupElement
							icon={<UsersListPeopleIcon />}
							text="All People"
							to=""
							end
						/>
					</NavigationTooltip>
					<NavigationTooltip text="Organize people into helpful lists or segment by target audience.">
						<ButtonGroupElement
							icon={<UserListSegmentsIcon />}
							text="Lists & Segments"
							to="lists"
						/>
					</NavigationTooltip>
					<NavigationTooltip text="Organize your tags and custom data collections.">
						<ButtonGroupElement
							icon={<LabelTagPropertiesIcon />}
							text="Properties"
							to="properties"
						/>
					</NavigationTooltip>
					<NavigationTooltip text="View patterns and links across your community">
						<ButtonGroupElement
							icon={<SparkAiStarsIcon />}
							text="Magic Segments"
							to="magic-segments"
						/>
					</NavigationTooltip>
				</ButtonGroup>
			</PageSubNavigation>

			<Outlet/>
		</NavigationPageLayout>
	)
};

export {CRMLandingPage};
