import React, {ReactElement} from "react";
import {Outlet} from "react-router";

import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {ChartsUsageIcon, HornMegaphoneCampaignIcon, SparkAiStarsIcon, TableTemplateEditIcon} from "../../../icons";
import {PageSubNavigation} from "../../../hoc/layout/layout/page-sub-navigation";
import {NavigationPageLayout} from "../../../hoc/layout/navigation-page-layout";
import {NavigationTooltip} from "../../../hoc/layout/navigation/navigation-tooltip";
import {useMount} from "@/hooks/useMount";

export const CampaignsPage = (): ReactElement => {
	useMount(() => {document.title = "Vurvey - Campaigns"})
	return <NavigationPageLayout>
		<PageSubNavigation header="Campaigns">
			<ButtonGroup>
				<NavigationTooltip text="Video-powered surveys to collect research, test concepts, and generate insights.">
					<ButtonGroupElement
						icon={<HornMegaphoneCampaignIcon />}
						text="All Campaigns"
						to=""
						end
					/>
				</NavigationTooltip>
				<NavigationTooltip text="Manage campaign templates to move fast and stay consistent">
					<ButtonGroupElement
						icon={<TableTemplateEditIcon />}
						text="Templates"
						to="templates"
					/>
				</NavigationTooltip>
				<NavigationTooltip text="View metrics on total responses, community growth, and total video minutes">
					<ButtonGroupElement
						icon={<ChartsUsageIcon />}
						text="Usage"
						to="usage"
					/>
				</NavigationTooltip>

				<NavigationTooltip text="Video highlights automated with the power of AI">
					<ButtonGroupElement
						icon={<SparkAiStarsIcon />}
						text="Magic Reels"
						to="magic-reels"
					/>
				</NavigationTooltip>
			</ButtonGroup>
		</PageSubNavigation>

		<Outlet />
	</NavigationPageLayout>;
}
