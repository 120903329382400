/* eslint-disable react/prop-types */
import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {NetworkStatus, useMutation, useQuery} from "@apollo/client";
import ReactTimeago from "react-timeago";

import {StatsCard, NewAvatar, Options, StatsHeader, formatDate} from "../../../shared";
import {
	GET_DELETE_RESPONSE_MODAL,
	GET_SELECTED_RESPONSE,
} from "../../../graphql/queries/client-queries";
import {GET_RESPONSES} from "../../../graphql/queries/survey-queries";
import {DELETE_RESPONSE} from "../../../graphql/mutations/results-mutations";
import {AlignText} from "../../../shared/typography/align-text";
import {SurveyContext} from "../../../context/survey-context";
import {ToastContext} from "../../../context/toast-context";
import {selectedResponse, toggleDeleteResponseModal} from "../../../cache";
import {DeleteResponseModal} from "../../../modals/delete-response";
import {Response, ResponsePageData} from "../../../models/response";
import {PaginatedTable} from "../../../shared/components/table/paginated-table";
import {currencySymbol} from "../../../shared/utility/utility";
import styles from "./responses-tab.module.scss";
import {useLoadingQuery} from "../../../hooks";
import {useFilter, useNavigate} from "../../../route";
import {Card} from "../../../shared/layout/card";
import {ContactModal} from "../../../modals/contact-modal";
import {useSearchParams} from "react-router-dom";
import {HeaderGroup} from "react-table";
import {ResponsesSort} from "../../../models/rewards";

const TABLE_PAGE_SIZE = 50;

const ResponsesTab = (): ReactElement => {

	const {
		survey: {id: surveyId, firstOpenedAt, closedAt, incentiveAmount, incentiveCurrency},
		responsesTabPage,
		setResponsesTabPage,
	} = useContext(SurveyContext);
	const [selectedUser, setSelectedUser] = useState("");
	const [params, setSearchParams] = useSearchParams();
	const {updateToast} = useContext(ToastContext);
	const navigate = useNavigate();
	const {membersFilter} = useFilter();
	const [sortBy, setSortBy] = useState(ResponsesSort.DEFAULT);

	const userId = params.get("userId");

	useEffect(() => {
		setSelectedUser(userId ?? "");
	}, [userId]);

	const handleSetUserId = (id: string | null): void => {
		if (id) {
			params.append("userId", id);
		} else {
			params.delete("userId");
		}

		setSearchParams(params);
	};

	const {data: deleteResponseModal} = useQuery(GET_DELETE_RESPONSE_MODAL);
	const {data: selectedResponseData} = useQuery(GET_SELECTED_RESPONSE);
	const {data, fragment, fetchMoreFragment, handleFetchMore, networkStatus} = useLoadingQuery<ResponsePageData>(GET_RESPONSES, {
		variables: {surveyId, filter: membersFilter, limit: TABLE_PAGE_SIZE, sort: sortBy},
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
		errorPolicy: "all",
		notifyOnNetworkStatusChange: true,
		what: "responses",
	});
	const [deleteResponse] = useMutation(DELETE_RESPONSE, {
		onCompleted: () => updateToast(
			{description: "All answers from user deleted", type: "informational"},
		),
	});

	const handleDelete = (id: string): boolean => {
		selectedResponse(id);
		return toggleDeleteResponseModal(true);
	};

	const handleDeleteResponse = (): void => {
		deleteResponse({
			variables: {id: selectedResponseData.selectedResponse},
			update(cache) {
				cache.modify({
					fields: {
						responses: (existingResponses, {readField}) => ({
							...existingResponses,
							items: existingResponses.items.filter(
								response => selectedResponseData.selectedResponse !== readField(
									"id",
									response,
								),
							),
						}),
					},
				});
			},
		});
		toggleDeleteResponseModal(false);
	};

	const handleReward = (): void => {
		navigate(
			{pathname: "/rewards", search: {surveyIds: [surveyId], completed: true}},
			{workspace: true},
		);
	};

	const handleUserClick = (id: string): void => handleSetUserId(id);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const columns = useMemo((): any => ([
		{
			Header: "Name",
			accessor: "user",
			width: 250,
			Cell: ({value}) =>
				<NewAvatar user={value} showName showTag onClick={() => handleUserClick(value.id)}/>,
		},
		{
			Header: "Completed",
			accessor: "completedAt",
			textAlign: "right",
			cellProps: () => ({
				styles: {textAlign: "right"},
			}),
			Cell: ({value}) => (
				value ?
					<ReactTimeago date={value}/> : <span>Partial</span>
			),
		},
		{
			Header: "Submitted from",
			accessor: "submittedFrom",
			Cell: ({row: {original: response}}) => (response.submittedFrom ?
				<span>
					{response.submittedFrom.city},
					&nbsp;
					{response.submittedFrom.countryName}
				</span>
				: null),
		},
		{
			id: "options",
			Header: "",
			accessor: "",
			disableSortBy: true,
			Cell: ({row: {original: response}}) => <AlignText align="right">
				<Options
					type="menu-vertical"
					options={[{
						name: "Delete",
						actionOptions: {
							onClick: () => handleDelete(response.id),
						},
						icon: "trash",
					}]}
				/>
			</AlignText>,
		},
	]), []);

	const handleSort = (value: HeaderGroup<Response>): void => {
		if (!value.canSort) return;

		switch (value.Header) {
		case "Name":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ResponsesSort.CREATOR_TAG_ASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.CREATOR_TAG_DESC);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		case "Completed":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ResponsesSort.COMPLETED_AT_ASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.COMPLETED_AT_DESC);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		case "Submitted from":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(ResponsesSort.LOCATION_ASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.LOCATION_DESC);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		default:
			setSortBy(ResponsesSort.DEFAULT);
		}
	};

	const currentData = useMemo(() => data?.responses.items.slice(
		responsesTabPage * TABLE_PAGE_SIZE,
		(responsesTabPage + 1) * TABLE_PAGE_SIZE,
	), [data, responsesTabPage]);

	const items = data?.responses?.items;
	if (!items?.length && (networkStatus !== NetworkStatus.loading)) {
		return (
			<div className={styles.noResponses}>
				<p>No responses yet</p>
				<p>As soon as responses are submitted, they will appear here.</p>
			</div>
		);
	}

	const cards: StatsCard[] = [{
		title: "Starts",
		value: ({survey}) => survey?.responseCount,
	}, {
		title: "Completes",
		value: ({survey}) => survey?.completedResponseCount,
		element: ({survey}) => (survey?.completedResponseCount && Number(incentiveAmount) ?
			<div className={styles.reward}>
				<span className={styles.action} onClick={handleReward}>
				Reward {currencySymbol(incentiveCurrency)}
					{(Number(incentiveAmount) * survey.completedResponseCount).toFixed(2)}
				</span>
				<span>*Tremendous may charge additional fees per reward</span>
			</div> : null),
	}, {
		title: "Median Completion Time",
		dataType: "time",
		value: ({survey}) => survey?.medianCompletionTime,
	}, {
		title: "Total Video",
		dataType: "time",
		value: ({survey}) => survey?.totalVideoDuration,
	}];

	return (
		<div className={styles.container}>
			{(firstOpenedAt || closedAt) ? (
				<div className={styles.live}>
					{firstOpenedAt ? (

						`Live: ${formatDate(firstOpenedAt)} - ${closedAt ? formatDate(closedAt) : "Present"}`
					) : (

						`Closed on ${formatDate(closedAt!)}`
					)}
				</div>
			) : <></>}
			<StatsHeader cards={cards} variables={{id: surveyId, filter: membersFilter}} />
			<Card className={styles.table}>
				<h2 className={styles.header}>Participation</h2>
				{
					(!data && fragment) || (data && currentData && data.responses.items.length > 0 && (
						<PaginatedTable
							columns={columns}
							data={currentData}
							totalCount={data.responses.items.length + data.responses.remaining}
							dataLength={data.responses.items.length}
							handleFetchMore={handleFetchMore}
							pageSize={TABLE_PAGE_SIZE}
							fetchMoreFragment={fetchMoreFragment}
							pageState={[responsesTabPage, setResponsesTabPage]}
							sortLoading={networkStatus === NetworkStatus.setVariables}
							onSort={handleSort}
							disablePagination={networkStatus === NetworkStatus.fetchMore}
							tableClassName={styles.table}
						/>
					))
				}
				<DeleteResponseModal
					isOpen={deleteResponseModal.toggleDeleteResponseModal}
					confirm={handleDeleteResponse}
					onClose={() => toggleDeleteResponseModal(false)}
					isDeleteAll
				/>
			</Card>
			<ContactModal
				isOpen={Boolean(selectedUser)}
				onClose={() => handleSetUserId(null)}
				userId={selectedUser}
			/>
		</div>
	);
};

export {ResponsesTab};
